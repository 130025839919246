import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    confirmationModalVisible: false,
    ID: null,
    message: '',
    deleteType: '',
    confirmActionVisible: false,
    attendanceOffcanvasVisible: false
}

export const ConfirmationModalSlice = createSlice({
    name: 'confirmation',
    initialState,
    reducers: {
      showConfirmationModal: (state, action) => {
        const {
            confirmationModalVisible,
            ID,
            message,
            deleteType
        } = action.payload
        state.confirmationModalVisible = confirmationModalVisible
        state.ID = ID
        state.message = message
        state.deleteType = deleteType
      },
      hideConfirmationModal: (state) => {
        state.confirmationModalVisible = false
      },
      showConfirmActionOffcanvas: (state, action) => {
        const {
            confirmActionVisible
        } = action.payload
        state.confirmActionVisible = confirmActionVisible
      },
      hideConfirmActionOffcanvas: (state) => {
        state.confirmActionVisible = false
      },
      showAttendanceOffcanvas: (state, action) => {
        const {
            attendanceOffcanvasVisible
        } = action.payload
        state.attendanceOffcanvasVisible = attendanceOffcanvasVisible
      },
      hideAttendanceOffcanvas: (state) => {
        state.attendanceOffcanvasVisible = false
      },
    }
})

export const { showConfirmationModal, hideConfirmationModal, showConfirmActionOffcanvas, hideConfirmActionOffcanvas, showAttendanceOffcanvas, hideAttendanceOffcanvas } = ConfirmationModalSlice.actions;


export default ConfirmationModalSlice.reducer