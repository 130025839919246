import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { connect } from "react-redux";
import Cropper from 'react-cropper';
import "cropperjs/dist/cropper.css";
import { useRef } from 'react';
import axios from '../../../AxiosConfig';
import { hideImageEditModal } from '../../../features/Offcanvas/imageEditModalSlice';
import { showItemProfileOffcanvas, setItemProfileDetails } from '../../../features/Offcanvas/itemProfileOffcanvasSlice'

const ImageEditModal = ({
    imageEditModalVisible,
    hideImageEditModal,
    imageEditData,
    showItemProfileOffcanvas,
    setItemProfileDetails
}) => {
    const [cropperActive, setCropperActive] = useState(false);
    const [selectImage, selectedImage] = useState([]);
    const [cropper, setCropper] = useState();
    const croperef = useRef();
    const [disableBtn, setDisableBtn] = useState(false);

    const [fileError, setFileError] = useState({
        'text' : '',
        'status' : ''
    })

    const setFilesError = (text, status) => {
        setFileError({ text, status })
    }

    const handleFileSelect = (e) => {
        const file = e.target.files[0];
        const fileTypes = ['image', 'image/png', 'image/jpeg', 'image/jpg']
        if (!fileTypes.includes(file.type)) {
            setFilesError('Please select .png, files only.', 'danger');
            return
        }

        if(file.size > 5000000) {
            setFilesError('File is too large, max file size should be 4 MB', 'danger');
            return;
        }
        setFilesError('', '');
        const fileUrl = URL.createObjectURL(e.target.files[0]);
        selectedImage(fileUrl);
        setCropperActive(true);
    }

    const updateItemProfile = (imageUrl) => {
        const updatedItemProfile = { 'ImageURL': imageUrl };
        axios.post('/ItemAvailability/' + imageEditData.ID, updatedItemProfile).then(res => {
            if (res.data.message === 'Success') {
                const tempItemData = JSON.parse(JSON.stringify(imageEditData));
                tempItemData.ImageURL = imageUrl;
                hideImageEditModal();
                setItemProfileDetails({itemProfileData: tempItemData})
                // showItemProfileOffcanvas({itemProfileVisible: true, itemProfileData: tempItemData})
            }
        })
    }

    const handleCroppedImage = async () => {
        if (typeof cropper !== undefined && typeof cropper !== null) {
            setDisableBtn(true);
            cropper.getCroppedCanvas({
                maxHeight: 360,
                maxWidth: 360,
                fillColor: 'transparent'
            }).toBlob((blob) => {
                const formData = new FormData();
                const fileUrl = URL.createObjectURL(blob);
                formData.append('inputFile', blob)
                axios.post('/applications/upload/items', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }).then(res => {
                    if (res.data.message === 'Success') {
                        updateItemProfile(res.data.data);
                    }
                    setDisableBtn(false);
                }).catch(err => {
                    setTimeout(() => {
                        if(imageEditData.ImageURL === 'assets/img/sample/avatar/avatar1.jpg') {
                            updateItemProfile('https://webweb.ams3.cdn.digitaloceanspaces.com/data/DefaultImages/ItemImage.jpg');
                        } else {
                            setFilesError('Something went wrong', 'danger');
                        }
                        setDisableBtn(false);
                    }, 2000);
                })

            }, 'png', 100);
        }
    }

    const RemoveSelectedImage = () => {
        selectedImage([]);
        setCropperActive(false);
        setFilesError('', '');
        setDisableBtn(false);
    }

    useEffect(() => {
        if(!imageEditModalVisible) {
            RemoveSelectedImage();
        }
    }, [imageEditModalVisible])
    
    return (
        <div>
            <Modal show={imageEditModalVisible} backdrop='static' centered={true} onHide={() => { hideImageEditModal() }}>
                <Modal.Header closeButton style={{ padding: '10px 15px 10px 15px' }}>
                    <div class="row w-100 position-relative">
                        <div class="col-md-7 col-7 d-flex align-items-center">
                            <img src={imageEditData?.ImageURL} alt="avatar" class="imaged me-1" style={{ height: '28px', width: '28px', objectFit: 'cover' }} />
                            {imageEditData?.ItemTitle}
                        </div>
                        {
                            cropperActive &&
                            <>
                            <div className='col-md-5 col-5 d-flex justify-content-end'>
                                <div class="d-flex align-items-center justify-content-end me-1">
                                    <button class="btn btn-sm btn-outline-secondary" onClick={() => RemoveSelectedImage()} disabled={disableBtn}>Change</button>
                                </div>
                                <div class="d-flex align-items-center justify-content-end" onClick={handleCroppedImage}>
                                    <button class="btn btn-sm btn-success" disabled={disableBtn}>
                                        {
                                            disableBtn ?
                                            <>
                                            <span class="spinner-border spinner-border-sm me-05" role="status" aria-hidden="true"></span>
                                            Uploading...</>
                                            :
                                            'Upload'
                                        }
                                    </button>
                                </div>
                            </div>
                            </>
                        }
                    </div>
                </Modal.Header>
                <Modal.Body class="pt-0 modal-body">
                    {
                        fileError.text.length > 0 &&
                        <>
                            {/* <div className={`alert alert-outline-${fileError.status} mb-1 mt-1`} role="alert">
                                {fileError.text}
                            </div> */}
                            <div className={`alert alert-${fileError.status} my-2 alert-dismissible fade show`} role="alert">
                                <strong>{fileError.text}</strong> 
                                <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => setFilesError('', '')}></button>
                            </div>
                        </>
                    }
                    <div class="section mt-1 p-0">
                        {
                            !cropperActive ?
                                <div class="custom-file-upload" id="fileUpload1">
                                    <input type="file" id="fileuploadInput" onChange={handleFileSelect} accept=".png, .jpg, .jpeg" />
                                    <label for="fileuploadInput">
                                        <span>
                                            <strong>
                                                <ion-icon name="cloud-upload-outline"></ion-icon>
                                                <i>Tap to Upload</i>
                                            </strong>
                                        </span>
                                    </label>
                                </div>
                                :
                                <div style={{'width': '100%', 'height': '220px'}}>
                                    <Cropper
                                        aspectRatio={1} // 
                                        style={{ height: "100%", width: "100%" }}
                                        zoomable={false}
                                        preview=".img-preview"
                                        src={selectImage}
                                        viewMode={1}
                                        minCropBoxHeight={50}
                                        minCropBoxWidth={50}
                                        background={false}
                                        responsive={true}
                                        autoCropArea={1}
                                        checkOrientation={false}
                                        id="cropperId"
                                        onInitialized={(instance) => {
                                            setCropper(instance);
                                        }}
                                        // setCropperAspectRatio={(cropperAspectRatio !== null && typeof (cropperAspectRatio) !== 'string' && cropperAspectRatio.value !== undefined) ? cropperAspectRatio.value : NaN}
                                        ref={croperef}
                                        guides={true}

                                    />
                                </div>
                        }
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}

const mapStateToProps = ({ imageEdit }) => {
    const {
        imageEditModalVisible,
        imageEditData
    } = imageEdit
    return {
        imageEditModalVisible,
        imageEditData
    }
}

const mapDispatchToProps = {
    hideImageEditModal,
    showItemProfileOffcanvas,
    setItemProfileDetails
}

export default connect(mapStateToProps, mapDispatchToProps)(ImageEditModal)