import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { connect } from "react-redux"
import { useNavigate } from 'react-router-dom'
import jwt_decode from 'jwt-decode'
import { hideOrderDetailModal } from '../../../features/Offcanvas/orderDetailModalSlice'
import Logo from '../../images/logo.svg'
import axios from '../../../AxiosConfig'
import moment from 'moment-timezone'
import { showConfirmationModal } from '../../../features/Offcanvas/confirmationModalSlice'
import { triggerBusinessdDataCall } from '../../../features/utilities/generalSlice'
import { setPrintInvoice, setInvoiceOrderList } from '../../../features/Data/dataSlice'

const OrderDetailModal = ({
    orderDetailModalVisible,
    orderDetaildata,
    hideOrderDetailModal,
    showConfirmationModal,
    businessData,
    triggerBusinessdDataCall,
    triggerFunction,
    setPrintInvoice,
    setInvoiceOrderList,
    buisness_ID,
    role,
    authToken
}) => {
    const [userValue, setUserValue] = useState(null)
    const [businessName, setBusinessName] = useState('')
    const [branchName, setBranchName] = useState('')
    const [businessLogo, setBusinessLogo] = useState('')
    let navigate = useNavigate()
    const targetTimezone = 'Asia/Kolkata'
    useEffect(() => {
        if (businessData === null || businessData === undefined) {
            triggerBusinessdDataCall({triggerFunction: true})
        } else if (role === 10) {
            businessData.map((value, key) => {
            if (orderDetaildata !== null && buisness_ID === value.ID) {
                setBusinessName(value.Name)
                setBusinessLogo(value.Logo)
                value.Branches.map((v,k) => {
                    if (orderDetaildata['Orders'][0].Location_ID === v.ID) {
                        setBranchName(v.Name)
                    }
                })  
                }
            })
        } else {
            // businessData.map((value, key) => {
            //     if (orderDetaildata !== null && orderDetaildata['Orders'][0].Business_ID === value.ID) {
            //     // if (orderDetaildata !== null && buisness_ID === value.ID) {
            //         setBusinessName(value.Name)
            //         setBusinessLogo(value.Logo)
            //         value.Branches.map((v,k) => {
            //             if (orderDetaildata['Orders'][0].Location_ID === v.ID) {
            //                 setBranchName(v.Name)
            //             }
            //         })  
            //     }
            // })
            const tokenData = jwt_decode(authToken)
            if (businessData) {
                businessData.map((value, key) => {
                    value.Branches.map((v,k) => {
                        if (value.ID === tokenData.userInfo.Business_ID && v.ID === tokenData.userInfo.DefaultLocationID) {
                            setBranchName(v.Name)
                            setBusinessName(value.Name)
                            setBusinessLogo(value.Logo)
                        }
                    })
                })   
            }
        }
        if (businessData === null || businessData === undefined || businessData === '') {
            navigate('/load')
        }
    }, [businessData, triggerFunction, buisness_ID, role])
    useEffect(() => {
        axios.get('/Customers').then(res => {
            if (res.data.message === 'Success') {
                {res.data.data.map(value => {
                    if (orderDetaildata !== null && orderDetaildata !== undefined) {
                        if (orderDetaildata['Orders'][0].Customer_ID === value.ID) {
                            setUserValue(value)
                        }
                    }
                })}
            }
        })
    }, [orderDetailModalVisible])

    const handleDeleteOrder = (orderID, message) => {
        hideOrderDetailModal()
        showConfirmationModal({confirmationModalVisible: true, ID: orderID, message: message, deleteType: 'Invoices'})
    }

    const handlePrintInvoice = () => {
        hideOrderDetailModal()
        setPrintInvoice({printInvoice: true})
        setInvoiceOrderList({invoiceWithOrders: orderDetaildata, paymentStatus: 'paid'})
    }
    return (
        <div>
            <Modal show={orderDetailModalVisible} centered={true}>
                <div class="rounded-circle d-flex align-items-center justify-content-center text-light" style={{height: '25px', width: '25px', background: 'rgba(0,0,0,0.3)', position: 'absolute', top: '-13px', right: '-13px', cursor: 'pointer'}} onClick={() => {hideOrderDetailModal()}}>
                    <ion-icon name="close-outline"></ion-icon>
                </div>
                <Modal.Body class="modal-body m-1 p-1 border">
                    <div class="section full mt-0">
                        <div class="invoice m-0 p-1 shadow-none">
                            <div class="invoice-page-header align-items-center">
                                {(businessLogo === null || businessLogo === undefined || businessLogo === '') &&
                                    <div class="invoice-logo">
                                        <img src={Logo} width="70px" alt="logo" class="h-auto" />
                                    </div>
                                }
                                {businessLogo &&
                                    <div class="invoice-logo">
                                        <img src={businessLogo} width="70px" alt="logo" class="h-auto" />
                                    </div>
                                }
                                <div>Bill No: {orderDetaildata && orderDetaildata.billNo}</div>
                            </div>
                            <div class="invoice-person mt-4">
                                <div class="invoice-to">
                                    <h4 class="fw-bolder">{businessName}</h4>
                                    <ion-icon name="location-outline"></ion-icon>{branchName}
                                    {userValue && (userValue.ContactNumber !== null || userValue.Email !== null) &&
                                        <p>
                                            <a href={'tel:' + (userValue && userValue.ContactNumber)} style={{color: 'inherit', fontSize: 'small'}}>{userValue && userValue.ContactNumber}</a>,&nbsp;
                                            <a href={'mailto:' + (userValue && userValue.Email)} style={{color: 'inherit', fontSize: 'small'}}>{userValue && userValue.Email}</a>
                                        </p>
                                    }
                                </div>
                                <div class="invoice-from">
                                    <h4 class="fw-bolder">
                                        {(orderDetaildata && orderDetaildata.PaymentMethod) &&
                                            <>
                                                {orderDetaildata && orderDetaildata.PaymentMethod},&nbsp;
                                            </>
                                        } 
                                        {orderDetaildata && orderDetaildata.Orders[0]['Type']}
                                    </h4>
                                    <h4 class="fw-bolder">
                                        {moment(orderDetaildata && orderDetaildata.DateTime).tz(targetTimezone).format('DD-MM-YYYY, HH:mm:ss')}
                                        {/* {orderDetaildata && orderDetaildata.DateTime} */}
                                    </h4>
                                    {/* <p>mobilekit@domain.com</p> */}
                                    <p>{userValue && userValue.City}</p>
                                </div>
                            </div>
                            <div class="invoice-detail mt-4">
                                <div class="table-responsive">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <td>DESCRIPTION</td>
                                                <td>QTY</td>
                                                <td>SUBTOTAL</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(orderDetaildata !== null && orderDetaildata !== undefined) &&
                                                <>
                                                    {orderDetaildata?.itemArr.map((v,k) => {
                                                        return <>
                                                            <tr>
                                                                <td>{v.ItemTitle}</td>
                                                                <td>{v.quantity}</td>
                                                                <td>₹{v.MRP * v.quantity}</td>
                                                            </tr>
                                                        </>
                                                    })}
                                                </>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            {/* <div class="invoice-signature mt-4">
                                <div class="signature-block">
                                    Signature Here
                                </div>
                            </div> */}
                            <div class="invoice-total">
                                <ul class="listview transparent simple-listview flush">
                                    <li>Subtotal <span class="hightext">₹{orderDetaildata && orderDetaildata.Subtotal}</span></li>
                                    <li>Tax (0%)<span class="hightext">₹0</span></li>
                                    {(orderDetaildata && orderDetaildata.DiscountType !== null && orderDetaildata && orderDetaildata.DiscountType !== undefined && orderDetaildata && orderDetaildata.DiscountType !== '') &&
                                        <li class="text-success">Discount ({orderDetaildata && orderDetaildata.DiscountType})<span class="hightext">₹{orderDetaildata.TotalDiscount}</span></li>
                                    }
                                    <li>Total<span class="totaltext text-primary">₹{orderDetaildata && orderDetaildata.GrandTotal}</span></li>
                                    {((orderDetaildata && orderDetaildata.BalanceAmount) > 0) &&
                                        <>
                                            <li>Paid <span class="hightext">₹{orderDetaildata && orderDetaildata.PaidAmount}</span></li>
                                            <li class="text-danger">Balance <span class="hightext">₹{orderDetaildata && orderDetaildata.BalanceAmount}</span></li>
                                        </>
                                    }
                                </ul>
                            </div>

                            <h6 class="border-top pt-2">Remark: <span class="fw-bolder">N/A</span></h6>

                            <p class="d-flex align-items-center justify-content-center mb-0" style={{fontSize: '13px'}}>
                                <button class="btn btn-link btn-sm p-0"><ion-icon name="share-social-outline" class="me-2 fs-4"></ion-icon></button>
                                <button class="btn btn-link btn-sm p-0" onClick={handlePrintInvoice}><ion-icon name="print-outline" class="me-2 fs-4"></ion-icon></button>
                                <button class="btn btn-link btn-sm p-0" onClick={() => {handleDeleteOrder(orderDetaildata.ID, 'Are you sure you want to cancel this Order ?')}}><ion-icon name="trash-bin-outline" class="fs-4 me-2"></ion-icon></button>
                            </p>

                            {/* <div class="invoice-bottom">
                                This invoice is for preview purposes only.
                            </div> */}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}

const mapStateToProps = ({ orderDetail, data, general, auth }) => {
    const {
        orderDetailModalVisible,
        orderDetaildata
    } = orderDetail
    const {
        businessData
    } = data
    const {
        triggerFunction
    } = general
    const {
        buisness_ID,
        role,
        authToken
    } = auth
    return {
        orderDetailModalVisible,
        orderDetaildata,
        businessData,
        triggerFunction,
        buisness_ID,
        role,
        authToken
    }
  }
  
  const mapDispatchToProps = {
    hideOrderDetailModal,
    showConfirmationModal,
    triggerBusinessdDataCall,
    setPrintInvoice,
    setInvoiceOrderList
  }

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetailModal)