import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    addUserVisible: false,
    userData: null,
    mobileNumberExist: false
}

export const AddUserModalSlice = createSlice({
    name: 'addUser',
    initialState,
    reducers: {
      setUserData: (state, action) => {
        const {
            userData
        } = action.payload
        state.userData = userData
      },
      showAddUserModal: (state, action) => {
        const {
            addUserVisible
        } = action.payload
        state.addUserVisible = addUserVisible
      },
      hideAddUserModal: (state) => {
        state.addUserVisible = false
      },
      checkMobileNumber: (state, action) => {
        const {
            mobileNumberExist
        } = action.payload
        state.mobileNumberExist = mobileNumberExist
      },
    }
})

export const { showAddUserModal, hideAddUserModal, setUserData, checkMobileNumber } = AddUserModalSlice.actions;


export default AddUserModalSlice.reducer