import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    toastVisible: false
}

export const ToastSlice = createSlice({
    name: 'toast',
    initialState,
    reducers: {
      showToast: (state, action) => {
        const {
            toastVisible,
        } = action.payload
        state.toastVisible = toastVisible
      },
      hideToast: (state) => {
        state.toastVisible = false
      }
    }
})

export const { showToast, hideToast } = ToastSlice.actions;


export default ToastSlice.reducer