import React, { useState, useEffect } from 'react'
import Offcanvas from 'react-bootstrap/Offcanvas'
import { connect } from "react-redux"
import { useNavigate } from 'react-router-dom'
import axios from '../../../AxiosConfig'
import MessageToast from '../../Elements/Notification/MessageToast'
import { hideItemProfileOffcanvas } from '../../../features/Offcanvas/itemProfileOffcanvasSlice'
import { showImageEditModal } from '../../../features/Offcanvas/imageEditModalSlice'
import Veg from '../../images/icon/veg.png'
import NonVeg from '../../images/icon/non-veg.png'
import { Navigate } from 'react-router'

const ItemProfileOffcanvas = ({
    itemProfileVisible,
    hideItemProfileOffcanvas,
    itemProfileData,
    categories,
    showImageEditModal
}) => {
    const [shortCode, setShortCode] = useState('')
    const [color, setColor] = useState('')
    const [alternateText, setAlternateText] = useState('')
    const [foodType, setFoodType] = useState('')
    let navigate = useNavigate()
    const addToQuickAdd = (quickAddStatus, ID) => {
        hideItemProfileOffcanvas()
        const itemQuickAddStatus = {
            "QuickAdd": quickAddStatus
        }
        axios.post('/ItemAvailability/' + ID, itemQuickAddStatus).then(res => {
            if (res.data.message === 'Success') {
            } else {
                // showAppToast({toastMessage: 'Something went wrong',background: 'danger',icon: 'close',position: 'center',timeout: 1000})
            }
        })
    }
    const updateItemProfile = (ID) => {
        const updatedItemProfile = {
            'Shortcode': shortCode,
            'Color': color,
            'AlternateText': alternateText,
            'FoodType': foodType
        }
        axios.post('/ItemAvailability/' + ID, updatedItemProfile).then(res => {
            if (res.data.message === 'Success') {
                hideItemProfileOffcanvas()
            } else {
                hideItemProfileOffcanvas()
                // showAppToast({toastMessage: 'Something went wrong',background: 'danger',icon: 'close',position: 'center',timeout: 1000, isVisible: true})
            }
        })
    }
    useEffect(() => {
        if (itemProfileVisible === false || itemProfileVisible === 'false') {
            setShortCode('')
            setColor('')
            setAlternateText('')
            setFoodType('')
        } else if (itemProfileVisible === true || itemProfileVisible === 'true') {
            setShortCode((itemProfileData) ? itemProfileData.Shortcode : '')
            setColor((itemProfileData) ? itemProfileData.Color : '')
            setAlternateText((itemProfileData) ? itemProfileData.AlternateText : '')  
            setFoodType((itemProfileData) ? itemProfileData.FoodType : '') 
        }
    }, [itemProfileVisible])
    const handleItemImageEdit = (data) => {
        showImageEditModal({imageEditModalVisible: true, imageEditData: data})
    }
    const editItemProfile = () => {
        hideItemProfileOffcanvas()
        navigate('/itemProfile')
    }
    return (
        <div>
            <Offcanvas show={itemProfileVisible} placement={'bottom'} style={{height: 'auto', transition: '0.4s'}}>
                <Offcanvas.Header closeButton style={{minHeight: '36px'}} onHide={() => {hideItemProfileOffcanvas()}}>
                    <div class="d-flex justify-content-between w-100">
                        <Offcanvas.Title style={{display: 'flex', alignItems: 'center'}}>Item Detail</Offcanvas.Title>
                        <Offcanvas.Title style={{display: 'flex', alignItems: 'center'}}>
                            {(itemProfileData?.QuickAdd === null || itemProfileData?.QuickAdd === undefined || itemProfileData?.QuickAdd === 'f' || itemProfileData?.QuickAdd === '') &&
                                <button class="btn btn-sm btn-success" onClick={() => {addToQuickAdd('t', itemProfileData?.ID)}}>Add To Quick</button>
                            }
                            {itemProfileData?.QuickAdd === 't' &&
                                <button class="btn btn-sm btn-outline-secondary" onClick={() => {addToQuickAdd('f', itemProfileData?.ID)}}>Remove From Quick</button>
                            }
                        </Offcanvas.Title>
                    </div>
                    <MessageToast os={false}></MessageToast>
                </Offcanvas.Header>
                <Offcanvas.Body class="offcanvas-body">
                    <div class="section p-0">
                        <div class="profile-head">
                            <div class="avatar me-1">
                                {(itemProfileData?.ImageURL === null || itemProfileData?.ImageURL === undefined || itemProfileData?.ImageURL === '') &&
                                    <img src="https://us.123rf.com/450wm/viktorijareut/viktorijareut1503/viktorijareut150300119/37649514-grey-tray-kitchenware-trey-isolated-tray-vector-closed-tray.jpg?ver=6" alt="avatar" class="imaged rounded" width="50px" onClick={() => {
                                        const tempItm = JSON.parse(JSON.stringify(itemProfileData));
                                        tempItm.ImageURL = 'https://us.123rf.com/450wm/viktorijareut/viktorijareut1503/viktorijareut150300119/37649514-grey-tray-kitchenware-trey-isolated-tray-vector-closed-tray.jpg?ver=6'
                                        handleItemImageEdit(tempItm);
                                    }} />
                                }
                                {(itemProfileData?.ImageURL !== null && itemProfileData?.ImageURL !== undefined && itemProfileData?.ImageURL !== '') &&
                                    <div class="position-relative">
                                        {/* <div class="position-absolute rounded-circle d-flex align-items-center justify-content-center" onClick={() => {handleItemImageEdit(itemProfileData)}} style={{objectFit: 'cover', top:0, right: 0, left: 0, bottom: 0, backgroundColor: '#2222225e', cursor: 'pointer'}}>
                                            <ion-icon name="camera-outline" class="text-light fs-4"></ion-icon>
                                        </div> */}
                                        <img src={itemProfileData?.ImageURL} alt="avatar" class="imaged rounded" style={{height: '50px', width: '50px', objectFit: 'cover'}} />
                                    </div>
                                }
                            </div>
                            <div class="in">
                                <h5 class="subtext">Item ID: #{itemProfileData?.Item_ID}</h5>
                                <h3 class="name m-0">
                                    <img src={(foodType === 'veg') ? Veg : NonVeg} style={{width: '14px', paddingBottom: '3px', marginRight: '2px'}} />
                                    {itemProfileData?.ItemTitle}
                                </h3>
                                {(categories !== null && categories !== undefined) &&
                                    <>
                                        {categories.map((v,k) => {
                                            return <h5 class="subtext">{(itemProfileData?.Item_CategoryID === v.ID) ? 'Category:' + v.Name: ''}</h5>
                                        })}
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                    <div class="section p-0 mt-1">
                        <div class="row">
                            <div class="col-6 col-md-6">
                                <span class="badge bg-success w-100">Cost: ₹{itemProfileData?.Cost}</span>
                            </div>
                            <div class="col-6 col-md-6">
                                <span class="badge bg-secondary w-100">MRP: ₹{itemProfileData?.MRP}</span>
                            </div>
                        </div>
                    </div>
                    <div class="section full mt-2">
                        <div class="py-2 px-0 border-top">
                            <div class="row">
                                <div class="col-4 col-md-4">
                                    <div class="form-group basic">
                                        <div class="input-wrapper">
                                            <label class="form-label" for="short_code">Short Code</label>
                                            <input type="text" class="border-0 bg-transparent" id="short_code" placeholder="Enter Short Code" disabled value={(shortCode) ? shortCode : 'N/A'} autocomplete="off" onChange={(e) => {setShortCode(e.target.value)}} />
                                            <i class="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-4 col-md-4">
                                    <div class="form-group basic">
                                        <div class="input-wrapper">
                                            <label class="form-label" for="color">Color</label>
                                            <input type="color" class="border-0 bg-transparent" id="color" placeholder="Enter Short Code" disabled value={(color) ? color : 'N/A'} autocomplete="off" onChange={(e) => {setColor(e.target.value)}} />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-4 col-md-4">
                                    <div class="form-group basic">
                                        <div class="input-wrapper">
                                            <label class="form-label" for="alternate_name">Alternate Name</label>
                                            {/* <input type="text" class="form-control" id="alternate_name" placeholder="Enter Alternate Name" value={alternateText} autocomplete="off" onChange={(e) => {setAlternateText(e.target.value)}} /> */}
                                            <input type="text" class="border-0 bg-transparent" id="alternate_name" placeholder="Enter Alternate Name" disabled value={(alternateText) ? alternateText : 'N/A'} autocomplete="off" onChange={(e) => {setAlternateText(e.target.value)}} />
                                            <i class="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                        </div>
                                    </div>
                                </div>
                                {/* <div class="col-12 col-md-12">
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" value="veg" name="radioInline" id="radioInlineDefault1" checked={(foodType === 'veg') ? true : false} onChange={(e) => {setFoodType(e.target.value)}} />&nbsp;
                                        <label class="form-check-label" for="radioInlineDefault1">Veg</label>
                                    </div>&nbsp;&nbsp;&nbsp;&nbsp;
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" value="non-veg" name="radioInline" id="radioInlineDefault2" checked={(foodType === 'non-veg') ? true : false} onChange={(e) => {setFoodType(e.target.value)}} />&nbsp;
                                        <label class="form-check-label" for="radioInlineDefault2">Non-Veg</label>
                                    </div>
                                </div> */}
                                <div class="col-12 col-md-12 text-end">
                                    {/* <button class="btn btn-success" onClick={() => {updateItemProfile(itemProfileData?.ID)}}>Update</button> */}
                                    <button class="btn btn-primary" onClick={() => {editItemProfile()}}>Edit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    )
}

const mapStateToProps = ({ itemProfile, data }) => {
    const {
        itemProfileVisible,
        itemProfileData
    } =  itemProfile
    const {
        categories
    } = data
    return {
        itemProfileVisible,
        itemProfileData,
        categories
    }
}
  
const mapDispatchToProps = {
    hideItemProfileOffcanvas,
    showImageEditModal
}

export default connect(mapStateToProps, mapDispatchToProps)(ItemProfileOffcanvas)