import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    paymentOffcanvasVisible: false,
    fromState: ''
}

export const PaymentOffcanvasSlice = createSlice({
    name: 'payment',
    initialState,
    reducers: {
      showPaymentOffcanvas: (state, action) => {
        const {
            paymentOffcanvasVisible,
            fromState
        } = action.payload
        state.paymentOffcanvasVisible = paymentOffcanvasVisible
        state.fromState = fromState
      },
      hidePaymentOffcanvas: (state) => {
        state.paymentOffcanvasVisible = false
      }
    }
})

export const { showPaymentOffcanvas, hidePaymentOffcanvas } = PaymentOffcanvasSlice.actions;


export default PaymentOffcanvasSlice.reducer