import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import axios from '../../../AxiosConfig'
import Offcanvas from 'react-bootstrap/Offcanvas'
import { hideUserListOffcanvas } from '../../../features/Offcanvas/userListOffcanvasSlice'
import { setUserData } from '../../../features/Data/dataSlice'

const UserBottomOffcanvas = ({
    userListOffcanvasVisible,
    hideUserListOffcanvas,
    inOut,
    setUserData
}) => {
    const [userList, setUserList] = useState(null)
    useEffect(() => {
        axios.get('/users/getUsers/' + inOut).then(res => {
            if (res.data.message === 'Success') {
              setUserList(res.data.data)
            } else {
              // showAppToast({toastMessage: 'Something went wrong',background: 'danger',icon: 'close',position: 'center',timeout: 1000})
            }
        })
    }, [inOut])

    const handleSelectedUser = (userData) => {
        setUserData({userData: userData})
        hideUserListOffcanvas()
    }
    return (
        <div>
            <Offcanvas show={userListOffcanvasVisible} placement={'bottom'} style={{height: 'auto', transition: '0.5s'}} onHide={() => {hideUserListOffcanvas()}}>
                <Offcanvas.Header closeButton style={{minHeight: '36px'}}>
                    <Offcanvas.Title class="w-100">
                        <div class="row w-100">
                            <div class="col-12 col-md-12 d-flex align-items-center">
                                User Name
                            </div>
                        </div>
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body class="pt-0 offcanvas-body p-0">
                <ul class="listview image-listview">
                    {(userList !== null && userList !== undefined && userList.length > 0) &&
                        <> 
                            {userList.map((value,key) => {
                                return <li onClick={() => {handleSelectedUser(value)}}>
                                    <div class="item pt-0 pb-0">
                                        <ion-icon name="person-circle-outline" class="image text-secondary"></ion-icon>
                                        <div class="in">
                                            <div>{value?.FullName}</div>
                                            <span class="text-muted">{value?.City}, {value?.Country}</span>
                                        </div>
                                    </div>
                                </li>
                            })}
                        </>
                    }
                </ul>
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    )
}

const mapStateToProps = ({ userList }) => {
    const {
        userListOffcanvasVisible
    } = userList
    return {
        userListOffcanvasVisible
    }
  }
  
const mapDispatchToProps = {
    hideUserListOffcanvas,
    setUserData
}

export default connect(mapStateToProps, mapDispatchToProps)(UserBottomOffcanvas)