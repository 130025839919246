import React, { useEffect, useState } from 'react'
import { connect } from "react-redux"
import jwt_decode from 'jwt-decode'
import { useNavigate, useLocation } from 'react-router-dom'
import { generalDataUpdate } from '../../features/utilities/generalSlice'
import { showItemProfileOffcanvas, setItemProfileDetails } from '../../features/Offcanvas/itemProfileOffcanvasSlice'
import Moment from 'react-moment'
import axios from '../../AxiosConfig'
import Veg from '../images/icon/veg.png'
import NonVeg from '../images/icon/non-veg.png'
import InwardOutwardDetailModal from '../layout/Sidebar/InwardOutwardDetailModal'
import { showInwardOutwardModal } from '../../features/Offcanvas/inwardOutwardModalSlice'

const Items = ({
    generalDataUpdate,
    categories,
    items,
    categoryID,
    itemName,
    showItemProfileOffcanvas,
    itemProfileVisible,
    authToken,
    setItemProfileDetails,
    showInwardOutwardModal
}) => {
    const [filteredCategory, setFilteredCategory] = useState('')
    const [allCategoryFlag, setAllCategoryFlag] = useState(true)
    const [itemSearch, setItemSearch] = useState('')
    const [stockableValue, setStockableValue] = useState('f')
    const [categoryList, setCategoryList] = useState(null)
    const [inwardOutwardList, setInwardOutwardList] = useState(null)
    const search = useLocation().search
    const inwardOutward = new URLSearchParams(search).get('tab')
    let navigate = useNavigate()
    const tokenData = jwt_decode(authToken)

    const buttonStyle = {
        "buttonText": "Items",
        "buttonColor":"warning"
    }

    useEffect(() => {
        generalDataUpdate({buttonStyle: buttonStyle})
    }, [])

    useEffect(() => {
        if (categories === null || categories === undefined || items === null || items === undefined) {
            navigate('/load')
        }
    }, [categories, items])

    useEffect(() => {
        if (items) {
            if (categoryID !== 'all') {
                setAllCategoryFlag(false)
                setFilteredCategory(items.filter((item) => categoryID === item.Item_CategoryID))
                setItemSearch(items.filter((item) => itemName.includes(item.Title)))
            } else {
                setAllCategoryFlag(true)
                setFilteredCategory(items)
                setItemSearch(items.filter((item) => itemName.includes(item.Title)))
            }
        }
    }, [categoryID])

    const handleItemDetailProfile = () => {
        navigate('/itemProfile')
    }

    const handleStockability = (stockable) => {
        if (stockable === true) {
            setStockableValue('t')
        } else {
            setStockableValue('f')
        }
    }

    const handleAddQuickAdd = (e, detail) => {
        e.stopPropagation();
        showItemProfileOffcanvas({itemProfileVisible: true})
        setItemProfileDetails({itemProfileData: detail})
    }
    const switchManageStock = () => {
        navigate('/manageStock?action=addStock')
    }

    useEffect(() => {
        axios.get('/Stock/getList').then(res => {
            if (res.data.message === 'Success') {
                console.log(res.data.data)
                setInwardOutwardList(res.data.data)
            }
        })
    }, [])

    const handleInwardOutwardDetail = (data) => {
        showInwardOutwardModal({inwardOutwardModalVisible: true, inwardOutwardDetailData: data})
    }
    return (
        <div>
            <div class="extraHeader p-0" style={{height: '36px', zIndex: 10}}>
                <ul class="nav nav-tabs lined" role="tablist">
                    <li class="nav-item">
                        <a class={(inwardOutward !== 'inwardOutward') ? "nav-link active" : "nav-link"} style={{height: '36px'}} data-bs-toggle="tab" href="#inventoryItems" role="tab">
                            Items
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class={(inwardOutward === 'inwardOutward') ? "nav-link active" : "nav-link"} style={{height: '36px'}} data-bs-toggle="tab" href="#inventoryInwardOutward" role="tab">
                            Inward-Outward
                        </a>
                    </li>
                </ul>
            </div>
            <div id="appCapsule" class="extra-header-active" style={{paddingTop: '90px'}}> 
                <div class="tab-content mt-1 mx-0" style={{marginRight: '-8px', marginLeft: '-8px'}}>
                    <div class={ (inwardOutward !== 'inwardOutward') ? "tab-pane fade show active" : "tab-pane fade"} id="inventoryItems" role="tabpanel">         
                        <div class="row">
                            <div class="col-12 col-md-12 col-lg-12">
                                <div class="p-0 mb-4">
                                    <div class="section full mt-1 mb-1">
                                        <div class="row">
                                            <div class="col-12 col-md-12 d-flex align-items-center">
                                                <div class="dropdown d-flex me-1" style={{cursor: 'pointer'}}>
                                                    <div class="badge bg-primary dropdown-toggle fs-8" data-bs-toggle="dropdown">
                                                        <span style={{textTransform: 'uppercase'}}>
                                                            Categories
                                                        </span>
                                                        <ion-icon name="chevron-down-outline" class="fs-6"></ion-icon>
                                                    </div>
                                                    <div class="dropdown-menu p-0">
                                                        {(categories !== undefined && categories !== null) &&
                                                            <>
                                                                {categories.map(value => {
                                                                    return <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)">{value.Name} <ion-icon name={'ellipse-outline'} class="m-0 text-secondary" style={{width: '18px'}}></ion-icon></a>
                                                                })}
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                                <div class="dropdown d-flex me-1" style={{cursor: 'pointer'}}>
                                                    <div class="badge bg-primary dropdown-toggle fs-8" data-bs-toggle="dropdown">
                                                        <span style={{textTransform: 'uppercase'}}>
                                                            {/* <ion-icon name="card-outline" class="m-0 fs-8" style={{width: '18px'}}></ion-icon> */}
                                                            Sort
                                                        </span>
                                                        <ion-icon name="chevron-down-outline" class="fs-6"></ion-icon>
                                                    </div>
                                                    <div class="dropdown-menu p-0">
                                                        <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)">Low to High <ion-icon name={'ellipse-outline'} class="m-0 text-secondary" style={{width: '18px'}}></ion-icon></a>
                                                        <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)">High to Low <ion-icon name={'ellipse-outline'} class="m-0 text-secondary" style={{width: '18px'}}></ion-icon></a>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div class="col-4 col-md-4 d-flex align-items-center justify-content-end">
                                                <div class="form-check form-switch">
                                                    <input class="form-check-input" type="checkbox" id="SwitchCheckDefault1" onChange={(e) => {handleStockability(e.target.checked)}} />
                                                    <label class="form-check-label position-relative" for="SwitchCheckDefault1">
                                                        <small class="fs-10 text-secondary" style={{position: 'absolute', bottom: '-20px', left: '-6px', textWrap: 'nowrap'}}>Only Stockable</small>
                                                    </label>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                    <div class="section full mt-1">
                                        {(categories !== null && categories !== undefined) &&
                                            <>
                                                {categories.map(v => {
                                                    return <>
                                                        <div class="listview-title sticky-title" style={{marginRight: '-8px', marginLeft: '-8px'}}>
                                                            <strong>{v.Name}</strong>
                                                        </div>
                                                        <ul class="listview image-listview flush bg-transparent">
                                                            {items !== null &&
                                                                <>
                                                                    {items.map((value, key) => {
                                                                        console.log(value)
                                                                        return <>
                                                                            {value.Stockable === 't' &&
                                                                                <>
                                                                                    {v.ID === value.Item_CategoryID &&
                                                                                        <li onClick={(e) => {handleAddQuickAdd(e, value)}}>
                                                                                            <a href="javascript:void(0)" class="item ps-0">
                                                                                            <div style={{height: '50px',width: '10px',color: 'transparent',marginRight: '2px', backgroundColor: value.Color}}>.</div>
                                                                                                <div class="imageWrapper me-1">
                                                                                                    {(value.ImageURL !== undefined && value.ImageURL !== null && value.ImageURL !== '') &&
                                                                                                        <img src={value.ImageURL && value.ImageURL} alt="image" class="imaged" style={{height: '50px', width: '50px', objectFit: 'cover'}} />
                                                                                                    }
                                                                                                    {(value.ImageURL === '') &&
                                                                                                        <img src="https://us.123rf.com/450wm/viktorijareut/viktorijareut1503/viktorijareut150300119/37649514-grey-tray-kitchenware-trey-isolated-tray-vector-closed-tray.jpg?ver=6" alt="image" class="imaged" style={{height: '50px', width: '50px', objectFit: 'cover'}} />
                                                                                                    }
                                                                                                </div>
                                                                                                <div class="in">
                                                                                                    <div>
                                                                                                        <header>{value.Shortcode}</header>
                                                                                                        {value.FoodType === 'veg' &&
                                                                                                            <img src={Veg} width="14px" style={{paddingBottom: '2px', marginRight: '2px'}} />
                                                                                                        }
                                                                                                        {value.FoodType === 'non-veg' &&
                                                                                                            <img src={NonVeg} width="14px" style={{paddingBottom: '2px', marginRight: '2px'}} />
                                                                                                        }
                                                                                                        {value.ItemTitle}
                                                                                                        {(value.AlternateText !== '' && value.AlternateText !== null && value.AlternateText !== undefined) &&
                                                                                                            <small> ({value.AlternateText})
                                                                                                            </small>
                                                                                                        }
                                                                                                        <footer>₹{value.MRP}</footer>
                                                                                                    </div>
                                                                                                    <span class="text-muted">{(value?.QuickAdd === null || value?.QuickAdd === undefined || value?.QuickAdd === 'f' || value?.QuickAdd === '') &&
                                                                                                        <i class="fa fa-star text-secondary me-1 fs-5" aria-hidden="true"></i>
                                                                                                    }
                                                                                                    {value?.QuickAdd === 't' &&
                                                                                                        <i class="fa fa-star text-warning me-1 fs-5" aria-hidden="true"></i>
                                                                                                    }Edit</span>
                                                                                                </div>
                                                                                            </a>
                                                                                        </li>
                                                                                    }
                                                                                </>
                                                                            }
                                                                        </>
                                                                    })}
                                                                </>
                                                            }
                                                        </ul>
                                                    </>
                                                })}
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        {tokenData?.userInfo?.RoleID !== '5' &&
                            <div class="fab-button text bottom-center responsive-bottom-fab">
                                <button class="fab fs-6 pe-2 ps-2" style={{height: '40px', fontFamily: "'Inter', sans-serif"}} disabled>
                                    <ion-icon name="add-outline" class="fs-6"></ion-icon>
                                    New Item
                                </button>
                            </div>
                        }
                    </div>
                    <div class={ (inwardOutward === 'inwardOutward') ? "tab-pane fade show active" : "tab-pane fade"}  id="inventoryInwardOutward" role="tabpanel">
                        <div class="row">
                            <div class="col-12 col-md-12 my-2 mx-2">
                                <div class="dropdown d-flex me-1" style={{cursor: 'pointer'}}>
                                    <div class="badge bg-primary dropdown-toggle fs-8" data-bs-toggle="dropdown">
                                        <span style={{textTransform: 'uppercase'}}>
                                            <ion-icon name="log-in-outline" class="m-0 fs-8" style={{width: '18px'}}></ion-icon>
                                            INWARD
                                        </span>
                                        <ion-icon name="chevron-down-outline" class="fs-6"></ion-icon>
                                    </div>
                                    <div class="dropdown-menu p-0">
                                        <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)">Inward</a>
                                        <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)">Outward</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="section full">
                            {inwardOutwardList?.length > 0 &&
                                <ul class="listview image-listview flush bg-transparent">
                                    {inwardOutwardList?.map((v,k) => {
                                        return <li onClick={() => {handleInwardOutwardDetail(v)}}>
                                            <a href="javascript:void(0)" class="item ps-1">
                                                {v.StockType === 'addStock' &&
                                                    <div class="icon-box bg-warning">
                                                        <ion-icon name="bag-add-outline"></ion-icon>
                                                    </div>
                                                }
                                                {v.StockType === 'removeStock' &&
                                                    <div class="icon-box bg-danger">
                                                        <ion-icon name="bag-remove-outline"></ion-icon>
                                                    </div>
                                                }
                                                {v.StockType === 'transferOut' &&
                                                    <div class="icon-box" style={{backgroundColor: '#fa758d'}}>
                                                        <ion-icon name="swap-horizontal-outline"></ion-icon>
                                                    </div>
                                                }
                                                {v.StockType === 'request' &&
                                                    <div class="icon-box bg-secondary">
                                                        <ion-icon name="bag-check-outline"></ion-icon>
                                                    </div>
                                                }
                                                <div class="in">
                                                    <div>
                                                        <header><Moment fromNow>{v.DateTime}</Moment></header>
                                                        {v.StockType === 'addStock' &&
                                                            <>
                                                                Added
                                                            </>
                                                        }
                                                        {v.StockType === 'removeStock' &&
                                                            <>
                                                                Removed
                                                            </>
                                                        }
                                                        {v.StockType === 'transferOut' &&
                                                            <>
                                                                Transfered
                                                            </>
                                                        }
                                                        {v.StockType === 'request' &&
                                                            <>
                                                                Requested
                                                            </>
                                                        }
                                                        <footer class="mt-0">
                                                            <div class="text">
                                                                {/* <img src="https://us.123rf.com/450wm/viktorijareut/viktorijareut1503/viktorijareut150300119/37649514-grey-tray-kitchenware-trey-isolated-tray-vector-closed-tray.jpg?ver=6" alt="avatar" class="imaged w24 rounded" style={{height: '24px', marginRight: '2px', objectFit: 'cover'}} /> */}
                                                                <span class="badge bg-secondary me-1" style={{height: '18px'}}>{v.Items.length} Items</span>
                                                                <span class="badge bg-secondary me-1" style={{height: '18px'}}>{v.TotalQty} Qty</span>
                                                            </div>
                                                            
                                                        </footer>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                    })}
                                </ul>
                            }
                            {inwardOutwardList?.length === 0 &&
                                <h1 class="text-danger d-flex align-items-center justify-content-center mt-5">No Items Added</h1>
                            }
                        </div>
                        <div class="fab-button text bottom-center responsive-bottom-fab">
                            <button class="fab pe-2 ps-2" style={{height: '40px', fontFamily: "'Inter', sans-serif", fontSize: '14px'}} onClick={switchManageStock}>
                                {/* <ion-icon name="add-outline" class="fs-6"></ion-icon> */}
                                Add / Remove Stocks
                            </button>
                        </div>
                        <InwardOutwardDetailModal></InwardOutwardDetailModal>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({ data, itemProfile, auth }) => {
    const {
        categories,
        items,
        categoryID,
        itemName
    } = data
    const {
        itemProfileVisible
    } = itemProfile
    const {
        authToken
    } = auth
    return {
        categories,
        items,
        categoryID,
        itemName,
        itemProfileVisible,
        authToken
    }
  }
  
const mapDispatchToProps = {
    generalDataUpdate,
    showItemProfileOffcanvas,
    setItemProfileDetails,
    showInwardOutwardModal
}

export default connect(mapStateToProps, mapDispatchToProps)(Items)
