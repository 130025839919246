import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import axios from '../../../AxiosConfig'
import { useNavigate } from 'react-router-dom'
import { connect } from "react-redux"
import jwt_decode from 'jwt-decode'
import { hideSwitchBusinessModal } from '../../../features/Offcanvas/switchBusinessModalSlice'
import { showAppToast } from '../../../features/utilities/appToastSlice'
import { setToken } from '../../../features/auth/authSlice'

const SwitchBusinessModal = ({
    switchBusinessModalVisible,
    hideSwitchBusinessModal,
    showAppToast,
    setToken,
    businessData,
    authToken
}) => {
    const [businessList, setBusinessList] = useState(null)
    const localStoragetToken = localStorage.getItem('token')
    const [orderCount, setOrderCount] = useState(0)
    const [orderCountBadge, showOrderCountBadge] = useState(false)
    const tokenData = jwt_decode(authToken)
    const activeBusinessID = tokenData.userInfo.Business_ID
    const activeLocationID = tokenData.userInfo.DefaultLocationID
    let navigate = useNavigate()
    const handleBusinessSwitch = (businessID, locationID) => {
        axios.get('/users/refresh/' + businessID + '/' + locationID).then(res => {
            if (res.data.message === 'Success') {
                localStorage.clear('billNo')
                localStorage.setItem('token', res.data.data)
                setToken({ authToken: res.data.data })
                navigate('/load')
                hideSwitchBusinessModal()
            } else {
              showAppToast({toastMessage: 'Something went wrong',background: 'danger',icon: 'close',position: 'center',timeout: 1000, isVisible: true})
            }
        })
    }

    const getBusinessOrderCount = () => {
        axios.get('/BusinessLocations/getCurrentOrdersCount').then(res => {
            if (res.data.message === 'Success') {
                showOrderCountBadge(true)
                setOrderCount(res.data.data)
            } else {
              showAppToast({toastMessage: 'Something went wrong',background: 'danger',icon: 'close',position: 'center',timeout: 1000, isVisible: true})
            }
        })
    }

    const closeSwitchBusinessModal = () => {
        hideSwitchBusinessModal()
        showOrderCountBadge(false)
    }
    return (
        <Modal show={switchBusinessModalVisible} fullscreen={true} onHide={closeSwitchBusinessModal}>
            <Modal.Header closeButton class="modal-header">
                <Modal.Title>
                    <div class="fs-6 d-flex align-items-center">
                        Switch Branch&nbsp;
                        {tokenData?.userInfo?.RoleID !== '5' &&
                            <button class="btn btn-sm btn-transparent d-flex align-items-center justify-content-center p-0" onClick={getBusinessOrderCount}>
                                <ion-icon name="refresh-outline" class="text-primary m-0"></ion-icon>
                            </button>
                        }
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body class="modal-body">
                {businessData !== null && businessData !== undefined &&
                    <>
                        {businessData.map((value, key) => {
                            return <div class="mb-2">
                                <div class="listview-title p-0">{value.Name}</div>
                                <div class="section full">
                                    {value.Branches.map((v,k) => {
                                        return <a href="javascript:void(0)" class={(activeBusinessID === value.ID && activeLocationID === v.ID) ? 'btn btn-sm btn-outline-secondary me-1 mb-1' : 'btn btn-sm btn-secondary me-1 mb-1'} onClick={() => {handleBusinessSwitch(value.ID, v.ID)}} style={{position: 'relative'}}>
                                            {v.Name}
                                            {orderCountBadge &&
                                                <>
                                                    {tokenData?.userInfo?.RoleID !== '5' &&
                                                        <>
                                                            {orderCount.length > 0 &&
                                                                <>
                                                                    {orderCount.map((v1,k1) => {
                                                                        return <>
                                                                            {v1.ID === v.ID &&
                                                                                <span class="badge bg-white text-dark" style={{position: 'absolute', top: -6, right: -6, fontSize: '8px', height: '15px', minWidth: '15px', paddingRight: '2px', paddingLeft: '2px'}}>{v1.TotalOrders}</span>
                                                                            }
                                                                        </>
                                                                    })}
                                                                </>
                                                            }
                                                        </>
                                                    }
                                                </>
                                            }
                                        </a>
                                    })}
                                </div>
                            </div>
                        })}
                    </>
                }
            </Modal.Body>
        </Modal>
    )
}

const mapStateToProps = ({ switchBusiness, data, auth }) => {
    const {
        switchBusinessModalVisible
    } = switchBusiness
    const {
        businessData
    } = data
    const {
        authToken
    } = auth
    return {
        switchBusinessModalVisible,
        businessData,
        authToken
    }
}
  
const mapDispatchToProps = {
    hideSwitchBusinessModal,
    showAppToast,
    setToken
}

export default connect(mapStateToProps,mapDispatchToProps)(SwitchBusinessModal)