import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    dineInTableOffcanvasVisible: false
}

export const DineInOffcanvasSlice = createSlice({
    name: 'dineIn',
    initialState,
    reducers: {
      showDineInOffcanvas: (state, action) => {
        const {
            dineInTableOffcanvasVisible
        } = action.payload
        state.dineInTableOffcanvasVisible = dineInTableOffcanvasVisible
      },
      hideDineInOffcanvas: (state) => {
        state.dineInTableOffcanvasVisible = false
      }
    }
})

export const { showDineInOffcanvas, hideDineInOffcanvas } = DineInOffcanvasSlice.actions;


export default DineInOffcanvasSlice.reducer