import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import { connect } from "react-redux"
import { useNavigate } from 'react-router'
import jwt_decode from 'jwt-decode'
import axios from '../../../AxiosConfig'
import { setBusinessData } from '../../../features/Data/dataSlice'
import moment from 'moment-timezone'
import ItemsListView from '../../Pages/Components/ItemsListView'
import { hideShopStatusModal } from '../../../features/Offcanvas/shopStatusModalSlice'

const ShopStatusModal = ({
    authToken,
    setBusinessData,
    items,
    shopStatusModalVisible,
    hideShopStatusModal,
    liveShopStatus,
    businessData
}) => {
    const [branchName, setBranchName] = useState('')
    const [businessName, setBusinessName] = useState('')
    const [businessLogo, setBusinessLogo] = useState('')
    const [summaryData, setSummaryData] = useState(null)
    // const [activeTab, setActiveTab] = useState('summary')
    const [todaysDate, setTodaysDate] = useState(new Date().toISOString().substr(0, 10))
    const targetTimezone = 'Asia/Kolkata'
    const currentTime = moment().tz(targetTimezone).format('HH:mm:ss')
    const [localTime, setLocalTime] = useState(currentTime)
    const [tempTodaysDate, setTempTodaysDate] = useState(moment().tz(targetTimezone).format('DD-MM-YYYY'))
    const localStoragetToken = localStorage.getItem('token')
    const tokenData = jwt_decode(localStoragetToken)
    const [userName, setUserName] = useState('')
    const [shopCash, setShopCash] = useState(null)
    const [shopRemark, setShopRemark] = useState('')
    const [nameAlert, showNameAlert] = useState(false)
    const [cashAlert, showCashAlert] = useState(false)
    const [alertMessage, setAlertMessage] = useState('')
    const [currentShopStatus, setCurrentShopStatus] = useState('')
    useEffect(() => {
        const tokenData = jwt_decode(authToken)
        if (businessData) {
            businessData.map((value, key) => {
                value.Branches.map((v,k) => {
                    if (value.ID === tokenData.userInfo.Business_ID && v.ID === tokenData.userInfo.DefaultLocationID) {
                        setBranchName(v.Name)
                        setBusinessName(value.Name)
                        setBusinessLogo(value.Logo)
                    }
                })
            })   
        }
    }, [businessData, authToken])
    let navigate = useNavigate()
    let tempShopStatus = localStorage.getItem('shopStatus') 
    useEffect(() => {
        axios.get('/Reports/dashboardSummary').then(res => {
            if (res.data.message === 'Success') {
                setSummaryData(res.data.data)
            } else {
            }
        }).catch(err => {
        });
    }, [])

    const handleShopStatus = (e, shopStatus) => {
        let tempSnapShot = []
        if (items) {
            items.map((v,k) => {
                if (v.Stockable === 't') {
                    const tempObj = {
                        [v.ID] : v.SalesQty
                    }
                    tempSnapShot.push(tempObj)
                }
            })
        }
        e.preventDefault()
        // if (userName !== '' || shopCash !== null) {
            let data = {
                "Summary":tempSnapShot,
                "Cash": shopCash,
                "Remark": shopRemark,
                "Name": userName,
                "LocalTimeStamp": moment().tz(targetTimezone).format('HH:mm:ss'),
                "ClosingStock": {},
                "Status": shopStatus,
                "Date": todaysDate
            }
            axios.put('/OpeningClosing', data).then(res => {
                if (res.data.message === 'Success') {
                    if (typeof(res.data.data) === 'string') {
                        setAlertMessage(res.data.data)
                    } else {
                        hideShopStatusModal()
                    }
                    axios.get('/OpeningClosing/getShopStatus/' + new Date().toISOString().substr(0, 10)).then(res => {
                        if (res.data.message === 'Success') {
                            setCurrentShopStatus(res.data.data)
                            localStorage.setItem('shopStatus', res.data.data)
                            if (res.data.data === '' || res.data.data === 'Close') {
                                // localStorage.clear()
                                // window.location.reload()
                                localStorage.setItem('shopStatus', 'Close')
                                hideShopStatusModal()
                                // logout()
                                // setTimeout(() => {
                                //     navigate('/')
                                // }, 1000)
                            } else {
                                localStorage.setItem('shopStatus', 'Open')
                                hideShopStatusModal()
                            }
                        } else {
                            // showAppToast({ toastMessage: 'Something went wrong', background: 'danger', icon: 'close', position: 'center', timeout: 1000 })
                        }
                    }).catch(err => {
                        // showAppToast({ toastMessage: 'Something went wrong', background: 'danger', icon: 'close', position: 'center', timeout: 1000 })
                    })
                } else {
                    // showAppToast({ toastMessage: 'Something went wrong', background: 'danger', icon: 'close', position: 'center', timeout: 1000 })
                }
            }).catch(err => {
                // showAppToast({ toastMessage: 'Something went wrong', background: 'danger', icon: 'close', position: 'center', timeout: 1000 })
            });
        // } else {
        //     if (userName === '') {
        //         showNameAlert(true)
        //     } else if (shopCash === null) {
        //         showCashAlert(true)
        //     }
        // }   
    }

    useEffect(() => {
        if (liveShopStatus === 'Open') {
            setAlertMessage('Shop Already Opened')
        } else if (liveShopStatus === 'Close') {
            setAlertMessage('Shop Already Closed')
        }
    }, [liveShopStatus])

    const handleName = (userName) => {
        setUserName(userName)
        if (userName.length !== 0) {
            showNameAlert(false)
        }
    }

    const handleCash = (shopCash) => {
        setShopCash(shopCash)
        if (shopCash.length !== 0) {
            showCashAlert(false)
        } else {
            setShopCash(null)
        }
    }

    const handleRemark = (shopRemark) => {
        setShopRemark(shopRemark)
    }

    const handleContinue = () => {
        setCurrentShopStatus(liveShopStatus)
    }

    useEffect(() => {
        if (currentShopStatus === 'Close') {
            localStorage.setItem('shopStatus', 'Close')
        } else if (currentShopStatus === 'Open') {
            hideShopStatusModal()
            localStorage.setItem('shopStatus', 'Open')
        }
    }, [currentShopStatus])
    return (
        <div>
            <Modal show={shopStatusModalVisible} centered={true} size="lg" backdrop="static" onHide={() => {hideShopStatusModal()}}>
                <Modal.Header closeButton style={{padding: '8px', paddingTop: '0px', paddingBottom: '0px', display: 'flex', justifyContent: 'center'}}>
                    <div class="row w-100">
                        <ul class="listview image-listview flush">
                            <li>
                                <div class="item p-0 ps-1">
                                    {(businessLogo !== null && businessLogo !== undefined) &&
                                        <img src={businessLogo} alt="image" class="image rounded-0" />
                                    }
                                    {(businessLogo === null || businessLogo === undefined) &&
                                        <ion-icon name="storefront-outline" class="me-1" style={{fontSize: '32px'}}></ion-icon>
                                    }
                                    <div class="in">
                                        <div style={{lineHeight: 1.2}}>
                                            {businessName}
                                            <footer><ion-icon name="location-outline" class="me-0"></ion-icon>&nbsp;{branchName}</footer>
                                        </div>
                                    </div>
                                    {/* <span class="text-muted">
                                        <a href="javascript:void(0)">
                                            <ion-icon name="close-outline" class="fs-2"></ion-icon>
                                        </a>
                                    </span> */}
                                </div>
                            </li>
                        </ul>
                    </div>
                </Modal.Header>
                <Modal.Body class="modal-body ps-0 pe-0">
                    {!alertMessage &&
                        <div class="section">
                            <div class="row">
                                <div class="col-4 col-md-4">
                                    <div class="card border shadow-none">
                                        <div class="card-body p-0 pb-1 pt-1 d-flex justify-content-center">
                                            <div class="row ps-1">
                                                <div class="col-12 col-sm-12 col-md-12 col-lg-12 d-flex align-items-center justify-content-center">
                                                    <p class="card-text m-0 text-secondary fs-10">Today's Date</p>
                                                </div>
                                                <div class="col-12 col-sm-12 col-md-12 col-lg-12 text-center">
                                                    <h3 class="m-0 fs-6">{tempTodaysDate}</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-4 col-md-4">
                                    <div class="card border shadow-none">
                                        <div class="card-body p-0 pb-1 pt-1 d-flex justify-content-center">
                                            <div class="row ps-1">
                                                <div class="col-12 col-sm-12 col-md-12 col-lg-12 d-flex align-items-center justify-content-center">
                                                    <p class="card-text m-0 text-secondary fs-10">Shop Status</p>
                                                </div>
                                                <div class="col-12 col-sm-12 col-md-12 col-lg-12 text-center">
                                                    {tempShopStatus === 'Close' &&
                                                        <h3 class="m-0 fs-6 text-danger">CLOSED</h3>
                                                    }
                                                    {tempShopStatus === 'Open' &&
                                                        <h3 class="m-0 fs-6 text-success">OPENED</h3>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-4 col-md-4">
                                    <div class="card border shadow-none">
                                        <div class="card-body p-0 pb-1 pt-1 d-flex justify-content-center">
                                            <div class="row ps-1">
                                                <div class="col-12 col-sm-12 col-md-12 col-lg-12 d-flex align-items-center justify-content-center">
                                                    <p class="card-text m-0 text-secondary fs-10">Time</p>
                                                </div>
                                                <div class="col-12 col-sm-12 col-md-12 col-lg-12 text-center">
                                                    <h3 class="m-0 fs-6">{moment().tz(targetTimezone).format('HH:mm:ss')}</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <form>
                                <div class="row">
                                    <div class="col-6 col-md-6 col-lg-4">
                                        <div class="form-group boxed">
                                            <div class="input-wrapper">
                                                <label class="form-label" for="name">Name</label>
                                                {/* <input type="text" class="form-control pe-1" id="name" placeholder="Name" value={userName} autocomplete="off" required onChange={(e) => {handleName(e.target.value)}} />
                                                <i class="clear-input">
                                                    <ion-icon name="close-circle"></ion-icon>
                                                </i> */}
                                                

                                                <div class="form-group searchbox">
                                                    <input type="text" class="form-control pe-1 ps-4" id="name" placeholder="Name" value={userName} autocomplete="off" required onChange={(e) => {handleName(e.target.value)}} />
                                                    <i class="input-icon h-100">
                                                        <ion-icon name="person-outline" class="fs-6 text-dark"></ion-icon>
                                                    </i>
                                                    {nameAlert &&
                                                        <span class="text-danger fs-10">Enter Name</span>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-md-6 col-lg-4">
                                        <div class="form-group boxed">
                                            <div class="input-wrapper">
                                                {tempShopStatus === 'Close' &&
                                                    <label class="form-label" for="closingOpening">Opening Cash</label>
                                                }
                                                {tempShopStatus === 'Open' &&
                                                    <label class="form-label" for="closingOpening">Closing Cash</label>
                                                }
                                                {/* <input type="number" min="0" class="form-control pe-1" id="closingOpening" value={shopCash} placeholder={(tempShopStatus === 'Close') ? "Opening Cash" : "Closing Cash"} onChange={(e) => {handleCash(e.target.value)}} autocomplete="off" required />
                                                <i class="clear-input">
                                                    <ion-icon name="close-circle"></ion-icon>
                                                </i> */}
                                                
                                                <div class="form-group searchbox">
                                                    <input type="number" min="0" class="form-control pe-1 ps-4" id="closingOpening" value={shopCash} placeholder={(tempShopStatus === 'Close') ? "Opening Cash" : "Closing Cash"} onChange={(e) => {handleCash(e.target.value)}} autocomplete="off" required />
                                                    <i class="input-icon h-100">
                                                        <ion-icon name="cash-outline" class="fs-6 text-dark"></ion-icon>
                                                    </i>
                                                    {cashAlert &&
                                                        <span class="text-danger fs-10">Enter Cash</span>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-12 col-lg-4">
                                        <div class="form-group boxed pt-0">
                                            <div class="input-wrapper">
                                                <label class="form-label" for="remark5">Remark</label>
                                                <textarea class="form-control pe-1" id="remark5" placeholder="Remark" value={shopRemark} onChange={(e) => {handleRemark(e.target.value)}} autocomplete="off"></textarea>
                                                <i class="clear-input">
                                                    <ion-icon name="close-circle"></ion-icon>
                                                </i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="" style={{position: 'absolute', top: -41, right: 35}}>
                                    {tempShopStatus === 'Close' &&
                                        <button type="submit" class="btn btn-success btn-sm text-nowrap" onClick={(e) => {handleShopStatus(e, 't')}}><ion-icon name="log-in-outline"></ion-icon>&nbsp;SHOP OPEN</button>
                                    }
                                    {tempShopStatus === 'Open' &&
                                        <button type="submit" class="btn btn-danger btn-sm text-nowrap" onClick={(e) => {handleShopStatus(e, 'f')}}><ion-icon name="log-out-outline"></ion-icon>&nbsp;SHOP CLOSE</button>
                                    }
                                </div>
                            </form>
                            {/* <div class="row">
                                <div class="col-12 col-md-12">
                                    <ul class="nav nav-tabs capsuled" role="tablist">
                                        <li class="nav-item">
                                            <a class={(activeTab === 'summary') ? "nav-link active" : "nav-link"} data-bs-toggle="tab" href="javascript:void(0)" role="tab" onClick={() => {handleActiveTabValue('summary')}}>
                                                Summary
                                            </a>
                                        </li>
                                        <li class="nav-item">
                                            <a class={(activeTab === 'stock') ? "nav-link active" :"nav-link"} data-bs-toggle="tab" href="javascript:void(0)" role="tab" onClick={() => {handleActiveTabValue('stock')}}>
                                                Stock
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div> */}
                            {/* {activeTab === 'summary' &&
                                <div class="mt-3">
                                    <div class="section full mt-1">
                                        <div class="row">
                                            <div class="col-6 col-md-4 col-lg-4 mb-2">
                                                <div class="card">
                                                    <div class="card-body p-2 pb-1 pt-1">
                                                        <div class="row ps-1">
                                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 d-flex align-items-center">
                                                                <p class="card-text m-0 text-secondary fs-8">Total Sale</p>
                                                            </div>
                                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                                                <h3 class="m-0 text-success fs-3">₹{summaryData?.Orders?.TotalSale}</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-6 col-md-4 col-lg-4 mb-2">
                                                <div class="card">
                                                    <div class="card-body p-2 pb-1 pt-1">
                                                        <div class="row ps-1">
                                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 d-flex align-items-center">
                                                                <p class="card-text m-0 text-secondary fs-8">Total Expense</p>
                                                            </div>
                                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                                                <h3 class="m-0 text-danger fs-3">₹{summaryData?.Transactions?.TotalExpense}</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-6 col-md-4 col-lg-4 mb-2">
                                                <div class="card">
                                                    <div class="card-body p-2 pb-1 pt-1">
                                                        <div class="row ps-1">
                                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 d-flex align-items-center">
                                                                <p class="card-text m-0 text-secondary fs-8">Balance Amount</p>
                                                            </div>
                                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                                                <h3 class="m-0 text-primary fs-3">₹{summaryData?.Orders?.BalanceAmount}</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-6 col-md-4 col-lg-4 mb-2">
                                                <div class="card">
                                                    <div class="card-body p-2 pb-1 pt-1">
                                                        <div class="row ps-1">
                                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 d-flex align-items-center">
                                                                <p class="card-text m-0 text-secondary fs-8">Total Orders</p>
                                                            </div>
                                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                                                <h3 class="m-0 text-primary fs-3">{summaryData?.Orders?.TotalOrder}</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="listview-title mt-0 ps-0">Order Type</div>
                                        <div class="row">
                                            <div class="col-6 col-md-4 col-lg-4 mb-2">
                                                <div class="card">
                                                    <div class="card-body p-2 pb-1 pt-1">
                                                        <div class="row ps-1">
                                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 d-flex align-items-center">
                                                                <p class="card-text m-0 text-secondary fs-8">Counter Sale</p>
                                                            </div>
                                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                                                <h3 class="m-0 text-primary fs-3">₹{summaryData?.Orders?.OrderType?.CounterSale}</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-6 col-md-4 col-lg-4 mb-2">
                                                <div class="card">
                                                    <div class="card-body p-2 pb-1 pt-1">
                                                        <div class="row ps-1">
                                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 d-flex align-items-center">
                                                                <p class="card-text m-0 text-secondary fs-8">DineIn</p>
                                                            </div>
                                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                                                <h3 class="m-0 text-primary fs-3">₹{summaryData?.Orders?.OrderType?.DineIn}</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-6 col-md-4 col-lg-4 mb-2">
                                                <div class="card">
                                                    <div class="card-body p-2 pb-1 pt-1">
                                                        <div class="row ps-1">
                                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 d-flex align-items-center">
                                                                <p class="card-text m-0 text-secondary fs-8">Pick Up</p>
                                                            </div>
                                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                                                <h3 class="m-0 text-primary fs-3">₹{summaryData?.Orders?.OrderType?.Parcel}</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-6 col-md-4 col-lg-4 mb-2">
                                                <div class="card">
                                                    <div class="card-body p-2 pb-1 pt-1">
                                                        <div class="row ps-1">
                                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 d-flex align-items-center">
                                                                <p class="card-text m-0 text-secondary fs-8">Zomato / Swiggy</p>
                                                            </div>
                                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                                                <h3 class="m-0 text-primary fs-3">₹{summaryData?.Orders?.OrderType?.Online}</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="listview-title mt-0 ps-0">Payment Method</div>
                                        <div class="row">
                                            <div class="col-6 col-md-4 col-lg-4 mb-2">
                                                <div class="card">
                                                    <div class="card-body p-2 pb-1 pt-1">
                                                        <div class="row ps-1">
                                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 d-flex align-items-center">
                                                                <p class="card-text m-0 text-secondary fs-8">Cash</p>
                                                            </div>
                                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                                                <h3 class="m-0 text-primary fs-3">₹{summaryData?.Orders?.PaymentMethod?.Cash}</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-6 col-md-4 col-lg-4 mb-2">
                                                <div class="card">
                                                    <div class="card-body p-2 pb-1 pt-1">
                                                        <div class="row ps-1">
                                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 d-flex align-items-center">
                                                                <p class="card-text m-0 text-secondary fs-8">UPI</p>
                                                            </div>
                                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                                                <h3 class="m-0 text-primary fs-3">₹{summaryData?.Orders?.PaymentMethod?.UPI}</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-6 col-md-4 col-lg-4 mb-2">
                                                <div class="card">
                                                    <div class="card-body p-2 pb-1 pt-1">
                                                        <div class="row ps-1">
                                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 d-flex align-items-center">
                                                                <p class="card-text m-0 text-secondary fs-8">Card</p>
                                                            </div>
                                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                                                <h3 class="m-0 text-primary fs-3">₹{summaryData?.Orders?.PaymentMethod?.Card}</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-6 col-md-4 col-lg-4 mb-2">
                                                <div class="card">
                                                    <div class="card-body p-2 pb-1 pt-1">
                                                        <div class="row ps-1">
                                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 d-flex align-items-center">
                                                                <p class="card-text m-0 text-secondary fs-8">Cheque</p>
                                                            </div>
                                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                                                <h3 class="m-0 text-primary fs-3">₹{summaryData?.Orders?.PaymentMethod?.Cheque}</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-6 col-md-4 col-lg-4 mb-2">
                                                <div class="card">
                                                    <div class="card-body p-2 pb-1 pt-1">
                                                        <div class="row ps-1">
                                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 d-flex align-items-center">
                                                                <p class="card-text m-0 text-secondary fs-8">Payment Gateway</p>
                                                            </div>
                                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                                                <h3 class="m-0 text-primary fs-3">₹{summaryData?.Orders?.PaymentMethod?.Online}</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-6 col-md-4 col-lg-4 mb-2">
                                                <div class="card">
                                                    <div class="card-body p-2 pb-1 pt-1">
                                                        <div class="row ps-1">
                                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 d-flex align-items-center">
                                                                <p class="card-text m-0 text-secondary fs-8">Other</p>
                                                            </div>
                                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                                                <h3 class="m-0 text-primary fs-3">₹{summaryData?.Orders?.PaymentMethod?.Other}</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {activeTab === 'stock' &&
                                <div class="mt-3">
                                    <ItemsListView itemCardDetail={items} cardBg={""} pageName={'NewOrder'}></ItemsListView>
                                </div>
                            } */}
                        </div>
                    }
                    {alertMessage &&
                        <div class="section d-flex justify-content-center align-items-center flex-column">
                            <h2 class="mb-2 text-danger">{alertMessage}</h2>
                            <button class="btn btn-primary" onClick={handleContinue}>Continue</button>
                        </div>
                    }
                </Modal.Body>
            </Modal>
        </div>
    );
}

const mapStateToProps = ({ auth, data, shopStatus }) => {
    const {
        authToken
    } = auth
    const {
        items,
        businessData
    } = data
    const {
        shopStatusModalVisible,
        liveShopStatus
    } = shopStatus
    return {
        authToken,
        items,
        shopStatusModalVisible,
        liveShopStatus,
        businessData
    }
  }
  
  const mapDispatchToProps = {
    setBusinessData,
    hideShopStatusModal
  }

export default connect(mapStateToProps, mapDispatchToProps)(ShopStatusModal)