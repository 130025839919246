import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { connect } from "react-redux"
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition'
import CategoryFullModal from '../Sidebar/CategoryFullModal'
import { showCategoryModal } from '../../../features/Offcanvas/categoryFullModalSlice'
import QuickAddOffcanvas from '../Sidebar/QuickAddOffcanvas'
import { showQuickAddOffcanvas } from '../../../features/Offcanvas/quickAddOffcanvasSlice' 
import { itemSearch, emptyTranscript, removeItem } from '../../../features/Data/dataSlice'
import { showCheckoutOffcanvas } from '../../../features/Offcanvas/checkoutOffcanvasSlice'
import MainActionSheet from '../Sidebar/MainActionSheet'
import { showMainActionSheet } from '../../../features/Offcanvas/mainActionSheetSlice'
import MessageToast from '../../Elements/Notification/MessageToast'
import { showOngoingOffcanvas } from '../../../features/Offcanvas/ongoingOffcanvasSlice'
import { showMainMenuOffcanvas } from '../../../features/Offcanvas/mainMenuOffcanvasSlice'
import Veg from '../../images/icon/veg.png'
import NonVeg from '../../images/icon/non-veg.png'

const Footer = ({
    showCategoryModal,
    showQuickAddOffcanvas,
    itemSearch,
    showCheckoutOffcanvas,
    checkoutItemList,
    showMainActionSheet,
    emptyTranscript,
    invoiceCount,
    showOngoingOffcanvas,
    showMainMenuOffcanvas,
    removeItem,
    draftData,
    stockActionColor,
    stockItemList
}) => {
    const [mobileCheckoutFooterFlag, setMobileCheckoutFooterFlag] = useState(false)
    const [footerTabName, setFooterTabName] = useState('')
    const [totalQty, setTotalQty] = useState(0)
    const [iosDetected, setiosdetection] = useState(false)
    const [checkoutItemListChip, setCheckoutItemListChip] = useState(null)
    const [stockItemListChip, setStockItemListChip] = useState(null)
    const search = window.location.pathname.split('/')
    let navigate = useNavigate()
    useEffect(() => {
        setFooterTabName(search[1])
    }, [search])
    const url = window.location.href
    useEffect(() => {
        if (footerTabName === 'newOrder' || footerTabName === 'ordersDetail') {
            setMobileCheckoutFooterFlag(true)
        }
    }, [search])
    const handleCategoriesModal = () => {
        showCategoryModal({categoryModalVisible: true})
    }
    const handleQuickAdd =() => {
        showQuickAddOffcanvas({quickAddVisible: true})
    }
    let count = 0
    useEffect(() => {
        if (footerTabName !== 'manageStock') {
            if (checkoutItemList.length > 0) {
                checkoutItemList.map(value => {
                    count = count + value.quantity
                })
                setTotalQty(count)
            } else {
                setTotalQty(0)
            }
        } else if (footerTabName === 'manageStock') {
            if (stockItemList.length > 0) {
                stockItemList.map(value => {
                    count = count + value.quantity
                })
                setTotalQty(count)
            } else {
                setTotalQty(0)
            }
        }
    }, [checkoutItemList, stockItemList])
    useEffect(() => {
        if (checkoutItemList) {
            setCheckoutItemListChip(checkoutItemList)
        }
    }, [draftData, checkoutItemList])
    useEffect(() => {
        if (stockItemList) {
            setStockItemListChip(stockItemList)
        }
    }, [stockItemList])
    const {
        transcript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition
    } = useSpeechRecognition();

    function startSpeechRecognition() {
        emptyTranscript({transcriptEmpty: true})
        resetTranscript();
        SpeechRecognition.startListening({ continuous: true, language: 'en-IN' });
    }

    function stopSpeechRecognition() {
        SpeechRecognition.stopListening();
        itemSearch({itemName: transcript})
        setTimeout(() => {
            resetTranscript();
        }, 300);
    }
    const isSpeechRecognitionDoable = () => {
        if (!browserSupportsSpeechRecognition) {
            return (<span className='fs-16' title='Voice to Text is not supported on this browser!'>
                <ion-icon name="mic-off-outline"></ion-icon>
            </span>)
        } else {
            return (
                <>
                    { 
                        listening ?
                            <button type='button' className='speaker-on speaker-btn scale-3' style={{backgroundColor: 'transparent', height: '14px', width: '14px'}} title='Stop Listening' onClick={() => stopSpeechRecognition()}>
                                <div class="pulse-ring" />
                                <div className='listening-animation'>
                                    <div className='sides border-circle' />
                                    <div className='center border-circle' />
                                    <div className='sides border-circle' />
                                </div>
                                <ion-icon name="mic-off-outline" class="off-mic" style={{ cursor: 'pointer' }}></ion-icon>
                            </button>
                            :
                            <button type='button' className='speaker-off speaker-btn scale-3' style={{backgroundColor: 'transparent', height: '14px', width: '14px', borderColor: 'transparent', color: 'transparent'}} title='Type by Voice' onClick={() => startSpeechRecognition()}>
                                <ion-icon name="mic-outline" style={{ cursor: 'pointer' }}></ion-icon>
                            </button>

                    }
                </>
            )
        }
    }

    const handleCheckout = () => {
        showCheckoutOffcanvas({checkoutOffcanvasVisible: true})
    }

    const handleActionSheet = () => {
        showMainActionSheet({mainActionSheetVisible: true})
    }

    useEffect(() => {
        var osDetection = navigator.userAgent || navigator.vendor || window.opera
        var iosDetection = /iPad|iPhone|iPod/.test(osDetection) && !window.MSStream
        setiosdetection(iosDetection)
      }, [])

    const handleOrderSwitch = (ongoing) => {
        if (ongoing === null || ongoing === undefined || ongoing === 0) {
            navigate('/orders?tab=sales&category=all')
        }
    }
    const handleMainMenuOffcanvas = () => {
        showMainMenuOffcanvas({mainMenuOffcanvasVisible: true})
    }

    const handleRemoveItem = (key, value) => {
        removeItem({itemKey: key, itemValue: value })
    }
    return (
        <>
            <CategoryFullModal></CategoryFullModal>
            <QuickAddOffcanvas></QuickAddOffcanvas>
            <MainActionSheet></MainActionSheet>
            <MessageToast os={iosDetected}></MessageToast>
            {mobileCheckoutFooterFlag &&
                <div class="position-fixed" style={{left: 0, right: 0, bottom: '56px'}}>
                    {/* <MobileCheckoutFooter></MobileCheckoutFooter> */}
                </div>
            }
            {(footerTabName !== 'newOrder' && footerTabName !== 'manageStock') &&
                <div>
                    <div class="section full mt-2 d-block d-md-none">
                        <div class="appBottomMenu" style={{minHeight: '68px', alignItems: 'start'}}>
                            {/* <Link to="/summary" class="item">
                                <div class="col d-flex justify-content-center align-items-center flex-column">
                                    <ion-icon name="storefront-outline" class={(footerTabName === 'summary') ? "fs-4 text-warning" : "fs-4"}></ion-icon>
                                    <strong class={(footerTabName === 'summary') ? "text-warning" : ""}>Summary</strong>
                                </div>
                            </Link> */}
                            <a href="javascript:void(0)" onClick={handleMainMenuOffcanvas} class="item">
                                <div class="col d-flex justify-content-center align-items-center flex-column">
                                    <ion-icon name="menu-outline" class="fs-4"></ion-icon>
                                    <strong>Navigation</strong>
                                </div>
                            </a>
                            <Link to="/items" class="item text-secondary">
                                <div class="col">
                                    <ion-icon name="cube-outline" class={(footerTabName === 'items') ? "fs-4 text-warning" : "fs-4"}></ion-icon>
                                    <strong class={(footerTabName === 'items') ? "text-warning" : ""}>Stock</strong>
                                </div>
                            </Link>
                            <Link to="/newOrder" class="item">
                                <div class="col">
                                    <div class="action-button">
                                        <ion-icon name="add-outline"></ion-icon>
                                    </div>
                                </div>
                            </Link>
                            {/* <a href="javascript:void(0)" class="item" onClick={handleActionSheet}>
                                <div class="col">
                                    <div class="action-button">
                                        <ion-icon name="add-outline"></ion-icon>
                                    </div>
                                </div>
                            </a> */}
                            <a href="javascript:void(0)" onClick={() => {handleOrderSwitch(null)}} class="item">
                                <div class="col position-relative">
                                    <ion-icon name="cart-outline" class={(footerTabName === 'orders') ? "fs-4 text-warning" : "fs-4"}></ion-icon>
                                    <strong class={(footerTabName === 'orders') ? "text-warning" : ""}>Orders</strong>
                                    {(invoiceCount !== null && invoiceCount !== undefined && invoiceCount > 0) &&
                                        <span class="badge badge-danger blink-ongoing-invoice" style={{position: 'absolute', top: -6}}>{invoiceCount}</span>
                                    }
                                </div>
                            </a>
                            <Link to="/transactions?type=income&tcategory=all" class="item">
                                <div class="col">
                                    <ion-icon name="swap-vertical-outline" class={(footerTabName === 'transactions') ? "fs-4 text-warning" : "fs-4"}></ion-icon>
                                    <strong class={(footerTabName === 'transactions') ? "text-warning" : ""}>Finance</strong>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            }
            {(footerTabName === 'newOrder' || footerTabName === 'manageStock') &&
                <div>
                    {footerTabName === 'newOrder' &&
                        <>
                            {checkoutItemListChip &&
                                <>
                                    {checkoutItemListChip.length > 0 &&
                                        <div class="border-top d-block d-md-none" style={{position: 'fixed', minHeight: 'auto', alignItems: 'start', bottom: '54px', right: 0, left: 0, backgroundColor: '#ffffff99', maxHeight: '90px', overflow: 'scroll'}}>
                                            <div class="section full p-1">
                                                {checkoutItemListChip.map((value, key) => {
                                                    return <div class={(value.item.FoodType === 'non-veg') ? 'chip chip-media border-danger bg-white me-1 mb-1 ps-1' : 'chip chip-media border-success bg-white me-1 mb-1 ps-1'}>
                                                    <span class={(value.item.FoodType === 'non-veg') ? "text-danger" : "text-success"}>{value.quantity} x</span>
                                                    <span class={(value.item.FoodType === 'non-veg') ? "chip-label text-danger" : "chip-label text-success"}>{value.item.ItemTitle}</span>
                                                    <a href="javascript:void(0)" class="chip-delete" onClick={() => {handleRemoveItem(key, value)}}>
                                                        <ion-icon name="close-circle"></ion-icon>
                                                    </a>
                                                </div>
                                                })}
                                            </div>
                                        </div>
                                    }
                                </>
                            }
                        </>
                    }
                    {footerTabName === 'manageStock' &&
                        <>
                            {stockItemListChip &&
                                <>
                                    {stockItemListChip.length > 0 &&
                                        <div class="border-top d-block d-md-none" style={{position: 'fixed', minHeight: 'auto', alignItems: 'start', bottom: '54px', right: 0, left: 0, backgroundColor: '#ffffff99', maxHeight: '90px', overflow: 'scroll'}}>
                                            <div class="section full p-1">
                                                {stockItemListChip.map((value, key) => {
                                                    return <div class={(value.item.FoodType === 'non-veg') ? 'chip chip-media border-danger bg-white me-1 mb-1 ps-1' : 'chip chip-media border-success bg-white me-1 mb-1 ps-1'}>
                                                    <span class={(value.item.FoodType === 'non-veg') ? "text-danger" : "text-success"}>{value.quantity} x</span>
                                                    <span class={(value.item.FoodType === 'non-veg') ? "chip-label text-danger" : "chip-label text-success"}>{value.item.ItemTitle}</span>
                                                    <a href="javascript:void(0)" class="chip-delete" onClick={() => {handleRemoveItem(key, null)}}>
                                                        <ion-icon name="close-circle"></ion-icon>
                                                    </a>
                                                </div>
                                                })}
                                            </div>
                                        </div>
                                    }
                                </>
                            }
                        </>
                    }
                    <div class="section full mt-2 d-block d-md-none">
                        <div class="appBottomMenu" style={{minHeight: '68px', alignItems: 'start'}}>
                            {/* <Link to="/summary" class="item">
                                <div class="col d-flex justify-content-center align-items-center flex-column">
                                    <ion-icon name="arrow-back-outline" class="fs-4"></ion-icon>
                                    <strong>Back</strong>
                                </div>
                            </Link> */}
                            <a href="javascript:void(0)" onClick={handleMainMenuOffcanvas} class="item">
                                <div class="col d-flex justify-content-center align-items-center flex-column">
                                    <ion-icon name="menu-outline" class="fs-4"></ion-icon>
                                    <strong>Navigation</strong>
                                </div>
                            </a>
                            <a href="javascript:void(0)" class="item text-secondary" onClick={handleCategoriesModal}>
                                <div class="col">
                                    <ion-icon name="grid-outline" class="fs-4"></ion-icon>
                                    <strong>Categories</strong>
                                </div>
                            </a>
                            <Link to="#!" class="item">
                                <div class="col">
                                    <div class="action-button" style={{backgroundColor: (footerTabName === 'manageStock') ? stockActionColor : ''}}>
                                        {isSpeechRecognitionDoable()}
                                    </div>
                                </div>
                            </Link>
                            <a href="javascript:void(0)" class="item" onClick={handleQuickAdd}>
                                <div class="col">
                                    <ion-icon name="flash-outline" class="fs-4"></ion-icon>
                                    <strong>Quick Add</strong>
                                </div>
                            </a>
                            <a href="javascript:void(0)" class="item" onClick={handleCheckout}>
                                <div class="col">
                                    <ion-icon name="cart-outline" class="fs-4"></ion-icon>
                                    <span class="badge badge-danger" style={{top: '3px'}}>{totalQty}</span>
                                    <strong>View Cart</strong>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>  
            }
        </>
    );
}

const mapStateToProps = ({ checkoutItems, data, stock }) => {
    const {
        checkoutItemList
    } = checkoutItems
    const {
        invoiceCount,
        draftData
    } = data
    const {
        stockActionColor,
        stockItemList
    } = stock
    return {
        checkoutItemList,
        invoiceCount,
        draftData,
        stockActionColor,
        stockItemList
    }
  }
  
  const mapDispatchToProps = {
    showCategoryModal,
    showQuickAddOffcanvas,
    itemSearch,
    showCheckoutOffcanvas,
    showMainActionSheet,
    emptyTranscript,
    showOngoingOffcanvas,
    showMainMenuOffcanvas,
    removeItem
  }

export default connect(mapStateToProps, mapDispatchToProps)(Footer)