import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import { connect } from "react-redux"
import { hideDiscountModal } from '../../../features/Offcanvas/discountModalSlice'
import { setDiscountDetails, cartDraftData } from '../../../features/Data/dataSlice'

const DiscountModal = ({
    discountModalVisible,
    hideDiscountModal,
    price,
    setDiscountDetails,
    draftIndex,
    draftData,
    cartDraftData
}) => {
    const [discountAmount, setDiscountAmount] = useState(0)
    const [discountTypeString, setDiscountTypeString] = useState('rupee')
    const [discountedAmount, setDiscountedAmount] = useState(0)
    const [inputValue, setInputValue] = useState(0)
    let tempDraftData = JSON.parse(JSON.stringify(draftData))
    const handleDiscountType = (type) => {
        setDiscountTypeString(type)
    }
    const handleDiscountAmount = (amount) => {
        setInputValue(amount)
        setDiscountAmount(amount)
        if (discountTypeString === 'rupee') {
            setDiscountedAmount(price?.subtotal - amount)
        } else {
            const a = (amount / 100 ) * price?.subtotal
            setDiscountedAmount(price?.subtotal - a)
        }
    }
    const handleDiscountModalClose = () => {
        hideDiscountModal()
        setDiscountAmount(0)
        setDiscountTypeString('')
        setDiscountedAmount(0)
    }
    const handleAppliedDiscount = () => {
        tempDraftData[draftIndex].DiscountedAmount = discountedAmount
        tempDraftData[draftIndex].TotalDiscount = price?.subtotal - discountedAmount 
        tempDraftData[draftIndex].DiscountType = (discountTypeString === 'percentage') ? inputValue + '%' :  inputValue
        tempDraftData[draftIndex].DiscountTypeString = discountTypeString
        setDiscountDetails({discountType: (discountTypeString === 'percentage') ? inputValue + '%' :  inputValue, discountedAmount: discountedAmount, totalDiscount: tempDraftData[draftIndex].TotalDiscount, discountTypeString: discountTypeString})
        cartDraftData({draftData: tempDraftData})
    }

    useEffect(() => {
        if (discountModalVisible) {
            setDiscountedAmount(draftData[draftIndex].DiscountedAmount)
            setDiscountAmount((draftData[draftIndex].DiscountTypeString !== 'percentage') ? draftData[draftIndex].TotalDiscount : parseInt(draftData[draftIndex].DiscountType) )
            setDiscountTypeString(draftData[draftIndex].DiscountTypeString)
        }
    }, [discountModalVisible])
    return (
        <div>
            <Modal show={discountModalVisible} centered={true}>
                <Modal.Header closeButton style={{padding: '15px 10px 15px 10px'}} onClick={handleDiscountModalClose}>
                    <div class="row w-100 position-relative">
                        <div class="col-md-7 col-6 pe-3">
                            Applied Discount
                        </div>
                        <div class="col-md-5 col-6 d-flex align-items-center justify-content-end">
                            <button class="btn btn-sm btn-success" onClick={handleAppliedDiscount}><ion-icon name="checkmark-circle-outline"></ion-icon> Apply</button>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body class="pt-0 p-1 modal-body">
                    <div class="section full mt-1">
                        <div class="row">
                            <div class="col-12 col-md-12 d-flex align-items-center justify-content-center">
                                <div class="form-check mb-1 me-1">
                                    <input class="form-check-input" type="radio" value="percentage" name="flexRadioDefault" id="flexRadioDefault6" onChange={(e) => {handleDiscountType(e.target.value)}} checked={(discountTypeString === 'percentage') ? true : false} />
                                    <label class="form-check-label" for="flexRadioDefault6" Checked={true}>
                                        Percentage
                                    </label>
                                </div>
                                <div class="form-check mb-1">
                                    <input class="form-check-input" type="radio" value="rupee" name="flexRadioDefault" id="flexRadioDefault7" onChange={(e) => {handleDiscountType(e.target.value)}} checked={(discountTypeString === 'rupee') ? true : false} />
                                    <label class="form-check-label" for="flexRadioDefault7">
                                        Rupee
                                    </label>
                                </div>
                            </div>
                            <div class="col-12 col-md-12 d-flex justify-content-center">
                                <div class="d-flex justify-content-center w-100">
                                    <h5 class="mb-0 text-muted fs-8 text-nowrap w-25 pe-1 d-flex align-items-center justify-content-end">Total &nbsp;<span class="fw-bold">₹{price?.subtotal}</span></h5>
                                    <div class="form-group searchbox d-flex justify-content-center w-25">
                                        <input type="number" min="0" max={(discountTypeString === 'percentage') ? 100 : ''} class="form-control pe-4 w-100" value={discountAmount} placeholder="" onChange={(e) => {handleDiscountAmount(e.target.value)}} />
                                        {discountTypeString === 'rupee' &&
                                            <i class="input-icon fs-5">
                                                ₹
                                            </i>
                                        }
                                        {discountTypeString === 'percentage' &&
                                            <i class="input-icon fs-5" style={{right: 0, left: 'auto'}}>
                                                %
                                            </i>
                                        }
                                    </div>
                                    <h5 class="mb-0 text-muted fs-8 text-nowrap w-25 ps-1 d-flex align-items-center">Applied &nbsp;<span class="fw-bold">₹{(discountedAmount !== null && discountedAmount !== undefined && discountedAmount !== '') ? discountedAmount : 0}</span></h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}

const mapStateToProps = ({ discount, checkoutItems, data }) => {
    const {
        discountModalVisible
    } = discount
    const {
        price
    } = checkoutItems
    const {
        draftIndex,
        draftData
    } = data
    return {
        discountModalVisible,
        price,
        draftIndex,
        draftData
    }
  }
  
  const mapDispatchToProps = {
    hideDiscountModal,
    setDiscountDetails,
    cartDraftData
  }

export default connect(mapStateToProps, mapDispatchToProps)(DiscountModal)