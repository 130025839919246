import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    orderTypeModalVisible: false,
    cartDraftID: null,
    reference: ''
}

export const OrderTypeModalSlice = createSlice({
    name: 'orderType',
    initialState,
    reducers: {
      showOrderTypeModal: (state, action) => {
        const {
            orderTypeModalVisible,
            cartDraftID,
            reference
        } = action.payload
        state.orderTypeModalVisible = orderTypeModalVisible
        state.cartDraftID = cartDraftID
        state.reference = reference
      },
      hideOrderTypeModal: (state) => {
        state.orderTypeModalVisible = false
        state.cartDraftID = null
        state.reference = ''
      }
    }
})

export const { showOrderTypeModal, hideOrderTypeModal } = OrderTypeModalSlice.actions;


export default OrderTypeModalSlice.reducer