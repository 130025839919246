import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    mainActionSheetVisible: false
}

export const MainActionSheetSlice = createSlice({
    name: 'mainActionSheet',
    initialState,
    reducers: {
      showMainActionSheet: (state, action) => {
        const {
            mainActionSheetVisible
        } = action.payload
        state.mainActionSheetVisible = mainActionSheetVisible
      },
      hideMainActionSheet: (state) => {
        state.mainActionSheetVisible = false
      }
    }
})

export const { showMainActionSheet, hideMainActionSheet } = MainActionSheetSlice.actions;


export default MainActionSheetSlice.reducer