import React, { useState, useEffect } from 'react'
import OrderDetailModal from '../layout/Sidebar/OrderDetailModal'
import axios from '../../AxiosConfig'
import config from '../../config'
import { connect } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { showOrderDetailModal } from '../../features/Offcanvas/orderDetailModalSlice'
import SearchWithButtons from './Components/SearchWithButtons'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import '@splidejs/splide/dist/css/splide.min.css'
import OrderGridView from './BitComponents/OrderGridView'
import OrderListView from './BitComponents/OrderListView'
import { activeTabUpdatedName, localStorageFlag, handleRenderOrders } from '../../features/Data/dataSlice'
import { generalDataUpdate } from '../../features/utilities/generalSlice'
import OngoingOrders from './BitComponents/OngoingOrders'
import IncomingOrders from './BitComponents/IncomingOrders'

const Orders = ({
    view,
    localStorageUpdated,
    localStorageFlag,
    handleRenderOrders,
    renderOrders,
    dayOrderFilter,
    generalDataUpdate,
    renderOngoingOrder,
    activeTabUpdatedName
}) => {
    const [orderListCallback, setOrderListCallback] = useState(false)
    const today = new Date().toISOString().slice(0, 10)
    const [activeTab, setActiveTab] = useState('sales')
    const [defaultView, setDefaultView] = useState('list')
    const [orderObj, setOrderObj] = useState(null)
    const [buttonStyle, setButtonStyle] = useState(null)
    const [totalOrderAmount, setTotalOrderAmount] = useState(0)
    const [totalPurchaseAmount, setTotalPurchaseAmount] = useState(0)
    const [totalWastageAmount, setTotalWastageAmount] = useState(0)
    const [totalSalesQty, setTotalSalesQty] = useState(0)
    const [totalPurchaseQty, setTotalPurchaseQty] = useState(0)
    const [totalWastageQty, setTotalWastageQty] = useState(0)
    const [totalSalesItems, setTotalSalesItems] = useState(0)
    const [totalPurchaseItems, setTotalPurchaseItems] = useState(0)
    const [totalWastageItems, setTotalWastageItems] = useState(0)
    const [orderArr, setOrderArr] = useState(null)
    const [userValue, setUserValue] = useState(null)
    const [orderStats, setOrderStats] = useState(null)
    const search = useLocation().search
    const url = new URLSearchParams(search).get('tab')
    const fullUrl = useLocation().pathname
    const tempPageName = fullUrl.split('/')[1]

    useEffect(() => {
        if (url) {
            setActiveTab(url)
        } else {
            navigate('/load')
        }
    }, [url])

    useEffect(() => {
        if (tempPageName === 'orders') {
            generalDataUpdate({view: 'list'})
        }
    }, [])

    useEffect(() => {
        if (activeTab === 'sales') {
            setButtonStyle({'buttonName': 'New Order', 'buttonColor':'btn-success'})
        } else if (activeTab === 'purchase') {
            setButtonStyle({'buttonName': 'Purchase', 'buttonColor':'btn-warning'})
        } else if (activeTab === 'wastage') {
            setButtonStyle({'buttonName': 'Wastage', 'buttonColor':'btn-danger'})
        } else {
            setButtonStyle({'buttonName': 'New Order', 'buttonColor':'btn-success'})
        }
    }, [activeTab])

    useEffect(() => {
        setInterval(() => {
          let syncOrders = JSON.parse(localStorage.getItem('Orders'))
          let syncPurchase = JSON.parse(localStorage.getItem('Purchase'))
          let syncWastage = JSON.parse(localStorage.getItem('Wastage'))
          if (syncOrders.length === 0 || syncPurchase.length === 0 || syncWastage.length === 0) {
            setOrderListCallback(true)
          }
        }, 600000)
    }, [])
    
    let navigate = useNavigate()
    useEffect(() => {
        let tempOrderList
        if (localStorageUpdated || localStorageUpdated === undefined || localStorageUpdated === false) {
            tempOrderList = JSON.parse(localStorage.getItem('Orders'))
            // tempOrderList.map((v,k) => {
            //     tempOrderList[k].OrderType = v.Type
            //     tempOrderList[k].InvoiceNumber = v.OrderNumber
            //     tempOrderList[k].TotalPaidAmount = v.Total - v.BalanceAmount
            //     tempOrderList[k].TotalItems = v.TotalQty
            // }) 
            setOrderObj({
                "Orders": tempOrderList,
                "Purchase" : JSON.parse(localStorage.getItem('Purchase')),
                "Wastage": JSON.parse(localStorage.getItem('Wastage'))
            })
            localStorageFlag({localStorageUpdated: false})   
        }
        let dateFilterFrom = new Date().toISOString().slice(0, 10)
        let dateFilterTo = new Date().toISOString().slice(0, 10)
        if (dayOrderFilter === 'Today') {
            dateFilterFrom = new Date().toISOString().slice(0, 10)
            dateFilterTo = new Date().toISOString().slice(0, 10)
        } else if (dayOrderFilter === 'Yesterday') {
            dateFilterFrom = (new Date(new Date().setDate(new Date().getDate()-1)).toISOString().slice(0, 10))
            dateFilterTo = (new Date(new Date().setDate(new Date().getDate()-1)).toISOString().slice(0, 10))
        } else if (dayOrderFilter === 'Last7') {
            dateFilterFrom = (new Date(new Date().setDate(new Date().getDate()-7)).toISOString().slice(0, 10))
            dateFilterTo = new Date().toISOString().slice(0, 10)
        } else if (dayOrderFilter === 'Last30') {
            dateFilterFrom = (new Date(new Date().setDate(new Date().getDate()-30)).toISOString().slice(0, 10))
            dateFilterTo = new Date().toISOString().slice(0, 10)
        } else if (dayOrderFilter === 'All') {
            dateFilterFrom = 'All'
            dateFilterTo = 'All'
        }
        let tempOrderArr = []
        let tempPurchaseArr = []
        let tempWastageArr = []

        let sumTotalOrderAmountArr = []
        let sumTotalPurchaseAmountArr = []
        let sumTotalWastageAmountArr = []

        let totalNoSales = []
        let totalNoPurchase = []
        let totalNoWastage = []
        axios.get(config.apiUrl + '/Invoices/getCompletedInvoices?fdate=' + dateFilterFrom + '&tdate='+ dateFilterTo).then(res=>{
            if (res.data.message === 'Success') {
                setOrderStats(res.data.data.stats)
                setOrderObj({
                    "Orders": [...res.data.data.orders, ...tempOrderList],
                    "Purchase" : [],
                    "Wastage": []
                })
            }
        })
    }, [orderListCallback, renderOrders, dayOrderFilter, renderOngoingOrder])

    useEffect(() => {
        axios.get('/Customers').then(res => {
            if (res.data.message === 'Success') {
                setUserValue(res.data.data)
            }
        })
    }, [])

    const handleAddPurchase = () => {
        activeTabUpdatedName({activeTabName: 'purchase'})
        navigate('/ordersDetail')
    }
    
    const handleAddWastage = () => {
        activeTabUpdatedName({activeTabName: 'wastage'})
        navigate('/ordersDetail')
    }
    return (
        <>
            {/* <div class="extraHeader p-0" style={{height: '36px', zIndex: 10}}>
                <ul class="nav nav-tabs lined" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link" style={{height: '36px'}} data-bs-toggle="tab" href="#ongoing" role="tab">
                            Active Orders
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link active" style={{height: '36px'}} data-bs-toggle="tab" href="#completed" role="tab">
                            Delivered
                        </a>
                    </li>
                </ul>
            </div> */}
            <div id="appCapsule">
                <div class="tab-content mt-1">
                    {/* <div class="tab-pane fade" id="incoming" role="tabpanel">
                        <div class="section full mt-2" style={{marginLeft: '-8px', marginRight: '-8px'}}>
                            <IncomingOrders></IncomingOrders>
                        </div>
                    </div> */}
                    {/* <div class="tab-pane fade" id="ongoing" role="tabpanel" >
                        <div class="section full mt-2" style={{marginLeft: '-8px', marginRight: '-8px'}}>
                            <OngoingOrders></OngoingOrders>
                        </div>
                    </div> */}
                    <div class="tab-pane fade show active" id="completed" role="tabpanel">
                        <div class="section full mb-1">
                            <div class="pt-1">
                                <SearchWithButtons pageName={'Orders'}></SearchWithButtons>
                            </div>
                        </div>
                        <div class="section full mt-1 mb-4">
                            <Splide
                                options={{
                                    type: "slide",
                                    gap: 10,
                                    arrows: false,
                                    trimSpace: false,
                                    autoWidth: true,
                                    pagination: false,
                                    breakpoints: {
                                        1400: {
                                            perPage: 4,
                                        },
                                        640: {
                                            perPage: 2,
                                        },
                                    }
                                }}
                                class="custom-padding"
                            >
                                <SplideSlide>
                                    <div class="card">
                                        <div class="card-body p-2 pb-1 pt-1">
                                            <div class="row ps-1">
                                                <div class="col-12 col-sm-12 col-md-12 col-lg-12 d-flex align-items-center">
                                                {url === 'sales' &&
                                                    <p class="card-text m-0 text-secondary fs-8">Total Sale</p>
                                                }
                                                {url === 'purchase' &&
                                                    <p class="card-text m-0 text-secondary fs-8">Total Purchase</p>
                                                }
                                                {url === 'wastage' &&
                                                    <p class="card-text m-0 text-secondary fs-8">Total Wastage</p>
                                                }
                                                </div>
                                                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                                    {url === 'sales' &&
                                                        <h3 class="m-0 text-primary fs-3">₹{(orderStats && orderStats.totalSale) ? orderStats.totalSale : 0}</h3>
                                                    }
                                                    {url === 'purchase' &&
                                                        <h3 class="m-0 text-primary fs-3">₹{totalPurchaseAmount}</h3>
                                                    }
                                                    {url === 'wastage' &&
                                                        <h3 class="m-0 text-primary fs-3">₹{totalWastageAmount}</h3>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </SplideSlide>
                                <SplideSlide>
                                    <div class="card">
                                        <div class="card-body p-2 pb-1 pt-1">
                                            <div class="row ps-1">
                                                <div class="col-12 col-sm-12 col-md-12 col-lg-12 d-flex align-items-center">
                                                {url === 'sales' &&
                                                    <p class="card-text m-0 text-secondary fs-8">No. Of Orders</p>
                                                }
                                                {url === 'purchase' &&
                                                    <p class="card-text m-0 text-secondary fs-8">No. Of Purchase</p>
                                                }
                                                {url === 'wastage' &&
                                                    <p class="card-text m-0 text-secondary fs-8">No. Of Wastage</p>
                                                }
                                                </div>
                                                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                                    {url === 'sales' &&
                                                        <h3 class="m-0 text-primary fs-3">{(orderStats && orderStats.noofsale) ? orderStats.noofsale : 0}</h3>
                                                    }
                                                    {url === 'purchase' &&
                                                        <h3 class="m-0 text-primary fs-3">{totalPurchaseItems}</h3>
                                                    }
                                                    {url === 'wastage' &&
                                                        <h3 class="m-0 text-primary fs-3">{totalWastageItems}</h3>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </SplideSlide>
                                <SplideSlide>
                                    <div class="card">
                                        <div class="card-body p-2 pb-1 pt-1">
                                            <div class="row ps-1">
                                                <div class="col-12 col-sm-12 col-md-12 col-lg-12 d-flex align-items-center">
                                                {url === 'sales' &&
                                                    <p class="card-text m-0 text-secondary fs-8">Balance Amount</p>
                                                }
                                                {url === 'purchase' &&
                                                    <p class="card-text m-0 text-secondary fs-8">Balance Amount</p>
                                                }
                                                {url === 'wastage' &&
                                                    <p class="card-text m-0 text-secondary fs-8">Balance Amount</p>
                                                }
                                                </div>
                                                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                                    {url === 'sales' &&
                                                        <h3 class="m-0 text-primary fs-3">₹{(orderStats && orderStats.balanceamount) ? orderStats.balanceamount : 0}</h3>
                                                    }
                                                    {url === 'purchase' &&
                                                        <h3 class="m-0 text-primary fs-3">{totalPurchaseQty}</h3>
                                                    }
                                                    {url === 'wastage' &&
                                                        <h3 class="m-0 text-primary fs-3">{totalWastageQty}</h3>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </SplideSlide>
                                <SplideSlide>
                                    <div class="card">
                                        <div class="card-body p-2 pb-1 pt-1">
                                            <div class="row ps-1">
                                                <div class="col-12 col-sm-12 col-md-12 col-lg-12 d-flex align-items-center">
                                                    <p class="card-text m-0 text-secondary fs-8">Cancelled Order</p>
                                                </div>
                                                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                                    <h3 class="m-0 text-primary fs-3">₹{(orderStats && orderStats.cancelSale) ? orderStats.cancelSale : 0}</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </SplideSlide>
                                <SplideSlide>
                                    <div class="card">
                                        <div class="card-body p-2 pb-1 pt-1">
                                            <div class="row ps-1">
                                                <div class="col-12 col-sm-12 col-md-12 col-lg-12 d-flex align-items-center">
                                                    <p class="card-text m-0 text-secondary fs-8">Cancelled Amount</p>
                                                </div>
                                                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                                    <h3 class="m-0 text-primary fs-3">₹{(orderStats && orderStats.cancelNoOfSale) ? orderStats.cancelNoOfSale : 0}</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </SplideSlide>
                            </Splide>
                        </div>
                        {(view === 'grid') &&
                            <div class="section full">
                                <OrderGridView allOrder={orderObj} userValue={userValue}></OrderGridView>
                            </div>
                        }
                        {(view === 'list') &&
                            <div class="section full">
                                <OrderListView allOrder={orderObj} userValue={userValue}></OrderListView>
                            </div>
                        }
                        {orderObj && orderObj['Orders'].length === 0 &&
                            <h2 class="text-danger d-flex align-items-center justify-content-center" style={{height: '250px'}}>No Orders Found</h2>
                        }
                    </div>
                </div>
                <OrderDetailModal></OrderDetailModal>
            </div>
        </>
    )
}

const mapStateToProps = ({ general, data }) => {
    const { 
        view
    } = general
    const {
        localStorageUpdated,
        renderOrders,
        dayOrderFilter,
        renderOngoingOrder
    } = data
    return {
        view,
        localStorageUpdated,
        renderOrders,
        dayOrderFilter,
        renderOngoingOrder
    }
  }
  
  const mapDispatchToProps = {
    showOrderDetailModal,
    activeTabUpdatedName,
    localStorageFlag,
    handleRenderOrders,
    generalDataUpdate
  }

export default connect(mapStateToProps, mapDispatchToProps)(Orders)
