import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { connect } from "react-redux"
import { hideInwardOutwardModal } from '../../../features/Offcanvas/inwardOutwardModalSlice'
import Logo from '../../images/logo.svg'
import jwt_decode from 'jwt-decode'
import moment from 'moment-timezone'

const InwardOutwardDetailModal = ({
    inwardOutwardModalVisible,
    hideInwardOutwardModal,
    inwardOutwardDetailData
}) => {
    const [totalStock, setTotalStock] = useState(0)
    const targetTimezone = 'Asia/Kolkata'
    useEffect(() => {
        if (inwardOutwardDetailData) {
            let totalStockSum = 0
            inwardOutwardDetailData?.Items?.map((v,k) => {
                totalStockSum = totalStockSum  + parseInt(v.quantity) 
            })
            setTotalStock(totalStockSum)
        }
    }, [inwardOutwardDetailData])
    return (
        <div>
            <Modal show={inwardOutwardModalVisible} centered={true}>
                <div>
                    <div class="rounded-circle d-flex align-items-center justify-content-center text-light" style={{height: '25px', width: '25px', background: 'rgba(0,0,0,0.3)', position: 'absolute', top: '-13px', right: '-13px', cursor: 'pointer'}} onClick={() => {hideInwardOutwardModal()}}>
                        <ion-icon name="close-outline"></ion-icon>
                    </div>
                </div>
                <Modal.Body class="modal-body mt-0 pt-0">
                    <div class="section full mt-0">
                        <div class="invoice m-0 p-1 shadow-none">
                            {/* <div class="invoice-page-header align-items-center">
                                <div class="invoice-logo">
                                    <img src={Logo} width="70px" alt="logo" class="h-auto" />
                                </div>
                                <div>Bill No: 10</div>
                            </div> */}
                            <div class="invoice-person mt-2">
                                <div class="invoice-from p-0">
                                    <h4 class="fw-bolder">
                                        <h4 class="fw-bolder">
                                            {inwardOutwardDetailData?.StockType === 'addStock' &&
                                                <>
                                                    Added
                                                </>
                                            }
                                            {inwardOutwardDetailData?.StockType === 'removeStock' &&
                                                <>
                                                    Removed
                                                </>
                                            }
                                            {inwardOutwardDetailData?.StockType === 'transferOut' &&
                                                <>
                                                    Transfered
                                                </>
                                            }
                                            {inwardOutwardDetailData?.StockType === 'request' &&
                                                <>
                                                    Request
                                                </>
                                            }
                                        </h4>
                                    </h4>
                                </div>
                                <div class="invoice-to p-0">
                                    {moment(inwardOutwardDetailData?.DateTime).tz(targetTimezone).format('DD-MM-YYYY, HH:mm:ss')}
                                </div>
                            </div>
                            <div class="invoice-detail mt-1">
                                <div class="table-responsive">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <td class="p-1 ps-0">ITEM</td>
                                                <td class="p-1 pe-0">STOCK</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {inwardOutwardDetailData?.Items.map((v,k) => {
                                                return <tr>
                                                    <td class="p-1 ps-0">{v.ItemTitle}</td>
                                                    <td class="p-1 pe-0">{v.quantity}</td>
                                                </tr>
                                            })}
                                            <tr style={{borderColor: 'transparent'}}>
                                                <td class="fs-6">Total Stock</td>
                                                <td class="fs-6">{totalStock}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}

const mapStateToProps = ({ inwardOutward }) => {
    const {
        inwardOutwardModalVisible,
        inwardOutwardDetailData
    } = inwardOutward
    return {
        inwardOutwardModalVisible,
        inwardOutwardDetailData
    }
  }
  
  const mapDispatchToProps = {
    hideInwardOutwardModal
  }

export default connect(mapStateToProps, mapDispatchToProps)(InwardOutwardDetailModal)