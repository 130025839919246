import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    categories: null,
    items: null,
    categoryID: null,
    itemName: '',
    type: '',
    localStorageUpdated: false,
    triggerCheckoutFunction: false,
    totalQty: 0,
    method: '',
    balanceAmount: 0,
    phone: null,
    todaysSale: null,
    dayOrderFilter: 'Today',
    dayTransactionFilter: 'Today',
    transcriptEmpty: false,
    categoryName: '',
    added: false,
    invoiceID: null,
    invoiceNumber:null,
    orderIDs: [],
    topScrollValue: [{
      "icon": "storefront-outline",
      "name":"Counter Sale"
  },{
      "icon": "restaurant-outline",
      "name":"Dine In"
  },{
      "icon": "bag-handle-outline",
      "name":"Pick Up"
  },{
      "icon": "phone-portrait-outline",
      "name":"Zomato / Swiggy"
  }],
  businessData: null,
  invoiceCount: 0,
  printInvoice: false,
  invoiceWithOrders: null,
  renderOngoingOrder: '',
  topProductList: null,
  kotData: null,
  offlineKotData: null,
  printFrom: '',
  paymentStatus: '',
  userData: null,
  fullInvoice: null,
  orderIndex: 0,
  draftData: null,
  cartDraftID: null,
  checkoutFlagDraft: false,
  renderHeaderDraft: false,
  draftIndex: null,
  odIndex: null,
  activeDraftID: null,
  updateCartFlag: '',
  updateCartKey: null,
  tempKeyCode: null,
  configObj: null,
  discountType: null,
  discountedAmount: 0,
  totalDiscount: 0,
  discountTypeString: '',
  itemKey: -1,
  itemValue: null,
  tempCategoryId: null
}

export const DataSlice = createSlice({
    name: 'data',
    initialState,
    reducers: {
      categoriesDataUpdate: (state, action) => {
        const {
            categories,
            categoryID
        } = action.payload
        state.categories = categories
        state.categoryID = categoryID
      },
      categoryIdUpdate: (state, action) => {
        const {
            categoryID
        } = action.payload
        state.categoryID = categoryID
      },
      itemSearch: (state, action) => {
        const {
            itemName
        } = action.payload
        state.itemName = itemName
      },
      itemsDataUpdate: (state, action) => {
        const {
            items
        } = action.payload
          state.items = items
      },
      activeTabUpdatedName: (state, action) => {
        const {
            activeTabName
        } = action.payload
          state.activeTabName = activeTabName
      },
      checkoutItemType: (state, action) => {
        const {
            type
        } = action.payload
          state.type = type
      },
      setPhoneNumber: (state, action) => {
        const {
            phone
        } = action.payload
          state.phone = phone
      },
      localStorageFlag: (state, action) => {
        const {
            localStorageUpdated
        } = action.payload
          state.localStorageUpdated = localStorageUpdated
      },
      mobileCheckoutButtonData: (state, action) => {
        const {
            totalQty,
            triggerCheckoutFunction
        } = action.payload
          state.totalQty = totalQty
          state.triggerCheckoutFunction = triggerCheckoutFunction
      },
      setPaymentType: (state, action) => {
        const {
            method,
            balanceAmount
        } = action.payload
          state.method = method
          state.balanceAmount = balanceAmount
      },
      handleRenderOrders: (state, action) => {
        const {
            renderOrders
        } = action.payload
        state.renderOrders = renderOrders
      },
      handleRenderTransactions: (state, action) => {
        const {
            renderTransactions
        } = action.payload
        state.renderTransactions = renderTransactions
      },
      setTodaysSale: (state, action) => {
        const {
            todaysSale
        } = action.payload
        state.todaysSale = todaysSale
      },
      setOrderDayFilter: (state, action) => {
        const {
            dayOrderFilter
        } = action.payload
        state.dayOrderFilter = dayOrderFilter
      },
      emptyTranscript: (state, action) => {
        const {
            transcriptEmpty
        } = action.payload
        state.transcriptEmpty = transcriptEmpty
      },
      setCategoryName: (state, action) => {
        const {
            categoryName
        } = action.payload
        state.categoryName = categoryName
      },
      setNewOrderTopScroll: (state, action) => {
        state.topScrollValue = [{
            "icon": "storefront-outline",
            "name":"Counter Sale"
        },{
            "icon": "restaurant-outline",
            "name":"Dine In"
        },{
            "icon": "bag-handle-outline",
            "name":"Pick Up"
        },{
            "icon": "phone-portrait-outline",
            "name":"Zomato / Swiggy"
        }]
      },
      setTransactionDayFilter: (state, action) => {
        const {
            dayTransactionFilter
        } = action.payload
        state.dayTransactionFilter = dayTransactionFilter
      },
      setBusinessData: (state, action) => {
        const {
            businessData
        } = action.payload
        state.businessData = businessData
      },
      addToCart: (state, action) => {
        const {
            added
        } = action.payload
        state.added = added
      },
      setInvoiceID: (state, action) => {
        const {
            invoiceID
        } = action.payload
        state.invoiceID = invoiceID
      },
      setInvoiceNumber: (state, action) => {
        const {
            invoiceNumber
        } = action.payload
        state.invoiceNumber = invoiceNumber
      },
      setOrderIDs: (state, action) => {
        const {
          orderIDs
        } = action.payload
        state.orderIDs = orderIDs
      },
      setInvoiceCount: (state, action) => {
        const {
          invoiceCount
        } = action.payload
        state.invoiceCount = invoiceCount
      },
      setPrintInvoice: (state, action) => {
        const {
          printInvoice
        } = action.payload
        state.printInvoice = printInvoice
      },
      setInvoiceOrderList: (state, action) => {
        const {
          invoiceWithOrders,
          paymentStatus
        } = action.payload
        state.invoiceWithOrders = invoiceWithOrders
        state.paymentStatus = paymentStatus
      },
      renderOngoingOrderList: (state, action) => {
        const {
          renderOngoingOrder
        } = action.payload
        state.renderOngoingOrder = renderOngoingOrder
      },
      setTopProductList: (state, action) => {
        const {
          topProductList
        } = action.payload
        state.topProductList = topProductList
      },
      setKotData: (state, action) => {
        const {
          kotData,
          printFrom
        } = action.payload
        state.kotData = kotData
        state.printFrom = printFrom
      },
      setOfflineKotData: (state, action) => {
        const {
          offlineKotData,
          orderIndex
        } = action.payload
        state.offlineKotData = offlineKotData
        state.orderIndex = orderIndex
      },
      setUserData: (state, action) => {
        const {
          userData
        } = action.payload
        state.userData = userData
      },
      setFullInvoice: (state, action) => {
        const {
          fullInvoice
        } = action.payload
        state.fullInvoice = fullInvoice
      },
      setCartDraftID: (state, action) => {
        const {
          cartDraftID
        } = action.payload
        state.cartDraftID = cartDraftID
      },
      setCheckoutFlagDraft: (state, action) => {
        const {
          checkoutFlagDraft
        } = action.payload
        state.checkoutFlagDraft = checkoutFlagDraft
      },
      setRenderHeaderDraft: (state, action) => {
        const {
          renderHeaderDraft
        } = action.payload
        state.renderHeaderDraft = renderHeaderDraft
      },
      cartDraftData: (state, action) => {
        const {
          draftData
        } = action.payload
        state.draftData = draftData
      },
      cartDraftIndex: (state, action) => {
        const {
          draftIndex
        } = action.payload
        state.draftIndex = draftIndex
      },
      cartOrderIndex: (state, action) => {
        const {
          odIndex
        } = action.payload
        state.odIndex = odIndex
      },
      setActiveDraftCart: (state, action) => {
        const {
          activeDraftID
        } = action.payload
        state.activeDraftID = activeDraftID
      },
      runUpdatedCartFunction: (state, action) => {
        const {
          updateCartFlag,
          updateCartKey
        } = action.payload
        state.updateCartFlag = updateCartFlag
        state.updateCartKey = updateCartKey
      },
      setKeyCode: (state, action) => {
        const {
          tempKeyCode
        } = action.payload
        state.tempKeyCode = tempKeyCode
      },
      setConfigObj: (state, action) => {
        const {
          configObj
        } = action.payload
        state.configObj = configObj
      },
      setDiscountDetails: (state, action) => {
        const {
          discountType,
          discountedAmount,
          totalDiscount,
          discountTypeString
        } = action.payload
        state.discountType = discountType
        state.discountedAmount = discountedAmount
        state.totalDiscount = totalDiscount
        state.discountTypeString = discountTypeString
      },
      removeItem: (state, action) => {
        const {
          itemKey,
          itemValue
        } = action.payload
        state.itemKey = itemKey
        state.itemValue = itemValue
      },
      setTempCategoryId: (state, action) => {
        const {
            tempCategoryId
        } = action.payload
        state.tempCategoryId = tempCategoryId
      },
    }
})

export const { categoriesDataUpdate, itemsDataUpdate, activeTabUpdatedName, categoryIdUpdate, itemSearch, checkoutItemType, setPhoneNumber, localStorageFlag, mobileCheckoutButtonData, setPaymentType, handleRenderOrders, handleRenderTransactions, setTodaysSale, setOrderDayFilter, emptyTranscript, setCategoryName, setNewOrderTopScroll, setTransactionDayFilter, setBusinessData, addToCart, setInvoiceID, setInvoiceNumber, setOrderIDs, setInvoiceCount, setPrintInvoice, setInvoiceOrderList, renderOngoingOrderList, setTopProductList, setKotData, setOfflineKotData, setUserData, setFullInvoice, cartDraftData, setCartDraftID, setCheckoutFlagDraft, setRenderHeaderDraft, cartDraftIndex, cartOrderIndex, setActiveDraftCart, runUpdatedCartFunction, setKeyCode, setConfigObj, setDiscountDetails, removeItem, setTempCategoryId} = DataSlice.actions;


export default DataSlice.reducer