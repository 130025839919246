import React, { useState, useEffect } from 'react'
import { connect } from "react-redux"
import { useNavigate } from 'react-router-dom'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import '@splidejs/splide/dist/css/splide.min.css'
import { checkoutItemType, cartDraftIndex, cartDraftData, cartOrderIndex } from '../../../features/Data/dataSlice'
import { hideMainActionSheet } from '../../../features/Offcanvas/mainActionSheetSlice'
import { showDineInOffcanvas } from '../../../features/Offcanvas/dineInTableOffcanvasSlice'

const CheckoutTopScroll = ({
    topScrollValue,
    checkoutItemType,
    pageName,
    type,
    shortCut,
    hideMainActionSheet,
    showDineInOffcanvas,
    draftData,
    draftIndex,
    cartDraftData,
    cartDraftIndex,
    cartOrderIndex
}) => {
    let navigate = useNavigate()
    const [activeTab, setActiveTab] = useState(0)
    const handleActiveTab = (ID, type) => {
        // DO NOT ADD BELOW CODE IN BUILD
        // if (type === 'Dine In') {
        //     showDineInOffcanvas({dineInTableOffcanvasVisible: true})
        // }
        // DO NOT ADD ABOVE CODE IN BUILD
        setActiveTab(ID)
        if (type === null || type === undefined || type === '') {
            checkoutItemType({type: topScrollValue[0].name})
        } else {
            checkoutItemType({type: type})
        }

        if (shortCut === true) {
            hideMainActionSheet()
            navigate('/newOrder')
        }

        let tempObj = JSON.parse(JSON.stringify(draftData))
        let addNewItem = false
        if (draftIndex !== null && draftIndex !== undefined && draftIndex !== '') {
            tempObj[draftIndex].OrderType = type 
            addNewItem = false
        } else {
            for (let i = 0; i < draftData.length; i++) {
                if (draftData[i].Status === -2) {
                    tempObj[i].Status = -1
                    tempObj[i].OrderType = type
                    tempObj[i].TotalQuantity = 0
                    cartDraftIndex({draftIndex: i})
                    cartOrderIndex({odIndex: 0})
                    break
                }
            }
        }
        cartDraftData({draftData: tempObj})
    }
    useEffect(() => {
        if (type !== '') {
            checkoutItemType({type: type})
        } else {
            checkoutItemType({type: topScrollValue[0].name})
        }
    }, [pageName, type])

    // useEffect(() => {
        
    // }, [draftData])
    
    return (
        <Splide
            options={{
                type: "slide",
                perPage: 4,
                gap: 10,
                pagination: false,
                arrows: false
            }}
        >
            {topScrollValue.map((value, index) => {
                return  <SplideSlide>
                <a href="#!" class={(shortCut === false) ? `item p-1 ${(type === value.name) ? 'border-primary' : ''} rounded` : 'item p-1 border-primary rounded'} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}} onClick={() => {handleActiveTab(index, value.name)}}>
                    <div class="col d-flex align-items-center flex-column">
                        <ion-icon name={value.icon && value.icon}></ion-icon>
                        <strong class="fs-10 text-nowrap lh-1">{value.name && value.name}</strong>
                    </div>
                </a>
            </SplideSlide>
            })}

        </Splide>
    )
}

const mapStateToProps = ({ data }) => {
    const {
        type,
        draftData,
        draftIndex
    } = data
    return {
        type,
        draftData,
        draftIndex
    }
  }
  
const mapDispatchToProps = {
    checkoutItemType,
    hideMainActionSheet,
    showDineInOffcanvas,
    cartDraftData,
    cartDraftIndex,
    cartOrderIndex
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutTopScroll)
