import React, { useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import { connect } from "react-redux"
import { hideMessageModal } from '../../../features/Offcanvas/messageModalSlice'

const MessageModal = ({
    messageModalVisible,
    message,
    hideMessageModal
}) => {
    useEffect(() => {
        if (messageModalVisible) {
            setTimeout(() => {
                hideMessageModal()
            }, 2000);
        }
    }, [messageModalVisible])
    return (
        <div>
            <Modal show={messageModalVisible} centered>
                <Modal.Body>
                    <div class="row">
                        <div classs="col-12 col-md-12 mb-2" style={{display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '48px'}}>
                            <ion-icon name="happy-outline" class="text-primary"></ion-icon>
                        </div>
                        <div classs="col-12 col-md-12">
                            <h3 class="mb-0 d-flex justify-content-center align-items-center">{message}</h3>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

const mapStateToProps = ({ messageModal }) => {
    const {
        messageModalVisible,
        message
    } = messageModal
    return {
        messageModalVisible,
        message
    }
}
  
const mapDispatchToProps = {
    hideMessageModal
}

export default connect(mapStateToProps, mapDispatchToProps)(MessageModal)