import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    gridView: true,
    buttonStyle: null,
    view: 'list',
    triggerFunction: false,
    shortcodeVisible: false,
    alternateTextVisible: 'englishText',
    colorVisible: false
}

export const GeneralSlice = createSlice({
    name: 'general',
    initialState,
    reducers: {
      generalDataUpdate: (state, action) => {
        const {
            gridView,
            buttonStyle,
            view
        } = action.payload
        state.view = view
        if (gridView !== undefined) {
          state.gridView = gridView
        }
        if (buttonStyle !== undefined) {
          state.buttonStyle = buttonStyle
        }
      },
      triggerBusinessdDataCall: (state, action) => {
        const {
            triggerFunction
        } = action.payload
        state.triggerFunction = triggerFunction
      },
      setShortcodeVisible: (state, action) => {
        state.shortcodeVisible = !state.shortcodeVisible
      },
      setAlternateTextVisible: (state, action) => {
        const {
          alternateTextVisible
        } = action.payload
        state.alternateTextVisible = alternateTextVisible
      },
      setColorVisible: (state, action) => {
        state.colorVisible = !state.colorVisible
      },
    }
})

export const { generalDataUpdate, triggerBusinessdDataCall, setShortcodeVisible, setAlternateTextVisible, setColorVisible } = GeneralSlice.actions;


export default GeneralSlice.reducer