import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    checkoutOffcanvasVisible: false
}

export const CheckoutOffcanvasSlice = createSlice({
    name: 'checkout',
    initialState,
    reducers: {
      showCheckoutOffcanvas: (state, action) => {
        const {
            checkoutOffcanvasVisible
        } = action.payload
        state.checkoutOffcanvasVisible = checkoutOffcanvasVisible
      },
      hideCheckoutOffcanvas: (state) => {
        state.checkoutOffcanvasVisible = false
      }
    }
})

export const { showCheckoutOffcanvas, hideCheckoutOffcanvas } = CheckoutOffcanvasSlice.actions;


export default CheckoutOffcanvasSlice.reducer