import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import Moment from 'react-moment'
import { showOrderDetailModal } from '../../../features/Offcanvas/orderDetailModalSlice'
import { showAddTransactionModal } from '../../../features/Offcanvas/addTransactionModalSlice'

const OrderListView = ({
    showOrderDetailModal,
    allOrder,
    userValue,
    showAddTransactionModal
}) => {
    const [allOrderList, setAllOrderList] = useState(null)
    const [orderTypeFilterName, setOrderTypeFilterName] = useState('Orders')
    const search = useLocation().search
    const orderTypeFilter = new URLSearchParams(search).get('tab')
    useEffect(() => {
        if (allOrder !== undefined && allOrder !== null) {
            setAllOrderList(allOrder)
        }
    }, [allOrder])
    const handleOrderDetailView = (orderDetail) => {
        showOrderDetailModal({orderDetailModalVisible: true, orderDetaildata: orderDetail})
    }
    useEffect(() => {
        if (allOrderList !== null && allOrderList !== undefined) {
            if (orderTypeFilter === 'sales') {
                setOrderTypeFilterName('Orders')
            } else if (orderTypeFilter === 'purchase') {
                setOrderTypeFilterName('Purchase')
            } else if (orderTypeFilter === 'wastage') {
                setOrderTypeFilterName('Wastage')
            }
        }
    }, [orderTypeFilter, orderTypeFilterName])

    const handleBalanceAmount = (e, value) => {
        e.stopPropagation()
        showAddTransactionModal({addTransactionModalVisible: true, transactionData: value})
    }
    return (
        <ul class="listview image-listview flush bg-transparent">
            {(allOrderList !== null && allOrderList !== undefined && orderTypeFilterName !== null && orderTypeFilterName !== undefined && orderTypeFilterName !== '') ? allOrderList[orderTypeFilterName].slice(0).reverse().map((k,v) => {

                let OrderType = orderTypeFilterName
                let BorderColor = ''
                if (OrderType === 'Orders') {
                    BorderColor = 'border-primary'
                } else if (OrderType === 'Purchase') {
                    BorderColor = 'border-warning'
                } else if (OrderType === 'Wastage') {
                    BorderColor = 'border-danger'
                }
                const tempK = JSON.parse(JSON.stringify(k))
                tempK.quantityCount = 0
                tempK.itemArr = []
                tempK.Subtotal = 0
                tempK.GrandTotal = 0
                return <>
                {
                        <li>
                            <a href="javascript:void(0)" class="item ps-1" onClick={() => {handleOrderDetailView(tempK)}}>
                                {(tempK.OrderType === '' || tempK.OrderType === null || tempK.OrderType === undefined) && 
                                    <div class="icon-box bg-primary">
                                        <ion-icon name="receipt-outline"></ion-icon>
                                    </div>
                                }
                                {tempK.OrderType === 'Counter Sale' && 
                                    <div class="icon-box bg-secondary">
                                        <ion-icon name="storefront-outline"></ion-icon>
                                    </div>
                                }
                                {tempK.OrderType === 'Dine In' && 
                                    <div class="icon-box bg-warning">
                                        <ion-icon name="restaurant-outline"></ion-icon>
                                    </div>
                                }
                                {tempK.OrderType === 'Pick Up' && 
                                    <div class="icon-box bg-info">
                                        <ion-icon name="bag-handle-outline"></ion-icon>
                                    </div>
                                }
                                {tempK.OrderType === 'Zomato / Swiggy' && 
                                    <div class="icon-box bg-dark">
                                        <ion-icon name="phone-portrait-outline"></ion-icon>
                                    </div>
                                }
                                <div class="in">
                                    <div>
                                        <header>{(tempK.OrderType !== null && tempK.OrderType !== undefined) ? tempK.OrderType : 'Delivered'} . <Moment fromNow>{tempK.DateTime}</Moment></header>
                                        Bill No: {tempK.billNo} <small class="text-muted">₹{tempK.PaidAmount}</small>
                                        <footer class="mt-0">
                                            <div class="text">
                                                {tempK.Orders.map((v1,k1) => {
                                                    tempK.quantityCount += v1.TotalQty
                                                    tempK.Subtotal += v1.Subtotal
                                                    tempK.GrandTotal += v1.Total
                                                    {v1.Items.map((value,key) => {
                                                        if (!tempK?.itemArr?.hasOwnProperty(value.Item_ID)) {
                                                            // tempK.itemArr.push(value.Image)
                                                            tempK.itemArr[value.Item_ID] = {
                                                                "Image":value.Image,
                                                                "ItemTitle":value.ItemTitle,
                                                                "MRP":value.MRP,
                                                                "quantity": value.quantity
                                                            }
                                                        } else {
                                                            tempK.itemArr[value.Item_ID]['quantity'] = tempK.itemArr[value.Item_ID]['quantity'] + value.quantity 
                                                        }
                                                    })}
                                                })}
                                                {tempK.itemArr.map((v2,k2) => {
                                                    return <>
                                                        {(v2.Image !== '') &&
                                                            <img src={v2.Image} alt="avatar" class="imaged w24 rounded" style={{height: '24px', marginRight: '2px', objectFit: 'cover'}} />
                                                        }
                                                        {(v2.Image === '') &&
                                                            <img src="https://us.123rf.com/450wm/viktorijareut/viktorijareut1503/viktorijareut150300119/37649514-grey-tray-kitchenware-trey-isolated-tray-vector-closed-tray.jpg?ver=6" alt="avatar" class="imaged w24 rounded" style={{height: '24px', marginRight: '2px', objectFit: 'cover'}} />
                                                        }
                                                        </>
                                                })}
                                                <span class="ms-1 badge bg-secondary" style={{height: '18px'}}>{tempK.quantityCount} Items</span>
                                            </div>
                                            
                                        </footer>
                                    </div>
                                    <span class="text-muted">
                                        <div class="text-end">
                                            <div class="fw-bolder text-dark d-flex">
                                                {tempK.BalanceAmount > 0 &&
                                                    <div class="align-items-center" onClick={(e) => {handleBalanceAmount(e, k)}}><ion-icon name="cash-outline" class="text-danger me-1 fs-3"></ion-icon></div>
                                                }
                                            </div>
                                            {/* <div>₹{tempK.BalanceAmount}</div> */}
                                        </div>
                                    </span>
                                </div>
                                {
                                    tempK.Status === -1 &&
                                    <div className='badge bg-danger'>Cancelled</div>
                                }
                            </a>
                        </li>
                    
                }
                </>
            })
            :
            null}
        </ul>
    )
}

const mapStateToProps = () => {
    return {
    }
}
  
const mapDispatchToProps = {
    showOrderDetailModal,
    showAddTransactionModal
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderListView)
