import React, { useEffect, useState, useRef } from 'react'
import Modal from 'react-bootstrap/Modal'
import { connect } from "react-redux"
import { hideShortcutSearchModal } from '../../../features/Offcanvas/shortcutSearchModalSlice'

const ShortcutSearchModal = ({
    shortcutSearchModalVisible,
    hideShortcutSearchModal,
    draftData,
    items,
    tempKeyCode
}) => {
    const [showOrders, setShowOrders] = useState(true)
    const [showItems, setShowItems] = useState(true)
    const [searchText, setSearchText] = useState('')
    const [currentIndex, setCurrentIndex] = useState(-1)
    const [currentActiveChip, setCurrentactiveChip] = useState('')
    useEffect(() => {
        if (shortcutSearchModalVisible) {
            document.getElementById("shortcutSearch").focus()
        }
    }, [shortcutSearchModalVisible])
    const ref = useRef()
    const refToSearch = useRef()
    const handleSearch = (text) => {
        setSearchText(text)
    }

    const moveUp = () => {
        setCurrentIndex(prevState => (prevState > 0) ? (prevState - 1) : prevState)
    }

    const moveDown = (nodes) => {
        if (currentIndex < nodes.length) {
            setCurrentIndex(prevState => (prevState + 1))
        }
    }

    useEffect(() => {
        if (shortcutSearchModalVisible) {
            const tagToFocus = ref.current;
            const nodes = tagToFocus.getElementsByTagName("a");
            if (nodes && currentIndex < nodes.length && currentIndex >= 0) {
                nodes[currentIndex].focus()
            }
        }
    }, [currentIndex])

    const collectInput = () => {
        const tagToFocus = ref.current;
        const nodes = tagToFocus.getElementsByTagName("a");
        tagToFocus.addEventListener('keydown', function(event) {
            if (event.keyCode === 40) {
                moveDown(nodes)
            } else if (event.keyCode === 38) {
                moveUp()
            }
        });
    }

    useEffect(() => {
        if (shortcutSearchModalVisible) {
            setTimeout(() => {
                setSearchText('')
            }, 100);
            collectInput()
        }
    }, [shortcutSearchModalVisible])

    useEffect(() => {
        if ((tempKeyCode === 40 || tempKeyCode === 13) && shortcutSearchModalVisible) {
            const input = document.getElementById("shortcutSearch")
            document.getElementById("shortcutSearch").blur()
            if (input === document.activeElement) {
                setCurrentIndex(-1)
            } else {
                if (!document.activeElement.getAttribute('data-focused')) {
                    setCurrentIndex(0)
                } else {
                    setCurrentIndex(-1)
                }
            }
        } else if (tempKeyCode === 111 || tempKeyCode === 191) {
            setCurrentIndex(-1)
            document.getElementById("shortcutSearch").focus()
            setTimeout(() => {
                setSearchText('')
            }, 100);
        }
    }, [tempKeyCode])

    useEffect(() => {
        if (refToSearch.current) {
            const searchInput = refToSearch.current
            searchInput.addEventListener('focus', function(event) {
                setCurrentIndex(-1)
            });
        }
    }, [refToSearch])

    const handleSearchSubmit = (e) => {
        e.preventDefault();
        if (searchText === '0') {
            setSearchText('')
            hideShortcutSearchModal()
        } else if (searchText === '*') {
            setShowOrders(false)
            setShowItems(true)
            setCurrentactiveChip('cart')
            setTimeout(() => {
                setCurrentIndex(-1)
            }, 100);
            setTimeout(() => {
                setCurrentIndex(draftData.filter(item => item.Status === 0).length)
            }, 200);
        } else if (searchText === '+') {
            setShowOrders(true)
            setShowItems(false)
            setCurrentactiveChip('liveOrders')
            setTimeout(() => {
                setCurrentIndex(-1)
            }, 100);
            setTimeout(() => {
                setCurrentIndex(0)
            }, 200);
        } else if (searchText === '' || searchText === null || searchText === undefined) {
            setShowOrders(true)
            setShowItems(true)
            setCurrentactiveChip('items')
            setTimeout(() => {
                setCurrentIndex(-1)
            }, 100);
            setTimeout(() => {
                setCurrentIndex(0)
            }, 200);
        }
    }
    return (
        <div>
            <Modal show={shortcutSearchModalVisible} centered={true} backdrop="static" onHide={() => {hideShortcutSearchModal()}} autoFocus={true}>
                <Modal.Header style={{border: 'none', display: 'flex', justifyContent: 'center', maxWidth: '800px', paddingBottom: '0px', backgroundColor: '#f9f9f9'}}>
                    <div class="row w-100 position-relative">
                        <div class="col-md-12 mb-1 p-0">
                            {/* <div class={(currentActiveChip === 'liveOrders' || currentActiveChip === '') ? "chip chip-media w-100 me-1 bg-transparent" : "chip chip-media w-100 me-1 bg-transparent border-primary"} style={{border: (currentActiveChip === 'liveOrders' || currentActiveChip === '') ? '2px solid #e8c71a' : ''}}>
                                <i class={(currentActiveChip === 'liveOrders' || currentActiveChip === '') ? "chip-icon text-white" : "chip-icon bg-primary"} style={{top: -2, left: -2, border: (currentActiveChip === 'liveOrders' || currentActiveChip === '') ? '2px solid #e8c71a' : '', backgroundColor: (currentActiveChip === 'liveOrders' || currentActiveChip === '') ? '#e8c71a' : ''}}>
                                    <ion-icon name="receipt"></ion-icon>
                                </i>
                                <span class="chip-label">Orders</span>
                            </div>

                            <div class={(currentActiveChip === 'items') ? "chip chip-media w-100 me-1 bg-transparent" : "chip chip-media w-100 me-1 bg-transparent border-primary"} style={{border: (currentActiveChip === 'items') ? '2px solid #e8c71a' : ''}}>
                                <i class={(currentActiveChip === 'items') ? "chip-icon text-white" : "chip-icon bg-primary"} style={{top: -2, left: -2, border: (currentActiveChip === 'items') ? '2px solid #e8c71a' : '', backgroundColor: (currentActiveChip === 'items') ? '#e8c71a' : ''}}>
                                    <ion-icon name="cube"></ion-icon>
                                </i>
                                <span class="chip-label">Items</span>
                            </div>

                            <div class={(currentActiveChip === 'cart') ? "chip chip-media w-100 me-0 bg-transparent" : "chip chip-media w-100 me-0 bg-transparent border-primary"} style={{border: (currentActiveChip === 'cart') ? '2px solid #e8c71a' : ''}}>
                                <i class={(currentActiveChip === 'cart') ? "chip-icon text-white" : "chip-icon bg-primary"} style={{top: -2, left: -2, border: (currentActiveChip === 'cart') ? '2px solid #e8c71a' : '', backgroundColor: (currentActiveChip === 'cart') ? '#e8c71a' : ''}}>
                                    <ion-icon name="cart"></ion-icon>
                                </i>
                                <span class="chip-label">Cart</span>
                            </div> */}

                            <ul class="nav nav-tabs capsuled mb-1" role="tablist">
                                <li class="nav-item">
                                    <a class={(currentActiveChip === 'liveOrders') ? "nav-link active" : "nav-link"} data-bs-toggle="tab" href="#home" role="tab">
                                        <div class="border webweb-bg-secondary d-flex align-items-center justify-content-center" style={{minWidth: '18px', maxHeight: '18px'}}>
                                            /
                                        </div>
                                        &nbsp;
                                        <div class="border webweb-bg-secondary d-flex align-items-center justify-content-center" style={{minWidth: '18px', maxHeight: '18px'}}>
                                            +
                                        </div>
                                        &nbsp;
                                        Orders
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a class={(currentActiveChip === 'items') ? "nav-link active" : "nav-link"} data-bs-toggle="tab" href="#profile" role="tab">
                                        <div class="border webweb-bg-secondary d-flex align-items-center justify-content-center" style={{minWidth: '18px', maxHeight: '18px'}}>
                                            /
                                        </div>
                                        &nbsp;
                                        Items
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a class={(currentActiveChip === 'cart') ? "nav-link active" : "nav-link"} data-bs-toggle="tab" href="#contact" role="tab">
                                        <div class="border webweb-bg-secondary d-flex align-items-center justify-content-center" style={{minWidth: '18px', maxHeight: '18px'}}>
                                            /
                                        </div>
                                        &nbsp;
                                        <div class="border webweb-bg-secondary d-flex align-items-center justify-content-center" style={{minWidth: '18px', maxHeight: '18px'}}>
                                            *
                                        </div>
                                        &nbsp;
                                        Cart
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="col-md-12 col-12 p-0">
                            <form class="search-form" onSubmit={(e) => {handleSearchSubmit(e)}}>
                                <div class="form-group searchbox">
                                    <input type="text" class="form-control" value={searchText} onChange={(e) => {handleSearch(e.target.value)}} ref={refToSearch} placeholder="Search" id="shortcutSearch" autoFocus="true" autocomplete="off" style={{fontSize: '22px', height: '50px'}} />
                                    <i class="input-icon" style={{height: '50px'}}>
                                        <ion-icon name="search-outline"></ion-icon>
                                    </i>
                                </div>
                            </form>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body class="modal-body" ref={ref} id="customShortKey" style={{backgroundColor: '#f9f9f9', height: '68vh', overflowY: 'auto'}}>
                    <div class="section mt-0 p-0" style={{display: (showOrders) ? 'block' : 'none'}}>
                        <div class="listview-title p-0 text-primary" style={{paddingBottom: '4px'}}>Live Orders</div>
                        <ul class="listview image-listview flush border"style={{borderRadius: '6px'}}>
                            {draftData?.map((v,k) => {
                                return <>
                                    {/* {v.Status === 0 && */}
                                        <li class="custom-line" key={k}>
                                            <a href="javascript:void(0)" data-focused="true" class={`item ps-1 pt-1 pb-1 ${k} shortCutFocus`}>
                                                {/* <ion-icon name="receipt-outline" class="fs-3 me-1" style={{width: '40px'}}></ion-icon> */}
                                                <div class="bg-primary d-flex justify-content-center align-items-center fs-8" style={{borderRadius: '100%', height: '25px', width: '28px', marginRight: '5px'}}>
                                                    {/* {k + 1} */}
                                                    {String.fromCharCode(64 + (k + 1))}
                                                </div>
                                                {/* {k + 1} */}
                                                <div class="in">
                                                    <div class="fw-bold">
                                                        {/* <header>{v.OrderType}</header> */}
                                                        {/* Bill No: {v.billNo} */}
                                                        {v.OrderType}
                                                        {/* <footer>{v.Orders[0].DateTime}</footer> */}
                                                    </div>
                                                    <span class="text-dark fw-bold">₹{v.Amount}</span>
                                                </div>
                                            </a>
                                        </li>
                                    {/* } */}
                                </>
                            })}                            
                        </ul>
                    </div>
                    <div class="section mt-0 p-0" style={{display: (showItems) ? 'block' : 'none'}}>
                        <div class="listview-title p-0 text-primary mt-2" style={{paddingBottom: '4px'}}>Items</div>
                        <ul class="listview image-listview flush border"style={{borderRadius: '6px'}}>
                            {items?.map((v,k) => {
                                return <li class="custom-line">
                                    <a href="javascript:void(0)" class="item ps-0 pt-1 pb-1 text-white shortCutFocus" data-focused="true">
                                        <img src={v.ImageURL} alt="image" class="image rounded ms-1 me-1" style={{objectFit:'cover',maxWidth: '50px', width: '50px', height: '50px', minWidth: 'auto'}} />
                                        <div class="in">
                                            <div>
                                                <header>{v.GroupItemID}</header>
                                                <span>{v.ItemTitle}</span> &nbsp;
                                                {(v.AlternateText !== '' && v.AlternateText !== null && v.AlternateText !== undefined) &&
                                                    <small>( {v.AlternateText} )</small>
                                                }
                                                <footer>{v.Shortcode} | ₹{v.MRP}</footer>
                                            </div>
                                            <span class="text-dark fw-bold" style={{position: 'relative'}}>
                                                <div class="stepper stepper-sm">
                                                    <button class="stepper-button stepper-down">-</button>
                                                    <input type="text" class="form-control" value="1" disabled />
                                                    <button class="stepper-button stepper-up">+</button>
                                                </div>
                                            </span>
                                        </div>
                                    </a>
                                </li>
                            })}
                            
                        </ul>
                    </div>
                </Modal.Body>
                <Modal.Footer style={{padding: '0px', boxShadow: '0px 5px 10px 0px', justifyContent: 'start', flexFlow: 'row'}}>
                    <div class="section">
                        <ul class="d-flex p-0 m-0" style={{listStyle: 'none'}}>
                            <li class="me-1 d-flex fs-10">
                                <div class="border webweb-bg-secondary d-flex align-items-center justify-content-center" style={{minWidth: '18px', maxHeight: '18px', marginTop: '3px'}}>
                                    <ion-icon name="return-down-back-outline" class="m-0 p-0"></ion-icon>
                                </div>
                                &nbsp;to select
                            </li>
                            <li class="me-1 d-flex fs-10">
                                <div class="border webweb-bg-secondary d-flex align-items-center justify-content-center" style={{minWidth: '18px', maxHeight: '18px', marginTop: '3px'}}>
                                    <ion-icon name="arrow-up-outline" class="m-0 p-0"></ion-icon>
                                </div>&nbsp;
                                <div class="border webweb-bg-secondary d-flex align-items-center justify-content-center" style={{minWidth: '18px', maxHeight: '18px', marginTop: '3px'}}>
                                    <ion-icon name="arrow-down-outline" class="m-0 p-0"></ion-icon>
                                </div>
                                &nbsp;to navigate
                            </li>
                            <li class="me-1 d-flex fs-10">
                                <div class="border webweb-bg-secondary d-flex align-items-center justify-content-center" style={{minWidth: '18px', maxHeight: '18px', marginTop: '3px'}}>
                                    0
                                </div>
                                &nbsp;
                                +
                                &nbsp;
                                <div class="border webweb-bg-secondary d-flex align-items-center justify-content-center" style={{minWidth: '18px', maxHeight: '18px', marginTop: '3px'}}>
                                    <ion-icon name="return-down-back-outline" class="m-0 p-0"></ion-icon>
                                </div>
                                &nbsp;to close
                            </li>
                        </ul>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

const mapStateToProps = ({ shortcut, data }) => {
    const {
        shortcutSearchModalVisible
    } = shortcut
    const {
        draftData,
        items,
        tempKeyCode
    } = data
    return {
        shortcutSearchModalVisible,
        draftData,
        items,
        tempKeyCode
    }
  }
  
  const mapDispatchToProps = {
    hideShortcutSearchModal
  }

export default connect(mapStateToProps, mapDispatchToProps)(ShortcutSearchModal)