import React, { useState, useEffect } from 'react'
import { connect } from "react-redux"
import axios from '../../../AxiosConfig'
import ItemList from '../BitComponents/ItemList'
import { itemsDataUpdate } from '../../../features/Data/dataSlice'

const ItemsListView = ({
    itemCardDetail,
    cardBg,
    categoryID,
    itemName,
    pageName,
    alternateTextVisible,
    colorVisible,
    categoryName,
    itemsDataUpdate,
    categories
}) => {
    const [filteredCategory, setFilteredCategory] = useState('')
    const [allCategoryFlag, setAllCategoryFlag] = useState(true)
    const [itemSearch, setItemSearch] = useState('')
    const [categoryList, setCategoryList] = useState(null)
    const [stateName, setStateName] = useState('')
    const page = window.location.pathname.split('/')
    useEffect(() => {
        setStateName(page[1])
    }, [page])

    // useEffect(() => {
    //     if (itemCardDetail) {
    //         if (categoryID !== 'all') {
    //             setAllCategoryFlag(false)
    //             setFilteredCategory(itemCardDetail.filter((item) => categoryID === item.Item_CategoryID))
    //             setItemSearch(itemCardDetail.filter((item) => itemName.includes(item.Title)))
    //         } else {
    //             setAllCategoryFlag(true)
    //             setFilteredCategory(itemCardDetail)
    //             setItemSearch(itemCardDetail.filter((item) => itemName.includes(item.Title)))
    //         }
    //     }
    // }, [categoryID])
    let tempCategoryID = []
    useEffect(() => {
        if (itemCardDetail) {
            let filtered;
            if (categoryID !== 'all') {
                setAllCategoryFlag(false)
                filtered = itemCardDetail.filter((item) => categoryID === item.Item_CategoryID);
            } else {
                setAllCategoryFlag(true)
                filtered = itemCardDetail
            }
            if(itemName?.trim().length > 0) filtered = filtered.filter((item) => item.ItemTitle.toLowerCase().includes(itemName.toLowerCase().trim()))
            setFilteredCategory(filtered)
        }
    }, [categoryID, itemName, categoryList, itemCardDetail])

    // useEffect(() => {
    //     axios.get('/ItemCategories/business').then(res => {
    //         if (res.data.message === 'Success') {
    //             setCategoryList(res.data.data)
    //         } else {
    //         //   showAppToast({toastMessage: 'Something went wrong',background: 'danger',icon: 'close',position: 'center',timeout: 1000})
    //         }
    //     })
    // }, [])
    return (
        <>
        {categories !==null &&
            <>
                {categories.map((v,k) => {
                    return <>
                        <div>
                            {((itemName === '' || itemName === null || itemName === undefined) && (categoryName === '' || categoryName === null || categoryName === undefined)) &&
                                <div class="listview-title sticky-title px-2 d-flex justify-content-start" style={{marginLeft: '-8px', marginRight: '-8px', top: '-1px', zIndex: 8}}>
                                    {colorVisible &&
                                        <div class="me-1" style={{height: '35px', width: '5px',color: 'transparent', background: v.Color}}>
                                            .
                                        </div>
                                    }
                                    <strong>
                                        {(alternateTextVisible === 'shortcode' || alternateTextVisible === 'englishText') &&
                                            <>
                                                {v.Name}
                                            </> 
                                        }
                                        {(alternateTextVisible === 'alternateText') &&
                                            <>
                                                {v.AlternateText}
                                            </>
                                        }
                                    </strong>
                                </div>
                            }
                            <ul class="listview image-listview media flush bg-transparent">
                                {(filteredCategory.length > 0) &&
                                    <>
                                        {(filteredCategory !== null) &&
                                            filteredCategory.map((value, key) => {
                                            return (
                                                <>
                                                    {stateName === 'newOrder' &&
                                                        <>
                                                            {value.Item_CategoryID === v.ID  &&
                                                                <>
                                                                    
                                                                    {(allCategoryFlag === false) &&
                                                                        <ItemList itemCardDetail={value} cardBg={cardBg} pageName={pageName}></ItemList>
                                                                    }
                                                                    {(allCategoryFlag === true) &&
                                                                        <ItemList itemCardDetail={value} cardBg={cardBg} pageName={pageName}></ItemList>
                                                                    }
                                                                        
                                                                </>
                                                            }
                                                        </>
                                                    }
                                                    {stateName === 'manageStock' &&
                                                        <>
                                                            {value.Item_CategoryID === v.ID  &&
                                                                <>
                                                                    {value.AllowNegativeStock === 't' &&
                                                                        <ItemList itemCardDetail={value} cardBg={cardBg} pageName={pageName}></ItemList>
                                                                           
                                                                    }
                                                                    {value.AllowNegativeStock === 'f' &&
                                                                        <>
                                                                            {value.Stockable === 't' &&
                                                                                <ItemList itemCardDetail={value} cardBg={cardBg} pageName={pageName}></ItemList>
                                                                            }
                                                                        </>
                                                                    }
                                                                        
                                                                </>
                                                            }
                                                        </>
                                                    }
                                                </>
                                            )})
                                        }
                                    </>
                                }
                            </ul>
                        </div>
                    </>
                })}
            </>
        }
            
            {(filteredCategory.length === 0) &&
                <>
                    {allCategoryFlag === false &&
                        <div class={`col-12 col-md-12 mb-1 pt-4 mt-5 d-flex align-items-center justify-content-center flex-column`}>
                            <h2 class="text-danger">No Items Found</h2>
                            <button class="btn btn-outline-primary">+ Add Item</button> 
                        </div>
                    }
                </>
            }
        </>
        
    )
}

const mapStateToProps = ({ data, general }) => {
    const {
        categoryID,
        itemName,
        categoryName,
        categories
    } = data
    const {
        alternateTextVisible,
        colorVisible
    } = general
    return {
        categoryID,
        itemName,
        alternateTextVisible,
        colorVisible,
        categoryName,
        categories
    }
  }
  
const mapDispatchToProps = {
    itemsDataUpdate
}


export default connect(mapStateToProps, mapDispatchToProps)(ItemsListView)
