import React, { useEffect, useState } from 'react'
import { connect } from "react-redux"
import { useNavigate, useLocation } from 'react-router-dom'
import CheckoutComponent from './Components/CheckoutComponent'
import NewOrderItem from './Components/NewOrderItem'
import { generalDataUpdate } from '../../features/utilities/generalSlice'
import CheckoutOffcanvas from '../layout/Sidebar/CheckoutOffcanvas'
import ItemSizeOffcanvas from '../layout/Sidebar/ItemSizeOffcanvas'

const ManageStock = ({
    items,
    categories,
    topScrollValue,
    generalDataUpdate,
    checkoutItemList
}) => {
    const [actionType, setActionType] = useState('')
    const itemList = []
    const stateName = window.location.pathname.split('/')
    const search = useLocation().search
    const action = new URLSearchParams(search).get('action')
    useEffect(() => {
        setActionType(action)
    }, [search, stateName])
    let navigate = useNavigate()
    const buttonStyle = {
        "buttonText": "Place Order",
        "buttonColor":"success"
    }
    useEffect(() => {
        generalDataUpdate({buttonStyle: buttonStyle})
    }, [])
    useEffect(() => {
        if (categories === null || categories === undefined || categories === '') {
            navigate('/load')
        }
    }, [categories])
    return (
        <div id="appCapsule" class="pb-0">
            <div class="row">
                <div class="col-12 col-md-8 col-lg-9">
                    <NewOrderItem itemCardDetail={items} searchWithButton itemCategories itemView pageName={'ManageStock'} cardBg={''} categories={categories} countInRow={3}></NewOrderItem>
                </div>
                <div className="col-md-4 col-lg-3 d-none d-md-block border shadowed" style={{position: 'fixed', top: '56px', right: 0, bottom: 0}}>
                    <CheckoutComponent topScroll={topScrollValue && topScrollValue} mobileNo itemList={itemList} checkoutButton={buttonStyle} pageName={'ManageStock'}></CheckoutComponent>
                    <CheckoutOffcanvas topScroll={topScrollValue && topScrollValue} mobileNo itemList={checkoutItemList} checkoutButton={buttonStyle} pageName={'ManageStock'}></CheckoutOffcanvas>
                    <ItemSizeOffcanvas></ItemSizeOffcanvas>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({ data, checkoutItems }) => {
    const {
        items,
        categories,
        topScrollValue
    } = data
    const {
        checkoutItemList
    } = checkoutItems
    return {
        items,
        categories,
        topScrollValue,
        checkoutItemList
    }
}
  
const mapDispatchToProps = {
    generalDataUpdate
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageStock)
