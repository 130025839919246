import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    quantityOffcanvasVisible: false,
    checkoutItemIndex: -1
}

export const QuantityOffcanvasSlice = createSlice({
    name: 'quantity',
    initialState,
    reducers: {
      showQuantityOffcanvas: (state, action) => {
        const {
            quantityOffcanvasVisible,
            checkoutItemIndex
        } = action.payload
        state.quantityOffcanvasVisible = quantityOffcanvasVisible
        state.checkoutItemIndex = checkoutItemIndex
      },
      hideQuantityOffcanvas: (state) => {
        state.quantityOffcanvasVisible = false
      }
    }
})

export const { showQuantityOffcanvas, hideQuantityOffcanvas } = QuantityOffcanvasSlice.actions;


export default QuantityOffcanvasSlice.reducer