import React from 'react'
import Offcanvas from 'react-bootstrap/Offcanvas'
import { connect } from "react-redux"
import { useNavigate } from 'react-router-dom'
import { hideMainActionSheet } from '../../../features/Offcanvas/mainActionSheetSlice'
import { showAddCategoryOffcanvas } from '../../../features/Offcanvas/addCategoryOffcanvasSlice'
import { activeTabUpdatedName, setInvoiceID, setPaymentType } from '../../../features/Data/dataSlice'
import { showAddTransactionModal } from '../../../features/Offcanvas/addTransactionModalSlice'
import CheckoutTopScroll from '../../Pages/BitComponents/CheckoutTopScroll'

const MainActionSheet = ({
    hideMainActionSheet,
    mainActionSheetVisible,
    showAddCategoryOffcanvas,
    activeTabUpdatedName,
    showAddTransactionModal,
    topScrollValue,
    setPaymentType,
    setInvoiceID
}) => {
    let navigate = useNavigate()
    const handleNewOrderSwitch = () => {
        setInvoiceID({invoiceID: null})
        setPaymentType({balanceAmount: 0})  
        navigate('/newOrder')
        hideMainActionSheet()
    }
    const handleCategroyModal = () => {
        showAddCategoryOffcanvas({addCategoryOffcanvasVisible: true})
        hideMainActionSheet()
    }
    const handleAddPurchase = () => {
        activeTabUpdatedName({activeTabName: 'purchase'})
        navigate('/ordersDetail')
    }
    const handleAddWastage = () => {
        activeTabUpdatedName({activeTabName: 'wastage'})
        navigate('/ordersDetail')
    }
    const handleNewTransaction = () => {
        hideMainActionSheet()
        showAddTransactionModal({addTransactionModalVisible: true, transactionData: {
            "Transaction_Category_ID": 1,
            "Transaction_Type":	"expense",	
            "BalanceAmount": '100',	
            "Date":	new Date().toISOString().substr(0, 10),
            "Description": '',
            "Currency": "INR",	
            "PaymentMethod": 'cash',
            "Item_Order_ID": null,
            "OrderType": "",
            "ID": null
        }})
    }
    return (
        <div>
            <Offcanvas show={mainActionSheetVisible} placement={'bottom'} style={{height: 'auto'}} onHide={() => {hideMainActionSheet()}}>
                <Offcanvas.Header closeButton style={{minHeight: '36px'}}>
                    <Offcanvas.Title class="w-100">
                        <div class="row w-100">
                            <div class="col-12 col-md-12 d-flex align-items-center">
                                Quick Action
                            </div>
                        </div>
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body class="pt-0 offcanvas-body p-0">
                    <ul class="action-button-list">
                        <li>
                            <a href="javascript:void(0)" class="btn btn-list" onClick={handleAddPurchase}>
                                <span>
                                    <ion-icon name="card-outline"></ion-icon>
                                    Add Purchase
                                </span>
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0)" class="btn btn-list" onClick={handleAddWastage}>
                                <span>
                                    <ion-icon name="trash-outline"></ion-icon>
                                    Add Wastage
                                </span>
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0)" class="btn btn-list" onClick={handleNewTransaction}>
                                <span>
                                    <ion-icon name="cash-outline"></ion-icon>
                                    New Transaction
                                </span>
                            </a>
                        </li>
                        <li class="action-divider"></li>
                        <li>
                            <a href="javascript:void(0)" class="btn btn-list" onClick={handleNewOrderSwitch}>
                                <span>
                                    <ion-icon name="add-circle-outline"></ion-icon>
                                    New Order
                                </span>
                            </a>
                        </li>
                        <li class="ps-2 pe-2 mb-4">
                            <CheckoutTopScroll topScrollValue={topScrollValue} pageName={'NewOrder'} shortCut={true}></CheckoutTopScroll>
                        </li>
                    </ul>
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    );
}

const mapStateToProps = ({ mainActionSheet, data }) => {
    const {
        mainActionSheetVisible
    } = mainActionSheet
    const {
        topScrollValue
    } = data
    return {
        mainActionSheetVisible,
        topScrollValue
    }
}
  
const mapDispatchToProps = {
    hideMainActionSheet,
    showAddCategoryOffcanvas,
    activeTabUpdatedName,
    showAddTransactionModal,
    setPaymentType,
    setInvoiceID
}

export default connect(mapStateToProps, mapDispatchToProps)(MainActionSheet)