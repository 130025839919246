import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import axios from '../../../AxiosConfig'
import { useNavigate } from 'react-router-dom'
import Moment from 'react-moment'
import printerLoader from '../../images/icon/printer.png'
import { showPaymentOffcanvas } from '../../../features/Offcanvas/paymentOffcanvasSlice'
import { setInvoiceID, setInvoiceNumber, setOrderIDs, setPaymentType, setInvoiceCount, setInvoiceOrderList, renderOngoingOrderList, setKotData, setFullInvoice, runUpdatedCartFunction, cartOrderIndex, cartDraftIndex, cartDraftData } from '../../../features/Data/dataSlice'
import { hideOngoingOffcanvas } from '../../../features/Offcanvas/ongoingOffcanvasSlice'
import { showConfirmationModal } from '../../../features/Offcanvas/confirmationModalSlice'

const OngoingOrders = ({
    showPaymentOffcanvas,
    setInvoiceID,
    setInvoiceNumber,
    setOrderIDs,
    setPaymentType,
    setInvoiceCount,
    setInvoiceOrderList,
    renderOngoingOrder,
    renderOngoingOrderList,
    setKotData,
    hideOngoingOffcanvas,
    setFullInvoice,
    showConfirmationModal,
    runUpdatedCartFunction,
    cartOrderIndex,
    renderOrders,
    draftData,
    cartDraftIndex,
    draftIndex,
    cartDraftData
}) => {
    const [invoiceList, setInvoiceList] = useState(null)
    let navigate = useNavigate()
    let tempOrderIDs = []
    const handlePaymentMethod = (invoiceid, orderList) => {
        setInvoiceOrderList({invoiceWithOrders: orderList, paymentStatus: 'unpaid'})
        setOrderIDs({ orderIDs: tempOrderIDs})
        setInvoiceID({invoiceID: invoiceid})
        setPaymentType({balanceAmount: orderList.BalanceAmount})
        showPaymentOffcanvas({paymentOffcanvasVisible: true})
        hideOngoingOffcanvas()
    }
    const addNewOrder = (fullInvoice, key) => {
        let tempFullInvoice = JSON.parse(JSON.stringify(fullInvoice))
        tempFullInvoice.Amount = fullInvoice.BalanceAmount
        setFullInvoice({fullInvoice: tempFullInvoice})
        let checkExisitingDraft = false
        let tempDraftData = JSON.parse(JSON.stringify(draftData))
        {draftData.map((v,k) => {
            if (v.OfflineInvoiceNumber === fullInvoice.OfflineInvoiceNumber) {
                checkExisitingDraft = true
                cartDraftIndex({draftIndex: k})
                tempFullInvoice.Orders.map((v2,k2) => {
                    tempFullInvoice.Orders[k2].OrderStatus = 1
                })
                tempDraftData[k] = tempFullInvoice
                cartDraftData({draftData: tempDraftData})
                return true
            }
        })}
        if (!checkExisitingDraft) {
            {draftData.map((v,k) => {
                if (v.OfflineInvoiceNumber === '') {
                    cartDraftIndex({draftIndex: k})
                    tempDraftData[k] = tempFullInvoice
                    cartDraftData({draftData: tempDraftData})
                    return true
                }
            })}
        }
        navigate('/newOrder')
        hideOngoingOffcanvas()
        cartOrderIndex({odIndex: fullInvoice.Orders.length})
        runUpdatedCartFunction({updateCartFlag: 'activeOrder', updateCartKey: key})
    }
    let offlineOrderList = []
    useEffect(() => {
        if (renderOngoingOrder === 'render' || renderOngoingOrder === '') {
            offlineOrderList = JSON.parse(localStorage.getItem('Orders'))
            let currentInvoiceCount = 0
            offlineOrderList?.map((v,k) => {
                if (v.Status === 0) {
                    currentInvoiceCount = currentInvoiceCount + 1 
                    setInvoiceCount({invoiceCount: currentInvoiceCount})
                } else {
                    setInvoiceCount({invoiceCount: 0})
                }
                offlineOrderList[k].TotalItems = 0
                v.Orders.map((v1,k1) => {
                    offlineOrderList[k].TotalItems = offlineOrderList[k].TotalItems + v1.TotalQty
                })
            })
            setInvoiceList(offlineOrderList)
        }
        setTimeout(() => {
            renderOngoingOrderList({renderOngoingOrder: ''})
        }, 30000);
    }, [renderOngoingOrder, renderOrders])

    const handleDeleteOrder = (orderID, message) => {
        showConfirmationModal({confirmationModalVisible: true, ID: orderID, message: message, deleteType: 'Invoices'})
    }

    const handleDeleteKot = (orderID, message) => {
        showConfirmationModal({confirmationModalVisible: true, ID: orderID, message: message, deleteType: 'kot'})
    }

    const printkot = (kotData) => {
        setKotData({kotData: kotData, printFrom: 'OngoingOrder'})
    }
    return (
        <>
            {(invoiceList !== null && invoiceList !== 'null' && invoiceList !== undefined && invoiceList !== 'undefined' && invoiceList.length > 0) &&
                <div class="accordion" id="accordionExample1">       
                    <>
                        {invoiceList?.map((v1,k1) => {
                            return  <>{v1.Status === 0 &&
                                <div class="accordion-item">
                                    <h2 class="accordion-header">
                                        <ul class="listview image-listview media flush">
                                            <li class="multi-level">
                                                <a href="#" class="item accordion-button collapsed pe-5" data-bs-toggle="collapse" data-bs-target={'#accordion' + k1}>
                                                    <div class="icon-box bg-primary">
                                                        <ion-icon name="storefront-outline" class="fs-5 me-0"></ion-icon>
                                                    </div>
                                                    <div class="in">
                                                        <div class="w-100" style={{lineHeight: 1.5}}>
                                                            <header>#{v1.OfflineInvoiceNumber}</header>
                                                            <span class="text-primary fw-bolder">₹{v1.BalanceAmount}</span>
                                                            <footer>{v1.Date}</footer>
                                                        </div>
                                                        <span class="text-muted">
                                                            <span class="badge bg-success" style={{height: '18px'}}>{v1.TotalItems} Items</span>
                                                        </span>
                                                    </div>
                                                </a>
                                            </li>
                                        </ul>
                                    </h2>
                                    <div id={'accordion' + k1} class="accordion-collapse collapse" data-bs-parent="#accordionExample1">
                                        <div class="accordion-body pe-0">
                                            <ul class="listview image-listview">
                                                <li class="pe-2 py-2">
                                                    <div class="gap-2 d-flex justify-content-between">
                                                        <button class="btn btn-sm btn-warning btn-block fs-6" onClick={() => {handleDeleteOrder(v1.ID, 'Are you sure you want to cancel this Order ?')}}>Cancel Order</button>
                                                        <button class="btn btn-sm btn-success btn-block fs-6" onClick={() => {addNewOrder(v1,k1)}}>Add KOT</button>
                                                        <button class="btn btn-sm btn-primary btn-block fs-6" onClick={() => {handlePaymentMethod(v1.OfflineInvoiceNumber, v1)}}>Deliver</button>
                                                    </div>
                                                </li>
                                                {v1?.Orders?.map((v2, k2) => {
                                                    return <li>
                                                        <div class="item">
                                                            <div class="icon-box webweb-bg-secondary cursor-pointer" style={{width: '45px', height: '45px', minWidth: '45px', maxWidth: '45px'}} onClick={() => {printkot(v2)}}>
                                                                <ion-icon name="print-outline" class="fs-1 me-0"></ion-icon>
                                                            </div>
                                                            <div class="in flex-column align-items-start">
                                                                <header>#{v2.OrderNumber}</header>
                                                                <div class="d-flex" style={{lineHeight: 1.5}}>
                                                                    <>
                                                                        {v2.Items.map((v3, k3) => {
                                                                            return <>
                                                                                {(v3.Image !== null && v3.Image !== undefined && v3.Image !== '') &&
                                                                                    <img src={v3.Image} alt="avatar" class="imaged rounded" style={{height: '20px',width: '20px', marginRight: '2px', objectFit: 'cover'}} />
                                                                                }
                                                                                {(v3.Image === null || v3.Image === undefined || v3.Image === '') &&
                                                                                    <img src="https://us.123rf.com/450wm/viktorijareut/viktorijareut1503/viktorijareut150300119/37649514-grey-tray-kitchenware-trey-isolated-tray-vector-closed-tray.jpg?ver=6" alt="avatar" class="imaged rounded" style={{height: '20px',width: '20px', marginRight: '2px', objectFit: 'cover'}} />
                                                                                }
                                                                                {v3.ItemTitle} <span class="text-warning fw-bolder">x{v3.quantity}</span>
                                                                                {v2.Items.length > 1 &&
                                                                                    <>
                                                                                        , &nbsp;
                                                                                    </>
                                                                                }
                                                                            </>
                                                                        })} 
                                                                    </>
                                                                </div>
                                                                <footer><Moment fromNow>{v2.DateTime}</Moment></footer>
                                                            </div>
                                                            <button class="btn btn-sm btn-danger" onClick={() => {handleDeleteKot(v2.ID, 'Are you sure you want to cancel this KOT ?')}} style={{textWrap: 'nowrap'}}>Cancel KOT</button>
                                                        </div>
                                                    </li>
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            }</>
                        })}
                    </>
                </div>
            }
            {(invoiceList === null || invoiceList === undefined || invoiceList.length === 0) &&
                <h2 class="d-flex justify-content-center align-items-center text-danger" style={{minHeight: '430px'}}>No Invoice Found</h2>
            }
        </>
    )
}

const mapStateToProps = ({ data }) => {
    const {
        renderOngoingOrder,
        renderOrders,
        draftData,
        draftIndex
    } = data
    return {
        renderOngoingOrder,
        renderOrders,    
        draftData,
        draftIndex
    }
  }
  
  const mapDispatchToProps = {
    showPaymentOffcanvas,
    setInvoiceID,
    setInvoiceNumber,
    setOrderIDs,
    setPaymentType,
    setInvoiceCount,
    setInvoiceOrderList,
    renderOngoingOrderList,
    setKotData,
    hideOngoingOffcanvas,
    setFullInvoice,
    runUpdatedCartFunction,
    cartOrderIndex,
    showConfirmationModal,
    cartDraftIndex,
    cartDraftData
  }

export default connect(mapStateToProps, mapDispatchToProps)(OngoingOrders)