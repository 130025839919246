import React, { useState, useEffect } from 'react'
import { connect } from "react-redux"
import { categoryIdUpdate, setCategoryName, setTempCategoryId } from '../../../features/Data/dataSlice'

const ItemCategoriesList = ({
    categories,
    categoryIdUpdate,
    setCategoryName,
    categoryName,
    viewType,
    alternateTextVisible,
    colorVisible,
    itemName,
    setTempCategoryId,
    tempCategoryId
}) => {
    // const [ID, setID] = useState(null)
    useEffect(() => {
        if (categoryName === '' || categoryName === undefined || categoryName === null) {
            categoryIdUpdate({categoryID: 'all'})
            // setID(null)
            setTempCategoryId({tempCategoryId: null})
        }
    }, [categoryName, itemName])
    const handleCategorySwitch = (categoryID, categoryName) => {
        categoryIdUpdate({categoryID: (categoryID === undefined || categoryID === null || categoryID === '') ? 1 : categoryID})
        // setID(categoryID)
        setTempCategoryId({tempCategoryId: categoryID})
        setCategoryName({categoryName: categoryName})
    }
    return (
        <>
            {(categories !== null && categories !== undefined) &&
                <ul class="listview link-listview bg-transparent">
                    {categories.map(value => {
                        return <li class={(tempCategoryId === value.ID) ? 'd-flex bg-transparent' : 'd-flex bg-white'}>
                            {colorVisible &&
                                <div style={{height: '35px', width: '5px',color: 'white', background: value.Color}}>
                                    {tempCategoryId}{value.ID}
                                </div>
                            }
                            <a href="javascript:void(0)" class="px-2 justify-content-start" onClick={() => {handleCategorySwitch(value.ID, value.Name)}}>
                            {(alternateTextVisible === 'shortcode' || alternateTextVisible === 'englishText') &&
                                <>
                                    {value.Name && value.Name}
                                </>
                            }
                            {alternateTextVisible === 'alternateText' &&
                                <>
                                    {value.AlternateText && value.AlternateText}
                                </>
                            }
                            </a>
                        </li>
                    })}
                </ul>
            }
        </>
    )
}

const mapStateToProps = ({ data, general }) => {
    const {
        categories,
        categoryName,
        itemName,
        tempCategoryId
    } = data
    const {
        alternateTextVisible,
        colorVisible
    } = general
    return {
        categories,
        categoryName,
        alternateTextVisible,
        colorVisible,
        itemName,
        tempCategoryId
    }
  }
  
const mapDispatchToProps = {
    categoryIdUpdate,
    setCategoryName,
    setTempCategoryId
}

export default connect(mapStateToProps, mapDispatchToProps)(ItemCategoriesList)