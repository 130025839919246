import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import jwt_decode from 'jwt-decode'
import { useNavigate } from 'react-router-dom'
import Select from 'react-select'
import CheckoutMobileNo from './BitComponents/CheckoutMobileNo'
import moment from 'moment-timezone'
import axios from '../../AxiosConfig'

const CustomOrder = ({
    businessData,
    authToken,
    items
}) => {
    const [branchData, setBranchData] = useState(null)
    const [businessTempData, setBusinessTempData] = useState(null)
    const [selectedOption, setSelectedOption] = useState(null)
    const [tempCurrentDate, setTempCurrentDate] = useState(null)
    const [disableSubmitButton, setDisableSubmitButton] = useState(false)
    const [orderNumber, setOrderNumber] = useState(null)
    // USESTATE FOR FORM FIELDS START
    const [billNo, setBillNo] = useState(null)
    const [orderDate, setOrderDate] = useState(null)
    const [deliveryDate, setDeliveryDate] = useState(null)
    const [eventType, setEventType] = useState("birthday")
    const [paymentMethod, setPaymentMethod] = useState("Cash")
    const [eventText, setEventText] = useState(null)
    const [deliveryTime, setDeliveryTime] = useState("12:00")
    const [selectedCakeType, setSelectedCakeType] = useState({
        "label": "Normal",
        "value": "normal"
    })
    const [itemSize, setItemSize] = useState(null)
    // USESTATE FOR FORM FIELDS END
    const [titleName, setTitleName] = useState(null)
    const [tempCustomOrderObj, setTempCustomOrderObj] = useState(null)
    let navigate = useNavigate()
    const calculateYear = (number) => {
        let num = number.toString()
        if (num.length === 1) {
            num = '00' + num
        } else if (num.length === 2) {
            num = '0' + num
        }
        return num
    }
    useEffect(() => {
        const tokenData = jwt_decode(authToken)
        if (businessData) {
          businessData.map((value, key) => {
            value.Branches.map((v,k) => {
                if (value.ID === tokenData.userInfo.Business_ID && v.ID === tokenData.userInfo.DefaultLocationID) {
                    setBranchData(v)
                    setBusinessTempData(value)
                }
            })
          }) 
        }
    }, [businessData, authToken])
    // const options = [
    //     {
    //       label: "Cake",
    //       options: [
    //         { label: "vanilla", value: "Vanilla" },
    //         { label: "chocolate", value: "Chocolate" }
    //       ]
    //     },
    //     {
    //         label: "Pastry",
    //         options: [
    //           { label: "strawbarry", value: "Strawbarry" },
    //           { label: "mango", value: "Mango" },
    //           { label: "chocolate", value: "ChocolatePastry" }
    //         ]
    //     }
    // ];
    useEffect(() => {
        let options = []
        if (items) {
            items.map((v,k) => {
                options.push({
                    "label": v.ItemTitle,
                    "value": v.ID,
                    "data": v
                })
            })
            setTitleName(options)
            const currentDate = new Date().toISOString().split('T')[0]
            setTempCurrentDate(currentDate)
            setOrderDate(currentDate)
            setDeliveryDate(currentDate)
        } else {
            navigate('/load')
        }
    }, [items])
    const cakeType = [
        { label: "Normal", value: "normal" },
        { label: "Photo Cake", value: "photoCake" },
        { label: "Tower", value: "tower" },
        { label: "Doll", value: "doll" },
        { label: "Square", value: "square" },
        { label: "Round", value: "round" },
        { label: "Heart", value: "heart" },
        { label: "Other Design", value: "otherDesign" },
        { label: "Less Cream", value: "lessCream" },
        { label: "Foundant", value: "foundant" },
        { label: "Home Delivery", value: "homeDelivery" }
    ]
    function updateObj (data, dataType) {
        console.log(data, dataType)
        const tempCustomOrderObj2 = JSON.parse(JSON.stringify(tempCustomOrderObj))
        if (dataType === 'billNo' ) {
            setBillNo(data)
            tempCustomOrderObj2.billNo = data
        } else if (dataType === 'orderDate') {
            setOrderDate(data)
            tempCustomOrderObj2.OrderDate = data
        } else if (dataType === 'deliveryDate') {
            setDeliveryDate(data)
            tempCustomOrderObj2.DeliveryDate = data
        } else if (dataType === 'eventType') {
            setEventType(data)
            tempCustomOrderObj2.Type = data
        } else if (dataType === 'eventText') {
            setEventText(data)
            tempCustomOrderObj2.EventText = data
        } else if (dataType === 'deliveryTime') {
            setDeliveryTime(data)
            tempCustomOrderObj2.DeliveryTime = data
        } else if (dataType === 'cakeType') {
            setSelectedCakeType(data)
            tempCustomOrderObj2.CakeType = data
        } else if (dataType === 'paymentMethod') {
            setPaymentMethod(data)
            tempCustomOrderObj2.PaymentMethod = data
        }
        setTempCustomOrderObj(tempCustomOrderObj2)
    }
    // ON CHANGE EVENT START
    const updateBillNo = (billNo) => {
        updateObj(billNo, 'billNo')
    }
    const updateOrderDate = (orderDate) => {
        updateObj(orderDate, 'orderDate')
    }
    const updateDeliveryDate = (deliveryDate) => {
        updateObj(deliveryDate, 'deliveryDate')
    }
    const updateEventType = (eventType) => {
        updateObj(eventType, 'eventType')
    }
    const updateEventText = (eventText) => {
        updateObj(eventText, 'eventText')
    }
    const updateDeliveryTime = (deliveryTime) => {
        updateObj(deliveryTime, 'deliveryTime')
    }
    const handleCakeTypeChange = (cakeType) => {
        updateObj(cakeType, 'cakeType')
    }
    const updatePaymentMethod = (paymentMethod) => {
        updateObj(paymentMethod, 'paymentMethod')
    }
    // ON CHANGE EVEN END
    let customOrderItem = []
    let customOrderObj =  {}
    const targetTimezone = 'Asia/Kolkata'
    const newDate = moment().tz(targetTimezone).format('YYYY-MM-DD HH:mm:ss')
    const tempBillNo = localStorage.getItem('billNo')
    useEffect(() => {
        let d = new Date();
        const year = d.getFullYear();
        const start = new Date(d.getFullYear(), 0, 0);
        const diff = d - start;
        const oneDay = 1000 * 60 * 60 * 24;
        const day = Math.floor(diff / oneDay);
        var seconds = (d.getSeconds() < 10 ? '0' : '') + d.getSeconds();
        var minutes = (d.getMinutes() < 10 ? '0' : '') + d.getMinutes();
        var hour = (d.getHours() < 10 ? '0' : '') + d.getHours();
        setOrderNumber(year + calculateYear(day) + 'AA' + 'K01' + hour + minutes + seconds)
        const tempOrderNumber = year + calculateYear(day) + 'AA' + 'K01' + hour + minutes + seconds
        for (let i = 0; i < 4; i++) {
            customOrderItem.push({
                "quantity":0,
                "MRP":0,
                "Cost":0,
                "ItemTitle":null,
                "Item_ID":null,
                "Taxes":0,
                "Status":false,
                "Image": null,
                "AlternateText": null,
                "Sizes": []
            }) 
        }
        customOrderObj = {
            "OfflineInvoiceNumber": "IN" + tempOrderNumber,
            "Items":customOrderItem,
            "TotalQty":0,
            "Subtotal":0,
            "TotalTax": 0,
            "Total":0,
            "Type":"birthday",
            "DateTime":newDate,
            "OrderNumber":tempOrderNumber,
            "Currency":"INR",
            "Phone":null,
            "Date": new Date().toISOString().split('T')[0],
            "UserName":"",
            "BalanceAmount":0,
            "PaymentMethod":"",
            "OrderType": "custom",
            "Amount": 0,
            "TransactionDate": null,
            // "Customer_ID": "",
            "Status": 0,
            "TotalDiscount": 0,
            "billNo": parseInt(tempBillNo) + 1,
            "OrderDate": new Date().toISOString().split('T')[0],
            "Status": 1
        }
        setTempCustomOrderObj(customOrderObj)
    }, [])
    const handleTitleChange = (value, index) => {
        setSelectedOption(value)
        const tempCustomOrderObj2 = JSON.parse(JSON.stringify(tempCustomOrderObj))
        if (value.data.Sizes.length > 0) {
        } else {
            tempCustomOrderObj2.Items[index].Item_ID = value.value
            tempCustomOrderObj2.Items[index].AlternateText = value.data.AlternateText
            tempCustomOrderObj2.Items[index].MRP = value.data.MRP
            tempCustomOrderObj2.Items[index].Cost = value.data.Cost
        }
        tempCustomOrderObj2.Items[index].ItemTitle = value.label
        tempCustomOrderObj2.Items[index].Sizes = value.data.Sizes
        tempCustomOrderObj2.Items[index].Image = value.data.ImageURL
        tempCustomOrderObj2.Items[index].quantity = 1
        let count = 0
        let tempSubtotal = 0
        tempCustomOrderObj2.Items.map((v,k) => {
            if (v.quantity && v.MRP) {
                tempSubtotal += v.quantity * v.MRP
            }
            count ++
        })
        tempCustomOrderObj2.Subtotal = tempSubtotal
        tempCustomOrderObj2.Total = tempSubtotal
        tempCustomOrderObj2.Amount = tempSubtotal
        tempCustomOrderObj2.Advance = tempSubtotal
        setTempCustomOrderObj(tempCustomOrderObj2)
    }
    const updateItemSize = (value, index) => {
        setItemSize(value)
        const tempCustomOrderObj2 = JSON.parse(JSON.stringify(tempCustomOrderObj))
        let count = 0
        let tempSubtotal = 0
        tempCustomOrderObj2.Items.map((v,k) => {
            v.Sizes.map((v1,k1) => {
                if (value === v1.Item_ID) {
                    tempCustomOrderObj2.Items[index].SelectedSize = v1
                    tempCustomOrderObj2.Items[index].Item_ID = v1.Item_ID
                    tempCustomOrderObj2.Items[index].AlternateText = v1.AlternateText
                    tempCustomOrderObj2.Items[index].MRP = v1.MRP
                    tempCustomOrderObj2.Items[index].Cost = v1.Cost
                }
            })
            if (v.quantity && v.MRP) {
                tempSubtotal += v.quantity * v.MRP
            }
            count ++
        })
        tempCustomOrderObj2.Subtotal = tempSubtotal
        tempCustomOrderObj2.Total = tempSubtotal
        tempCustomOrderObj2.Amount = tempSubtotal
        tempCustomOrderObj2.Advance = tempSubtotal
        tempCustomOrderObj2.Items[index].ItemSize = value
        setTempCustomOrderObj(tempCustomOrderObj2)
    }
    const updateItemSizeCustom = (value, index) => {
        const tempCustomOrderObj2 = JSON.parse(JSON.stringify(tempCustomOrderObj))
        tempCustomOrderObj2.Items[index].CustomSIze = value
        setTempCustomOrderObj(tempCustomOrderObj2)
    }
    const updateItemQty = (value, index) => {
        setItemSize(value)
        const tempCustomOrderObj2 = JSON.parse(JSON.stringify(tempCustomOrderObj))
        tempCustomOrderObj2.Items[index].quantity = value
        let count = 0
        let tempSubtotal = 0
        tempCustomOrderObj2.Items.map((v,k) => {
            if (v.quantity && v.MRP) {
                tempSubtotal += v.quantity * v.MRP
            }
            count ++
        })
        tempCustomOrderObj2.Subtotal = tempSubtotal
        tempCustomOrderObj2.Total = tempSubtotal
        tempCustomOrderObj2.Amount = tempSubtotal
        tempCustomOrderObj2.Advance = tempSubtotal
        setTempCustomOrderObj(tempCustomOrderObj2)
    }
    const updateItemMRP = (value, index) => {
        setItemSize(value)
        const tempCustomOrderObj2 = JSON.parse(JSON.stringify(tempCustomOrderObj))
        tempCustomOrderObj2.Items[index].MRP = value
        let count = 0
        let tempSubtotal = 0
        tempCustomOrderObj2.Items.map((v,k) => {
            if (v.quantity && v.MRP) {
                tempSubtotal += v.quantity * v.MRP
            }
            count ++
        })
        tempCustomOrderObj2.Subtotal = tempSubtotal
        tempCustomOrderObj2.Total = tempSubtotal
        tempCustomOrderObj2.Amount = tempSubtotal
        tempCustomOrderObj2.Advance = tempSubtotal
        setTempCustomOrderObj(tempCustomOrderObj2)
    }
    const updateAdvance = (value, index) => {
        const tempCustomOrderObj2 = JSON.parse(JSON.stringify(tempCustomOrderObj))
        tempCustomOrderObj2.Advance = value
        tempCustomOrderObj2.BalanceAmount = parseInt(tempCustomOrderObj2.Total) - parseInt(tempCustomOrderObj2.Advance) 
        console.log(tempCustomOrderObj2)
        setTempCustomOrderObj(tempCustomOrderObj2)
    }
    const updateCgst = (value, index) => {
        const tempCustomOrderObj2 = JSON.parse(JSON.stringify(tempCustomOrderObj))
        tempCustomOrderObj2.Cgst = value
        tempCustomOrderObj2.Total = parseInt(tempCustomOrderObj2.Subtotal) + (parseInt(tempCustomOrderObj2.Cgst) ? parseInt(tempCustomOrderObj2.Cgst) : 0) + (parseInt(tempCustomOrderObj2.Sgst) ? parseInt(tempCustomOrderObj2.Sgst) : 0)
        tempCustomOrderObj2.Amount = tempCustomOrderObj2.Total
        tempCustomOrderObj2.Advance = tempCustomOrderObj2.Amount
        setTempCustomOrderObj(tempCustomOrderObj2)
    }
    const updateSgst = (value, index) => {
        const tempCustomOrderObj2 = JSON.parse(JSON.stringify(tempCustomOrderObj))
        tempCustomOrderObj2.Sgst = value
        tempCustomOrderObj2.Total = parseInt(tempCustomOrderObj2.Subtotal) + (parseInt(tempCustomOrderObj2.Cgst) ? parseInt(tempCustomOrderObj2.Cgst) : 0) + (parseInt(tempCustomOrderObj2.Sgst) ? parseInt(tempCustomOrderObj2.Sgst) : 0)
        tempCustomOrderObj2.Amount = tempCustomOrderObj2.Total
        tempCustomOrderObj2.Advance = tempCustomOrderObj2.Amount
        setTempCustomOrderObj(tempCustomOrderObj2)
    }
    const handleCustomOrderSubmit = (e) => {
        e.preventDefault()
        console.log(tempCustomOrderObj)
        setDisableSubmitButton(true)
        axios.post('/Order/CustomOrder', tempCustomOrderObj).then(res => {
            console.log(res.data)
            if (res.data.message === 'Success') {
                setDisableSubmitButton(false)
                // showAppToast({toastMessage: 'Order Placed',background: 'success',icon: 'close-circle-outline',position: 'center',timeout: '1000'})
            } else {
                // showAppToast({toastMessage: 'Something went wrong',background: 'danger',icon: 'close',position: 'center',timeout: 1000})
            }
        })
    }
    return (
        <div id="appCapsule">
            <div class="section full mt-1">
                <form onSubmit={(e) => {handleCustomOrderSubmit(e)}}>
                    <div class="invoice p-0 bg-transparent" style={{boxShadow: 'none'}}>
                        <div class="row">
                            <div class="col-md-12 d-flex justify-content-center flex-column">
                                <h3 class="mb-0 text-center">{businessTempData?.Name}</h3>
                                <small class="text-secondary text-center">({branchData?.Name})</small>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-6 col-md-6">
                                        <p class="mb-1">
                                            <div class="form-group boxed pb-0">
                                                <div class="input-wrapper">
                                                    <label class="form-label" for="billNo">Bill No</label>
                                                    <input type="number" class="form-control form-control-sm p-0 ps-1" id="billNo" placeholder="Bill No" value={tempCustomOrderObj?.billNo} autocomplete="off" required onChange={(e) => {updateBillNo(e.target.value)}} style={{minHeight: '36px', height: '36px'}} />
                                                    <i class="clear-input">
                                                        <ion-icon name="close-circle"></ion-icon>
                                                    </i>
                                                </div>
                                            </div>
                                        </p>
                                    </div>
                                    <div class="col-6 col-md-6">
                                        <p class="mb-1">
                                            <div class="form-group boxed pb-0">
                                                <div class="input-wrapper">
                                                    <label class="form-label" for="customerName">Mobile No</label>
                                                </div>
                                            </div>
                                            <CheckoutMobileNo></CheckoutMobileNo>
                                        </p>
                                    </div>
                                    <div class="col-6 col-md-6">
                                        <p class="mb-1">
                                            <div class="form-group boxed pb-0">
                                                <div class="input-wrapper">
                                                    <label class="form-label" for="deliveryDate">Order Date</label>
                                                    <input type="date" class="form-control form-control-sm p-0 ps-1" id="date" placeholder="Date" value={orderDate} autocomplete="off" onChange={(e) => {updateOrderDate(e.target.value)}} min={tempCurrentDate} style={{minHeight: '36px', height: '36px'}} required />
                                                    <i class="clear-input">
                                                        <ion-icon name="close-circle"></ion-icon>
                                                    </i>
                                                </div>
                                            </div>
                                        </p>
                                    </div>
                                    <div class="col-6 col-md-6">
                                        <p class="mb-1">
                                            <div class="form-group boxed">
                                                <div class="input-wrapper">
                                                    <label class="form-label" for="deliveryDate">Delivery Date</label>
                                                    <div class="d-flex">
                                                        <input type="date" class="form-control form-control-sm p-0 ps-1 me-1" id="date" placeholder="Date" autocomplete="off" onChange={(e) => {updateDeliveryDate(e.target.value)}} min={orderDate} value={deliveryDate} style={{minHeight: '36px', height: '36px'}} />
                                                        <i class="clear-input">
                                                            <ion-icon name="close-circle"></ion-icon>
                                                        </i>
                                                    </div>
                                                </div>
                                            </div>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-6 col-md-6">
                                        <p class="mb-1">
                                            <div class="form-group boxed pb-0">
                                                <div class="input-wrapper">
                                                    <label class="form-label" for="event">Select Event</label>
                                                    <select class="form-control form-select-sm form-select" id="event" value={eventType} onChange={(e) => {updateEventType(e.target.value)}} style={{minHeight: '36px', height: '36px'}} required>
                                                        <option value="">Select event</option>
                                                        <option value="birthday">Happy Birthday</option>
                                                        <option value="annivarsary">Happy Annivarsary</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </p>
                                    </div>
                                    <div class="col-6 col-md-6">
                                        <p class="mb-1">
                                            <div class="form-group boxed pb-0">
                                                <div class="input-wrapper">
                                                    <label class="form-label" for="eventText" style={{textTransform: 'capitalize'}}> Text on Cake</label>
                                                    <input type="text" class="form-control form-control-sm p-0 ps-1" id="eventText" placeholder={'Text on Cake'} onChange={(e) => {updateEventText(e.target.value)}} value={eventText} autocomplete="off" style={{minHeight: '36px', height: '36px'}} />
                                                    <i class="clear-input">
                                                        <ion-icon name="close-circle"></ion-icon>
                                                    </i>
                                                </div>
                                            </div>
                                        </p>
                                    </div>
                                    <div class="col-6 col-md-6">
                                        <p class="mb-1">
                                            <div class="form-group boxed">
                                                <div class="input-wrapper">
                                                    <label class="form-label" for="deliveryDate">Delivery Time</label>
                                                    <div class="d-flex">
                                                        <input type="time" class="form-control form-control-sm p-0 ps-1" id="time" placeholder="time" autocomplete="off" value={deliveryTime} onChange={(e) => {updateDeliveryTime(e.target.value)}} style={{minHeight: '36px', height: '36px'}} />
                                                        <i class="clear-input">
                                                            <ion-icon name="close-circle"></ion-icon>
                                                        </i>
                                                    </div>
                                                </div>
                                            </div>
                                        </p>
                                    </div>
                                    <div class="col-6 col-md-6">
                                        <p class="mb-0">
                                            <div class="form-group boxed pb-0">
                                                <div class="input-wrapper">
                                                    <label class="form-label" for="cakeType">Cake Type</label>
                                                </div>
                                            </div>
                                            <Select
                                                value={selectedCakeType}
                                                onChange={handleCakeTypeChange}
                                                options={cakeType}
                                                autoFocus={false}
                                                isMulti
                                                required
                                            />
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="invoice-detail mt-2">
                            <div>
                                <div class="table-responsive">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <td>ITEM</td>
                                                <td class="text-start">SIZE(S)</td>
                                                <td>QTY</td>
                                                <td>RATE</td>
                                                <td>SUBTOTAL</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {tempCustomOrderObj?.Items.map((v,k) => {
                                                return <tr key={k}>
                                                    <td>
                                                        <div style={{minWidth: '200px'}}>
                                                            <Select
                                                                value={{
                                                                    "label": v?.ItemTitle,
                                                                    "value": v?.Item_ID
                                                                }}
                                                                onChange={(e) => {handleTitleChange(e, k)}}
                                                                options={titleName}
                                                                autoFocus={false}
                                                                key={k}
                                                            />
                                                        </div>
                                                    </td>
                                                    {v?.Sizes?.length > 0 &&
                                                        <td>
                                                            <div class="form-group boxed ms-0 pb-0 pt-0">
                                                                <div class="input-wrapper d-flex justify-content-start">
                                                                    <select class="form-control form-control-sm form-select text-center" value={v?.SelectedSize?.Item_ID} id="time" onChange={(e) => {updateItemSize(e.target.value, k)}} style={{minHeight: '38px', height: '38px', minWidth: '50px',width: '150px', fontSize: '0.875rem',paddingRight: '30px'}}>
                                                                        <option class="text-start" value="">Select a size</option>
                                                                        {v?.Sizes?.map((v1,k1) => {
                                                                            return <option class="text-start" value={v1.Item_ID}>{v1.ItemTitle}</option>
                                                                        })}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    }
                                                    {(v?.Sizes?.length === 0 || v?.Sizes === null || v?.Sizes === undefined) &&
                                                        <td>
                                                            <div class="form-group boxed ms-0 pb-0 pt-0">
                                                                <div class="input-wrapper d-flex justify-content-start">
                                                                    <input type="number" class="form-control form-control-sm p-0 ps-1 text-start" id="size" placeholder="Size" onChange={(e) => {updateItemSizeCustom(e.target.value, k)}} value={v.CustomSize} autocomplete="off" style={{minHeight: '38px', height: '38px', width: '150px'}} />
                                                                    <i class="clear-input">
                                                                        <ion-icon name="close-circle"></ion-icon>
                                                                    </i>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    }
                                                    <td>
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper d-flex justify-content-center">
                                                                <input type="number" class="form-control form-control-sm p-0 ps-1 text-center" id="qty" placeholder="Qty" onChange={(e) => {updateItemQty(e.target.value, k)}} value={v.quantity} autocomplete="off" style={{minHeight: '38px', height: '38px', width: '100px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="pe-0">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-group">
                                                                <span class="input-group-text ps-1 pe-0 bg-white" id="basic-addon1" style={{height: '38px', border: '1px solid lightgray', borderRadius: '6px 0px 0px 6px'}}>₹</span>
                                                                <input type="number" class="form-control form-control-sm p-0 ps-1 text-center" onChange={(e) => {updateItemMRP(e.target.value, k)}} value={v.MRP} placeholder="Rate" aria-label="Rate" aria-describedby="basic-addon1" style={{minHeight: '38px', height: '38px', width: '100px', borderLeft: 'none'}} />
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="ps-0">₹{parseInt(v.quantity) * parseInt(v.MRP)}</td>
                                                </tr>
                                            })}
                                            <tr>
                                                <td class="text-start" colspan="5">
                                                    <button class="btn btn-success" disabled><ion-icon name="add-outline"></ion-icon> Add</button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div class="invoice-total mt-0 border-0">
                            <div class="row">
                                <div class="col-12 col-md-9 order-2 order-md-1">
                                    <div class="row">
                                        <div class="col-6 col-md-6">
                                            <div class="form-group boxed">
                                                <div class="input-wrapper">
                                                    <label class="form-label" for="remark">Remark</label>
                                                    <textarea id="remark" rows="4" class="form-control"></textarea>
                                                    <i class="clear-input">
                                                        <ion-icon name="close-circle"></ion-icon>
                                                    </i>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6 col-md-6">
                                            <div class="row">
                                                <div class="col-6 col-md-6">
                                                    <div class="form-group boxed pb-0">
                                                        <div class="input-wrapper">
                                                            <label class="form-label" for="paymentMethod">Payment Method</label>
                                                            <select class="form-control form-select-sm form-select" id="paymentMethod" value={paymentMethod} onChange={(e) => {updatePaymentMethod(e.target.value)}} style={{minHeight: '36px', height: '36px'}} required>
                                                                <option value="">Select payment method</option>
                                                                <option value="Cash">Cash</option>
                                                                <option value="Card">Card</option>
                                                                <option value="UPI">UPI</option>
                                                                <option value="Payment-Gateway">Payment Gateway</option>
                                                                <option value="Cheque">Cheque</option>
                                                                <option value="Other">Other</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-6 col-md-6">
                                                    <div class="form-group boxed">
                                                        <div class="input-wrapper">
                                                            <label class="form-label" for="advance">Advance</label>
                                                        </div>
                                                        <div class="input-group">
                                                            <span class="input-group-text ps-1 pe-0 bg-white" id="basic-addon1" style={{height: '38px', border: '1px solid lightgray', borderRadius: '6px 0px 0px 6px'}}>₹</span>
                                                            <input type="number" class="form-control form-control-sm p-0 ps-1 text-center" placeholder="Advance" value={tempCustomOrderObj?.Advance} onChange={(e) => {updateAdvance(e.target.value)}}aria-label="advance" aria-describedby="basic-addon1" required style={{minHeight: '38px', height: '38px', borderLeft: 'none'}} />
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div class="col-6 col-md-6">
                                                    <div class="form-group boxed">
                                                        <div class="input-wrapper">
                                                            <label class="form-label" for="balance">Balance</label>
                                                        </div>
                                                        <div class="input-group">
                                                            <span class="input-group-text ps-1 pe-0 bg-white" id="basic-addon1" style={{height: '38px', border: '1px solid lightgray', borderRadius: '6px 0px 0px 6px'}}>₹</span>
                                                            <input type="number" class="form-control form-control-sm p-0 ps-1 text-center" placeholder="Balance" value={tempCustomOrderObj?.BalanceAmount} aria-label="balance" aria-describedby="basic-addon1" required style={{minHeight: '38px', height: '38px', borderLeft: 'none'}} />
                                                        </div>
                                                    </div>
                                                </div> */}
                                                <div class="col-12 col-md-12">
                                                    <div class="form-group boxed">
                                                        <div class="input-wrapper">
                                                            <label class="form-label" for="image">Upload Image</label>
                                                            <input type="file" class="form-control form-control-sm p-0 ps-1 text-start" id="image" placeholder="Image" autocomplete="off" style={{minHeight: '38px', height: '38px'}} />
                                                            <i class="clear-input">
                                                                <ion-icon name="close-circle"></ion-icon>
                                                            </i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-3 order-1 order-md-2">
                                    <ul class="listview transparent simple-listview flush">
                                        <li>Subtotal <span class="hightext">₹{tempCustomOrderObj?.Subtotal}</span></li>
                                        <li>
                                            CGST
                                            <span class="hightext">
                                                ₹{(tempCustomOrderObj?.Cgst) ? tempCustomOrderObj?.Cgst : 0}
                                                {/* <div class="form-group boxed ms-0 pb-0 pt-0">
                                                    <div class="input-group">
                                                        <span class="input-group-text ps-1 pe-0 bg-white" id="basic-addon1" style={{height: '25px', border: '1px solid lightgray', borderRadius: '6px 0px 0px 6px', fontSize: '14px'}}>₹</span>
                                                        <input type="number" class="form-control form-control-sm p-0 ps-1 text-center" onChange={(e) => {updateCgst(e.target.value)}} value={tempCustomOrderObj?.Cgst} placeholder="CGST" aria-label="cgst" aria-describedby="basic-addon1" required style={{minHeight: '25px', height: '25px', width: '60px', borderLeft: 'none'}} />
                                                    </div>
                                                </div> */}
                                            </span>
                                        </li>
                                        <li>
                                            SGST
                                            <span class="hightext">
                                                ₹{(tempCustomOrderObj?.Sgst) ? tempCustomOrderObj?.Sgst : 0}
                                                {/* <div class="form-group boxed ms-0 pb-0 pt-0">
                                                    <div class="input-group">
                                                        <span class="input-group-text ps-1 pe-0 bg-white" id="basic-addon1" style={{height: '25px', border: '1px solid lightgray', borderRadius: '6px 0px 0px 6px', fontSize: '14px'}}>₹</span>
                                                        <input type="number" class="form-control form-control-sm p-0 ps-1 text-center" onChange={(e) => {updateSgst(e.target.value)}} value={tempCustomOrderObj?.Sgst} placeholder="SGST" aria-label="Sgst" aria-describedby="basic-addon1" required style={{minHeight: '25px', height: '25px', width: '60px', borderLeft: 'none'}} />
                                                    </div>
                                                </div> */}
                                            </span>
                                        </li>
                                        <li>Total<span class="totaltext text-primary">₹{tempCustomOrderObj?.Total}</span></li>
                                        <li>Balance<span class="hightext">₹{tempCustomOrderObj?.BalanceAmount}</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-3">
                            <div class="col-md-12 d-flex justify-content-end">
                                <button class="btn btn-success" disabled={disableSubmitButton}>Place Order</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

const mapStateToProps = ({ data, auth }) => {
    const {
      businessData,
      items
    } = data
    const {
      authToken
    } = auth
    return {
      authToken,
      businessData,
      items
    }
  }
  
  const mapDispatchToProps = {
  }

export default connect(mapStateToProps, mapDispatchToProps)(CustomOrder)
