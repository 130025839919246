import React, { useEffect, useState } from 'react'
import Offcanvas from 'react-bootstrap/Offcanvas'
import Modal from 'react-bootstrap/Modal'
import { connect } from "react-redux"
import { hideItemSizeOffcanvas } from '../../../features/Offcanvas/itemSizeOffcanvasSlice'
import { updateCart, updateQuantity, updatePrice } from '../../../features/state/checkoutItemsSlice'
import { addToCart, cartOrderIndex, cartDraftIndex, cartDraftData, itemsDataUpdate } from '../../../features/Data/dataSlice' 
import { showItemSizeOffcanvas } from '../../../features/Offcanvas/itemSizeOffcanvasSlice'
import { updateStockQuantity, updateStock, updateStockPrice } from '../../../features/Data/stockSlice'
import { showAppToast } from '../../../features/utilities/appToastSlice'
import { showShopStatusModal } from '../../../features/Offcanvas/shopStatusModalSlice'
import Veg from '../../images/icon/veg.png'
import NonVeg from '../../images/icon/non-veg.png'

const ItemSizeOffcanvas = ({
    itemSizeOffcanvasVisible,
    itemValue,
    hideItemSizeOffcanvas,
    groupItemID,
    items,
    updateCart,
    updateQuantity,
    updatePrice,
    checkoutItemList,
    price,
    addToCart,
    draftData,
    draftIndex,
    odIndex,
    cartOrderIndex,
    cartDraftIndex, 
    cartDraftData,
    showItemSizeOffcanvas,
    updateStockQuantity, 
    updateStock, 
    updateStockPrice,
    stockItemList,
    stockPrice,
    showAppToast,
    showShopStatusModal,
    itemsDataUpdate
}) => {
    const [currentActiveItemSize, setCurrentActiveItemSize] = useState(null)
    const [stateName, setStateName] = useState('')
    const [activeItem, setActiveItem] = useState([])
    const [itemAmount, setItemAmount] = useState(0)
    const [filteredSize, setFilteredSize] = useState('')
    const page = window.location.pathname.split('/')
    useEffect(() => {
        setStateName(page[1])
    }, [page])

    useEffect(() => {
        if (items) {
            setFilteredSize(items.filter((item) => groupItemID === item.GroupItemID))
        }
    }, [groupItemID])

    useEffect(() => {
        if (itemSizeOffcanvasVisible === true) {
            setCurrentActiveItemSize(null)
            setItemAmount(0)
        }
    }, [itemSizeOffcanvasVisible])

    // const addToCheckoutItems = (item, pageName) => {
    //     if (pageName === 'NewOrder') {
    //         let tempItemObj = {
    //             "quantity": 1,
    //             "MRP":item.MRP,
    //             "Cost":item.Cost,
    //             "ItemTitle":item.ItemTitle,
    //             "Item_ID":item.ID,
    //             "Taxes":item.Taxes,
    //             "Image": item.ImageURL,
    //             "AlternateText": item.AlternateText,
    //             "Status": false
    //         }
    //         let tempOrderObj = {
    //             "DateTime": null,
    //             "Items": [],
    //             "OrderNumber": "",
    //             "Subtotal": 0,
    //             "Total":0,
    //             "TotalQty": 0,
    //             "TotalTax": {"cgst": 0, "sgst": 0},
    //             "Type":"",
    //             "OrderStatus": 0
    //         }
    //         if (draftData !== undefined) {
    //             let tempObj = JSON.parse(JSON.stringify(draftData))
    //             let addNewItem = false
    //             if (draftIndex !== null && draftIndex !== undefined && draftIndex !== '') {
    //                 if (tempObj[draftIndex].Status === 0) {
    //                     let addNewOrder = false
    //                     for (let i = 0; i < tempObj[draftIndex].Orders.length; i++) {
    //                         if (tempObj[draftIndex].Orders[i].OrderStatus === 1) {
    //                             addNewOrder = true
    //                         }
    //                     }
    //                     if (addNewOrder) {
    //                         tempObj[draftIndex].Orders.push(tempOrderObj)
    //                         cartOrderIndex({odIndex: tempObj[draftIndex].Orders.length - 1})
    //                         odIndex = tempObj[draftIndex].Orders.length - 1
    //                     }
    //                 }
    //                 if (tempObj[draftIndex].Orders[odIndex].Items.length === 0) {
    //                     addNewItem = true
    //                     tempObj[draftIndex].TotalQuantity = 0
    //                 } else {
    //                     for (let i = 0; i < tempObj[draftIndex].Orders[odIndex].Items.length; i++) {
    //                         if (tempObj[draftIndex].Orders[odIndex].Items[i].Item_ID === item.ID) {
    //                             tempObj[draftIndex].Orders[odIndex].Items[i].quantity = tempObj[draftIndex].Orders[odIndex].Items[i].quantity + 1
    //                             addNewItem = false
    //                             break 
    //                         } else {
    //                             addNewItem = true                    
    //                         }
    //                     }
    //                 }
    //                 if (addNewItem) {
    //                     tempObj[draftIndex].Orders[odIndex].Items.push(tempItemObj)
    //                 }
    //                 tempObj[draftIndex].TotalQuantity = tempObj[draftIndex].TotalQuantity + 1
    //                 tempObj[draftIndex].Amount = tempObj[draftIndex].Amount + parseInt(item.MRP)
    //                 tempObj[draftIndex].BalanceAmount = tempObj[draftIndex].BalanceAmount + parseInt(item.MRP)
    //                 tempObj[draftIndex].Status = -1
    //                 tempObj[draftIndex].OrderType = (tempObj[draftIndex].OrderType === '') ? 'Counter Sale' : tempObj[draftIndex].OrderType
    //                 addNewItem = false
    //             } else {
    //                 for (let i = 0; i < draftData.length; i++) {
    //                     if (draftData[i].Status === -2) {
    //                         tempObj[i].Status = -1
    //                         tempObj[i].OrderType = 'Counter Sale'
    //                         tempObj[i].TotalQuantity = 1
    //                         tempObj[i].Orders[0].Items.push(tempItemObj)
    //                         cartDraftIndex({draftIndex: i})
    //                         cartOrderIndex({odIndex: 0})
    //                         tempObj[i].Amount = tempObj[i].Amount + parseInt(item.MRP)
    //                         tempObj[i].BalanceAmount = tempObj[i].BalanceAmount + parseInt(item.MRP)
    //                         break
    //                     }
    //                 }
    //             }
    //             cartDraftData({draftData: tempObj})
    //             // if (pageName === 'NewOrder') {
    //                 let itemList = [...checkoutItemList]
    //                 // let checkoutItemIndex = itemList.findIndex(e=>e.item.ID === item.ID)
    //                 let checkoutItemIndex = -1
    //                 for (let i = 0; i < itemList.length; i++) {
    //                     if (parseInt(itemList[i].item.ID) === parseInt(item.ID) && itemList[i].OrderIndex === odIndex) {
    //                         checkoutItemIndex = i
    //                         break
    //                     }
    //                 }
    //                 if(checkoutItemIndex===-1){
    //                     itemList.push({
    //                         item : item, 
    //                         quantity : 1,
    //                         OrderStatus : 0,
    //                         OrderIndex: (odIndex === null ? 0 : odIndex)
    //                     })
    //                     updateCart(itemList)
    //                 } else {
    //                     updateQuantity({checkoutItemIndex:checkoutItemIndex,quantity:Number(itemList[checkoutItemIndex]?.quantity)+1})
    //                     itemList.splice(checkoutItemIndex,1,{item : item, quantity : itemList[checkoutItemIndex].quantity+1, OrderStatus: 0, OrderIndex: (odIndex === null ? 0 : odIndex)})
    //                     updateCart(itemList)
    //                 }
    //                 updatePrice({
    //                     subtotal : Number(price.subtotal)+Number(item.MRP),
    //                     cgst : 0,
    //                     sgst : 0})
    //                 // updateCart(itemList.reverse())
    //                 addToCart({added: true})
    //             // }   
    //         }
    //     } else if (pageName === 'ManageStock') {
    //         // if (item?.Sizes.length === 0) {
    //             setActiveItem(item.ID)
    //             let itemList = [...stockItemList]
    //             let stockItemIndex = -1
    //             for (let i = 0; i < itemList.length; i++) {
    //                 if (parseInt(itemList[i].item.ID) === parseInt(item.ID)) {
    //                     stockItemIndex = i
    //                     break
    //                 }
    //             }
    //             if(stockItemIndex===-1){
    //                 itemList.push({
    //                     item : item, 
    //                     quantity : 1,
    //                     OrderStatus : 0,
    //                     OrderIndex: (odIndex === null ? 0 : odIndex)
    //                 })
    //                 updateStock(itemList)
    //             } else {
    //                 updateStockQuantity({stockItemIndex:stockItemIndex,quantity:Number(itemList[stockItemIndex]?.quantity)+1})
    //                 itemList.splice(stockItemIndex,1,{item : item, quantity : itemList[stockItemIndex].quantity+1, OrderStatus: 0, OrderIndex: (odIndex === null ? 0 : odIndex)})
    //                 updateStock(itemList)
    //             }
    //             updateStockPrice({
    //                 subtotal : Number(stockPrice.subtotal)+Number(item.MRP),
    //                 cgst : 0,
    //                 sgst : 0})
    //         // } else {
    //         //     showItemSizeOffcanvas({itemSizeOffcanvasVisible: true, itemValue: item})
    //         // }
    //     }
    // }

    const calculateStock = (itemList, item) => {
        itemList.map((v,k) => {
            if (v.ID === item.GroupItemID) {
                if (v.Sizes.length > 0) {
                    v.Sizes.map((v1, k1) => {
                        if (v1.ID === item.ID) {
                            v1.SalesQty = parseInt(v1.SalesQty) - 1 
                            showItemSizeOffcanvas({itemSizeOffcanvasVisible: true, itemValue: v})
                        }
                    })
                }
            }
        })
        itemsDataUpdate({items: itemList})
    }
    const addToCheckoutItems = (item, pageName) => {
        let tempItemList = JSON.parse(JSON.stringify(items))
        if (pageName === 'NewOrder') {
            if (parseInt(item.SalesQty) === 0 && item.AllowNegativeStock === 'f') {
                showAppToast({ toastMessage:'OUT OF STOCK', background: 'danger', timeout: '1000', icon:'close-circle-outline', position: 'toast-center', isVisible: true })
            } else {
                const currentShopStatus = localStorage.getItem('shopStatus')
                if (currentShopStatus === 'Close') {
                    showShopStatusModal({shopStatusModalVisible: true, liveShopStatus: ''})
                } else {
                    setActiveItem(item.ID)
                    let tempItemObj = {
                        "quantity": 1,
                        "MRP":item.MRP,
                        "Cost":item.Cost,
                        "ItemTitle":item.ItemTitle,
                        "Item_ID":item.ID,
                        "Taxes":item.Taxes,
                        "Image": item.ImageURL,
                        "AlternateText": item.AlternateText,
                        "Status": false,
                        "FoodType": item.FoodType
                    }
                    let tempOrderObj = {
                        "DateTime": null,
                        "Items": [],
                        "OrderNumber": "",
                        "Subtotal": 0,
                        "Total":0,
                        "TotalQty": 0,
                        "TotalTax": {"cgst": 0, "sgst": 0},
                        "Type":"",
                        "OrderStatus": 0
                    }
                    let tempObj = JSON.parse(JSON.stringify(draftData))
                    let addNewItem = false
                    if (draftIndex !== null && draftIndex !== undefined && draftIndex !== '') {
                        if (tempObj[draftIndex].Status === 0) {
                            let addNewOrder = false
                            for (let i = 0; i < tempObj[draftIndex].Orders.length; i++) {
                                if (tempObj[draftIndex].Orders[i].OrderStatus === 1) {
                                    addNewOrder = true
                                }
                            }
                            if (addNewOrder) {
                                tempObj[draftIndex].Orders.push(tempOrderObj)
                                cartOrderIndex({odIndex: tempObj[draftIndex].Orders.length - 1})
                                odIndex = tempObj[draftIndex].Orders.length - 1
                            }
                        }
                        if (tempObj[draftIndex].Orders[(odIndex) ? odIndex : 0].Items.length === 0) {
                            addNewItem = true
                            tempObj[draftIndex].TotalQuantity = 0
                        } else {
                            for (let i = 0; i < tempObj[draftIndex].Orders[(odIndex) ? odIndex : 0].Items.length; i++) {
                                if (tempObj[draftIndex].Orders[(odIndex) ? odIndex : 0].Items[i].Item_ID === item.ID) {
                                    tempObj[draftIndex].Orders[(odIndex) ? odIndex : 0].Items[i].quantity = tempObj[draftIndex].Orders[(odIndex) ? odIndex : 0].Items[i].quantity + 1
                                    addNewItem = false
                                    break 
                                } else {
                                    addNewItem = true                    
                                }
                            }
                        }
                        if (addNewItem) {
                            tempObj[draftIndex].Orders[(odIndex) ? odIndex : 0].Items.push(tempItemObj)
                        }
                        tempObj[draftIndex].TotalQuantity = tempObj[draftIndex].TotalQuantity + 1
                        tempObj[draftIndex].Amount = tempObj[draftIndex].Amount + parseInt(item.MRP)
                        tempObj[draftIndex].BalanceAmount = tempObj[draftIndex].BalanceAmount + parseInt(item.MRP)
                        tempObj[draftIndex].Status = -1
                        tempObj[draftIndex].OrderType = (tempObj[draftIndex].OrderType === '') ? 'Counter Sale' : tempObj[draftIndex].OrderType
                        // tempObj[draftIndex].DiscountType = (discountType === 'percentage') ? totalDiscount + '%' : totalDiscount 
                        // tempObj[draftIndex].DiscountedAmount = discountedAmount
                        // tempObj[draftIndex].TotalDiscount = tempObj[draftIndex].Amount - discountedAmount
                        addNewItem = false
                    } else {
                        let draftIndexSet = false
                        for (let i = 0; i < draftData.length; i++) {
                            if (draftData[i].Status === -2) {
                                draftIndexSet = true
                                tempObj[i].Status = -1
                                tempObj[i].OrderType = 'Counter Sale'
                                tempObj[i].TotalQuantity = 1
                                tempObj[i].Orders[0].Items.push(tempItemObj)
                                cartDraftIndex({draftIndex: i})
                                cartOrderIndex({odIndex: 0})
                                tempObj[i].Amount = tempObj[i].Amount + parseInt(item.MRP)
                                tempObj[i].BalanceAmount = tempObj[i].BalanceAmount + parseInt(item.MRP)
                                break
                            }
                        }
                        if (!draftIndexSet) {
                            let defObj = {
                                "OfflineInvoiceNumber": '',
                                "Orders":[{
                                    "Items":[],
                                    "TotalQty":0,
                                    "Subtotal":0,
                                    "TotalTax":{
                                        "cgst": 0,
                                        "sgst":0
                                    },
                                    "Total":0,
                                    "Type":'',
                                    "DateTime":null,
                                    "OrderNumber":'',
                                    "OrderStatus": 0
                                }],
                                "Currency":"INR",
                                "Phone":null,
                                "Date": null,
                                "UserName":"",
                                "BalanceAmount":0,
                                "PaymentMethod":"",
                                "OrderType": '',
                                "Amount": 0,
                                "TransactionDate": null,
                                "Customer_ID": "",
                                "Status": -2
                            }
                            tempObj = JSON.parse(JSON.stringify(draftData))
                            tempObj.push(defObj)
                            tempObj[draftData.length].Status = -1
                            tempObj[draftData.length].OrderType = 'Counter Sale'
                            tempObj[draftData.length].TotalQuantity = 1
                            tempObj[draftData.length].Orders[0].Items.push(tempItemObj)
                            cartDraftIndex({draftIndex: draftData.length})
                            cartOrderIndex({odIndex: 0})
                            tempObj[draftData.length].Amount = tempObj[draftData.length].Amount + parseInt(item.MRP)
                            tempObj[draftData.length].BalanceAmount = tempObj[draftData.length].BalanceAmount + parseInt(item.MRP)
                            // tempObj[draftData.length].DiscountType = (discountType === 'percentage') ? totalDiscount + '%' : totalDiscount 
                            // tempObj[draftData.length].DiscountedAmount = discountedAmount
                            // tempObj[draftData.length].TotalDiscount = tempObj[draftData.length].Amount - discountedAmount
                        }
                    }
                    cartDraftData({draftData: tempObj})
                    if (pageName === 'NewOrder') {
                            let itemList = [...checkoutItemList]
                            // let checkoutItemIndex = itemList.findIndex(e=>e.item.ID === item.ID)
                            let checkoutItemIndex = -1
                            for (let i = 0; i < itemList.length; i++) {
                                if (parseInt(itemList[i].item.ID) === parseInt(item.ID) && itemList[i].OrderIndex === odIndex) {
                                    checkoutItemIndex = i
                                    break
                                }
                            }
                            if(checkoutItemIndex===-1){
                                itemList.push({
                                    item : item, 
                                    quantity : 1,
                                    OrderStatus : 0,
                                    OrderIndex: (odIndex === null ? 0 : odIndex)
                                })
                                updateCart(itemList)
                            } else {
                                updateQuantity({checkoutItemIndex:checkoutItemIndex,quantity:Number(itemList[checkoutItemIndex]?.quantity)+1})
                                itemList.splice(checkoutItemIndex,1,{item : item, quantity : itemList[checkoutItemIndex].quantity+1, OrderStatus: 0, OrderIndex: (odIndex === null ? 0 : odIndex)})
                                updateCart(itemList)
                            }
                            updatePrice({
                                subtotal : Number(price.subtotal)+Number(item.MRP),
                                cgst : 0,
                                sgst : 0})
                            // updateCart(itemList.reverse())
                            addToCart({added: true})
                    }
                    calculateStock(tempItemList, item)
                }
            }
        } else if (pageName === 'ManageStock') {
                setActiveItem(item.ID)
                let itemList = [...stockItemList]
                let stockItemIndex = -1
                for (let i = 0; i < itemList.length; i++) {
                    if (parseInt(itemList[i].item.ID) === parseInt(item.ID)) {
                        stockItemIndex = i
                        break
                    }
                }
                if(stockItemIndex===-1){
                    itemList.push({
                        item : item, 
                        quantity : 1,
                        OrderStatus : 0,
                        OrderIndex: (odIndex === null ? 0 : odIndex)
                    })
                    updateStock(itemList)
                } else {
                    updateStockQuantity({stockItemIndex:stockItemIndex,quantity:Number(itemList[stockItemIndex]?.quantity)+1})
                    itemList.splice(stockItemIndex,1,{item : item, quantity : itemList[stockItemIndex].quantity+1, OrderStatus: 0, OrderIndex: (odIndex === null ? 0 : odIndex)})
                    updateStock(itemList)
                }
                updateStockPrice({
                    subtotal : Number(stockPrice.subtotal)+Number(item.MRP),
                    cgst : 0,
                    sgst : 0})
        }
    }

    const selectItemSize = (item, k) => {
        if (stateName === 'newOrder') {
            setCurrentActiveItemSize(k)
            setItemAmount(item.MRP)
            addToCheckoutItems(item, 'NewOrder')
        } else if (stateName === 'manageStock') {
            addToCheckoutItems(item, 'ManageStock')
        }
    }
    return (
        <div>
            <Modal show={itemSizeOffcanvasVisible} centered backdrop="static" onHide={() => {hideItemSizeOffcanvas()}}>
                <Modal.Header closeButton style={{minHeight: '36px', zIndex: 1, padding: '8px', paddingRight: '15px'}}>
                    <div class="row w-100">
                        <div class="col-2 col-md-1 d-flex align-items-center">
                            <img src={(itemValue?.ImageURL === '' || itemValue?.ImageURL === null || itemValue?.ImageURL === undefined) ? "https://us.123rf.com/450wm/viktorijareut/viktorijareut1503/viktorijareut150300119/37649514-grey-tray-kitchenware-trey-isolated-tray-vector-closed-tray.jpg?ver=6" : itemValue?.ImageURL } alt="image" class="image rounded-circle me-1" style={{width: '35px'}} />
                        </div>
                        <div class="col-10 col-md-10 ms-md-1 d-flex justify-content-between align-items-center">
                            <h3 class="mb-0">
                                <img src={(itemValue?.FoodType === 'veg') ? Veg : NonVeg} style={{width: '14px', paddingBottom: '2px', marginRight: '2px'}} />
                                {itemValue?.ItemTitle} <br />
                                <span class="badge bg-primary" style={{height: '18px'}}> {itemValue?.Sizes?.length} Size(s)</span>
                            </h3>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body class="modal-body p-1">
                    <div class="section mt-1 p-0">
                        <div class="row w-100 m-0">
                            <div class="col-12 col-md-12">
                                {/* <ul class="d-flex p-0 flex-wrap mb-0" style={{listStyleType: 'none'}}> */}
                                    {/* {itemValue?.Sizes.map((v,k) => {
                                        return <li class="me-1 mb-1" onClick={() => {selectItemSize(v,k)}}>
                                            <div class={(currentActiveItemSize === k) ? "chip bg-primary border-primary text-white" : "chip bg-transparent border-secondary"}>
                                                <span class="chip-label text-nowrap">{v.ItemTitle}</span>
                                                <span class="chip-label text-nowrap">₹{v.MRP}</span>
                                                    <div class="badge w-auto bg-primary me-1" style={{fontSize: '8px', height: '14px'}}><ion-icon name="cart-outline" style={{paddingBottom: '2px', paddingRight: '2px'}}></ion-icon> {v.SalesQty}</div>
                                            </div>
                                        </li>
                                    })} */}
                                {/* </ul> */}
                                <div class="row">
                                    {itemValue?.Sizes.map((v,k) => {
                                        return <div class="col-6 col-md-6 mb-1">
                                            <div class={(currentActiveItemSize === k) ? "btn btn-block btn-lg bg-primary border-primary text-white p-0" : "btn btn-block btn-lg bg-transparent border-secondary p-0"} onClick={() => {selectItemSize(v,k)}} style={{height: '70px'}}>
                                                <div class="row w-100">
                                                    <div class="col-8 col-md-9 d-flex align-items-center">
                                                        <img src={(v.FoodType === 'veg') ? Veg : NonVeg} style={{width: '14px', paddingBottom: '2px', marginRight: '2px'}} />
                                                        <div class="text-start" style={{fontSize: '14px'}}>{v.ItemTitle}</div>
                                                    </div>
                                                    <div class="col-4 col-md-3 text-end">
                                                        <div class="badge bg-primary" style={{fontSize: '12px', height: '16px'}}><ion-icon name="cart-outline" class="fs-10 me-0" style={{paddingRight: '2px'}}></ion-icon> {v.SalesQty}</div>
                                                        <div class="fs-6 fw-bold">₹{v.MRP}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}

const mapStateToProps = ({ itemSize, data, checkoutItems, stock }) => {
    const {
        itemSizeOffcanvasVisible,
        itemValue
    } = itemSize
    const {
        checkoutItemList,
        price
    } = checkoutItems
    const {
        items,
        draftData,
        draftIndex,
        odIndex
    } = data
    const {
        stockItemList,
        stockPrice
    } = stock
    return {
        itemSizeOffcanvasVisible,
        itemValue,
        items,
        checkoutItemList,
        price,
        draftData,
        draftIndex,
        odIndex,
        stockItemList,
        stockPrice
    }
  }
  
  const mapDispatchToProps = {
    hideItemSizeOffcanvas,
    updateCart,
    updateQuantity,
    updatePrice,
    addToCart,
    cartOrderIndex,
    cartDraftIndex, 
    cartDraftData,
    showItemSizeOffcanvas,
    updateStockQuantity, 
    updateStock, 
    updateStockPrice,
    showAppToast,
    showShopStatusModal,
    itemsDataUpdate
  }

export default connect(mapStateToProps, mapDispatchToProps)(ItemSizeOffcanvas)