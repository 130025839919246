import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    itemProfileVisible: false,
    itemProfileData: null
}

export const itemProfileOffcanvasSlice = createSlice({
    name: 'itemProfile',
    initialState,
    reducers: {
      showItemProfileOffcanvas: (state, action) => {
        const {
            itemProfileVisible
        } = action.payload
        state.itemProfileVisible = itemProfileVisible
      },
      hideItemProfileOffcanvas: (state) => {
        state.itemProfileVisible = false
      },
      setItemProfileDetails: (state, action) => {
        const {
            itemProfileData
        } = action.payload
        state.itemProfileData = itemProfileData
      },
    }
})

export const { showItemProfileOffcanvas, hideItemProfileOffcanvas, setItemProfileDetails } = itemProfileOffcanvasSlice.actions;


export default itemProfileOffcanvasSlice.reducer