import React, { useEffect } from 'react'
import Toast from 'react-bootstrap/Toast'
import { connect } from "react-redux"
import { hideAppToast } from '../../../features/utilities/appToastSlice'

const AppToast = ({
  background,
  message,
  show,
  icon,
  hideAppToast,
  position,
  isVisible,
  toastMessage
}) => {
  useEffect(() => {
    if (isVisible) {
      setTimeout(() => {
        hideAppToast()
      }, 2000); 
    }
  }, [isVisible])

  return (
    <div>
      <Toast show={isVisible} autohide delay={2000} bg={background} className={(position === 'toast-center') ? "toast-box toast-center" : "toast-box rounded-0 p-0 toast-center"} style={{display: 'inherit', zIndex: 9999}}>
        <Toast.Body className="in">
          <ion-icon name={icon} className="bg-success"></ion-icon>
          <div className="text text-white">
              {message}
              {toastMessage}
          </div>
          <button type="button" className="btn btn-sm btn-text-light close-button" onClick={hideAppToast}>CLOSE</button>
        </Toast.Body>
      </Toast>
    </div>
  )
}

const mapStateToProps = ({ appToast }) => {
  const {
    background,
    message,
    show,
    icon,
    position,
    isVisible,
    toastMessage
  } = appToast
  return {
    background,
    message,
    show,
    icon,
    position,
    isVisible,
    toastMessage
  }
}

const mapDispatchToProps = {
  hideAppToast
}

export default connect(mapStateToProps, mapDispatchToProps)(AppToast)
