import React, { useState, useEffect } from 'react'
import Offcanvas from 'react-bootstrap/Offcanvas'
import { useNavigate } from 'react-router'
import { connect } from "react-redux"
import { hideQuickAddOffcanvas } from '../../../features/Offcanvas/quickAddOffcanvasSlice'
import { updateQuantity, updateCart, updatePrice } from '../../../features/state/checkoutItemsSlice'
import { showItemSizeOffcanvas } from '../../../features/Offcanvas/itemSizeOffcanvasSlice'
import { addToCart } from '../../../features/Data/dataSlice'
import MessageToast from '../../Elements/Notification/MessageToast'
import Veg from '../../images/icon/veg.png'
import NonVeg from '../../images/icon/non-veg.png'

const QuickAddOffcanvas = ({
    quickAddVisible,
    hideQuickAddOffcanvas,
    items,
    checkoutItemList,
    price,
    updateQuantity,
    updateCart,
    updatePrice,
    showItemSizeOffcanvas,
    addToCart
}) => {
    const search = window.location.pathname.split('/')
    const [pageName, setPageName] = useState('')
    let navigate = useNavigate()
    useEffect(() => {
        if (search[1] === 'newOrder') {
            setPageName('NewOrder')
        }
    }, [search])
    const addToCheckoutItems = (item, pageName) => {
        if (pageName === 'NewOrder') {
                let itemList = [...checkoutItemList];
                let checkoutItemIndex = itemList.findIndex(e=>e.item.ID === item.ID)
                if(checkoutItemIndex===-1){
                    itemList.push({item : item, quantity : 1})
                }
                else{
                    updateQuantity({checkoutItemIndex:checkoutItemIndex,quantity:Number(itemList[checkoutItemIndex]?.quantity)+1})
                    itemList.splice(checkoutItemIndex,1,{item : item, quantity : itemList[checkoutItemIndex].quantity+1})
                }
                updatePrice({
                    subtotal : Number(price.subtotal)+Number(item.MRP),
                    cgst : 0,
                    sgst : 0})
                updateCart(itemList)
                addToCart({added: true})
        } else if (pageName === 'OrderDetail') {
            let itemList = [...checkoutItemList];
            let checkoutItemIndex = itemList.findIndex(e=>e.item.ID === item.ID)
            if(checkoutItemIndex===-1){
                itemList.push({item : item, quantity : 1})
            }
            else{
                updateQuantity({checkoutItemIndex:checkoutItemIndex,quantity:Number(itemList[checkoutItemIndex]?.quantity)+1})
                itemList.splice(checkoutItemIndex,1,{item : item, quantity : itemList[checkoutItemIndex].quantity+1})
            }
            updatePrice({
                subtotal : Number(price.subtotal)+Number(item.MRP),
                cgst : 0,
                sgst : 0})
            updateCart(itemList)
        }
        
    }
    const handleItemDetailProfile = () => {
        navigate('/itemProfile')
    }
    const handleItemSize = (e, itemName, groupID) => {
        e.stopPropagation()
        showItemSizeOffcanvas({itemSizeOffcanvasVisible: true, itemName: itemName, groupItemID: groupID})
        hideQuickAddOffcanvas()
    }
    return (
        <div>
            <Offcanvas show={quickAddVisible} placement={'bottom'} style={{height: 'auto', transition: '0.4s'}} onHide={() => {hideQuickAddOffcanvas()}}>
                <Offcanvas.Header closeButton style={{minHeight: '36px'}}>
                    <Offcanvas.Title style={{display: 'flex', alignItems: 'center'}}><ion-icon name="flash-outline" class="text-secondary"></ion-icon>&nbsp;Quick Add</Offcanvas.Title>
                    <MessageToast os={false}></MessageToast>
                </Offcanvas.Header>
                <Offcanvas.Body class="offcanvas-body p-0">
                    {(items !== null && items !== undefined) &&
                        <div class="row w-100 m-0">
                            {items.map(value => {
                                return <>
                                {value.QuickAdd === 't' &&
                                    <>
                                        <div class="col-4 col-md-2">
                                            <div class="card product-card shadow-none" onClick={(pageName === 'NewOrder') ? ()=>addToCheckoutItems(value, pageName) : handleItemDetailProfile} style={{backgroundColor: 'transparent'}}>
                                                <div class="card-body">
                                                    <div style={{position: 'relative'}}>
                                                        {(value !== null || value !== undefined) && (value.ImageURL === null || value.ImageURL === undefined || value.ImageURL === '') &&
                                                            <img src="https://us.123rf.com/450wm/viktorijareut/viktorijareut1503/viktorijareut150300119/37649514-grey-tray-kitchenware-trey-isolated-tray-vector-closed-tray.jpg?ver=6" class="image" alt="product image" style={{objectFit:'cover'}} />
                                                        }
                                                        {value && value.ImageURL &&
                                                            <img src={value.ImageURL && value.ImageURL} class="image" alt="product image" style={{objectFit:'cover'}} />
                                                        }
                                                        {value && value.FoodType === 'veg' &&
                                                            <img src={Veg} width="18px" style={{position: 'absolute', top: 5, right: 5, backgroundColor: 'white'}} />
                                                        }
                                                        {value && value.FoodType === 'non-veg' &&
                                                            <img src={NonVeg} width="18px" style={{position: 'absolute', top: 5, right: 5, backgroundColor: 'white'}} />
                                                        }
                                                    </div>
                                                    <h2 class="title d-flex justify-content-between" style={{fontSize: '13px'}}>
                                                    <a href="javascript:void(0)">
                                                        <div class="fw-bolder" style={{fontSize: '13px'}}>{value.ItemTitle && value.ItemTitle} <small>{value.Subtitle && value.Subtitle}</small></div>
                                                        <div class="badge position-absolute w-auto rounded-0" style={{fontSize: '8px',top: '20px', right: '8px', backgroundColor: value.labelColor && value.labelColor}}>{value.label && value.label}</div>
                                                    </a>
                                                    </h2>
                                                    <div class="row">
                                                        <div class="col-6 col-md-6 mb-1">
                                                            <p class="price mb-0 text-secondary" style={{fontSize: '13px'}}>₹{value.MRP && value.MRP}</p>
                                                        </div>
                                                        {value.GroupItemID &&
                                                            <div class="col-6 col-md-6 d-flex justify-content-md-end">
                                                                <button class="px-1 fs-10 btn btn-text btn-sm d-flex align-value-center ps-1 pe-1" style={{height: '18px'}} onClick={(pageName === 'NewOrder') ? (e) => {handleItemSize(e, (value.GroupItemID && value.GroupItemID), value.GroupItemID && value.GroupItemID)} : handleItemDetailProfile}> <ion-icon name="color-filter-outline" class="text-secondary"></ion-icon></button>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                                </>
                            })}
                        </div>
                    }
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    )
}

const mapStateToProps = ({ quickAdd, data, checkoutItems }) => {
    const {
        quickAddVisible,
    } = quickAdd
    const {
        items
    } = data
    const {
        checkoutItemList,
        price
    } = checkoutItems
    return {
        quickAddVisible,
        items,
        checkoutItemList,
        price
    }
  }
  
  const mapDispatchToProps = {
    hideQuickAddOffcanvas,
    updateQuantity, 
    updateCart, 
    updatePrice,
    showItemSizeOffcanvas,
    addToCart
  }

export default connect(mapStateToProps, mapDispatchToProps)(QuickAddOffcanvas)