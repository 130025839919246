import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { showMainMenuOffcanvas } from '../../../features/Offcanvas/mainMenuOffcanvasSlice'
import SmallLogo from '../../images/allpos_white.svg'
import OrderTypeModal from '../Sidebar/OrderTypeModal'
import { showOrderTypeModal } from '../../../features/Offcanvas/orderTypeModalSlice'
import { showCheckoutOffcanvas } from '../../../features/Offcanvas/checkoutOffcanvasSlice'
import { setCartDraftID,setCheckoutFlagDraft, cartDraftIndex, cartOrderIndex, checkoutItemType, setActiveDraftCart, runUpdatedCartFunction, setDiscountDetails } from '../../../features/Data/dataSlice'
import { updateCart, tempPrice, resetPrice, updatePrice } from '../../../features/state/checkoutItemsSlice'
import { showDraftCartListModal } from '../../../features/Offcanvas/draftCartListModalSlice'
import { setStockAction } from '../../../features/Data/stockSlice'

const Header2 = ({
    showMainMenuOffcanvas,
    showOrderTypeModal,
    draftData,
    showCheckoutOffcanvas,
    setCartDraftID,
    setCheckoutFlagDraft,
    renderHeaderDraft,
    updateCart,
    tempPrice,
    cartDraftIndex,
    cartOrderIndex,
    resetPrice,
    odIndex,
    checkoutItemType,
    updatePrice,
    showDraftCartListModal,
    setActiveDraftCart,
    activeDraftID,
    fullInvoice,
    updateCartFlag,
    updateCartKey,
    runUpdatedCartFunction,
    setDiscountDetails,
    setStockAction,
    stockActionColor
}) => {
    const [stateName, setStateName] = useState('')
    const [cartDraftList, setCartDraftList] = useState(null)
    const search = useLocation().search
    useEffect(() => {
        const stockAction = new URLSearchParams(search).get('action')
        if (stockAction === 'addStock') {
            setStockAction({stockActionColor: '#fe9500', stockActionType: 'addStock'})
        } else if (stockAction === 'removeStock') {
            setStockAction({stockActionColor: '#ec4433', stockActionType: 'removeStock'})
        } else if (stockAction === 'transferOut') {
            setStockAction({stockActionColor: '#fa758d', stockActionType: 'transferOut'})
        } else if (stockAction === 'request') {
            setStockAction({stockActionColor: '#6c7c94', stockActionType: 'request'})
        }
    }, [search])
    const page = window.location.pathname.split('/')
    useEffect(() => {
        setStateName(page[1])
    }, [page])
    let navigate = useNavigate()
    const handleMainMenuOffcanvas = () => {
        showMainMenuOffcanvas({mainMenuOffcanvasVisible: true})
    }
    useEffect(() => {
        setCartDraftList(draftData)
    }, [draftData, renderHeaderDraft])
    const updateDraftCart = (currentCartData, key) => {
        setActiveDraftCart({activeDraftID: key})
        navigate('/newOrder')
        if (currentCartData?.Status !== -2) {
            let tempCurrentCartData = []
            let tempCurrentCartDataObj = JSON.parse(JSON.stringify(currentCartData))
            tempCurrentCartDataObj?.Orders.map((val, key) => {
                tempCurrentCartDataObj?.Orders[key]?.Items?.map((v,k) => {
                    v.ImageURL = v.Image
                    v.ID = v.Item_ID
                    v.MRP = parseInt(v.MRP)
                    tempCurrentCartData.push({
                        'item' : v,
                        'quantity' : v.quantity,
                        'OrderStatus' : val.OrderStatus,
                        'OrderIndex' : key
                    })
                    setCartDraftID({cartDraftID: key})
                })
            })
            updateCart(tempCurrentCartData)
            tempPrice({tempSubtotal: currentCartData.Amount, tempBalance: currentCartData.BalanceAmount})
            setDiscountDetails({discountType: currentCartData.DiscountType, discountedAmount: currentCartData.DiscountedAmount, totalDiscount: currentCartData.TotalDiscount})
        }
        if (currentCartData?.Status === -1 || currentCartData?.Status === 0) {
            cartOrderIndex({odIndex: 0})
        }
        cartDraftIndex({draftIndex: key})
        checkoutItemType({type: currentCartData?.OrderType})
        updatePrice({
            subtotal : currentCartData?.Amount,
            cgst : 0,
            sgst : 0})
    }
    useEffect(() => {
        if (updateCartFlag === 'activeOrder') {
            updateDraftCart(fullInvoice, updateCartKey)
            setTimeout(() => {
                runUpdatedCartFunction({updateCartFlag: '', updateCartKey: null})
            }, 500);
        }
    }, [updateCartFlag])
    const handleCartAction = (actionType, key, value) => {
        navigate('/newOrder')
        setActiveDraftCart({activeDraftID: key})
        if (actionType === 'add') {
            updateCart([])
            tempPrice({tempSubtotal: 0, tempBalance: 0})
            cartDraftIndex({draftIndex: key})
            cartOrderIndex({odIndex: 0})
            resetPrice('')
            setDiscountDetails({discountType: null, discountedAmount: 0, totalDiscount: 0})
        }
        if (value.OrderType === '' || value.OrderType === null || value.OrderType === undefined) {
            showOrderTypeModal({orderTypeModalVisible: true, reference: 'NewOrder'})
        }
    }
    return (
        <>
            <OrderTypeModal></OrderTypeModal>
            <div class={(stateName !== 'manageStock') ? "appHeader bg-primary text-light" : "appHeader text-light"} style={{zIndex: 1000, backgroundColor: (stateName === 'manageStock') ? stockActionColor : '' }}>
                <div class="left d-none d-sm-flex">
                    <a href="javascript:void(0)" onClick={handleMainMenuOffcanvas} class="headerButton p-0">
                        <ion-icon name="menu-outline"></ion-icon>
                    </a>
                    <img src={SmallLogo} alt="allpos-logo" width='100px' style={{maxWidth: '65px'}} />
                </div>
                <div class="pageTitle">
                </div>
                <div class="right custom-splide d-flex justify-content-end custom-header-width">
                    <div class="infinite-menu text-end h-100">
                        <div class="d-flex align-items-center" style={{height: 'inherit'}}>
                            <a href="javascript:void(0)" class="item w-auto d-sm-none">
                                <span class="p-0" style={{height: 'auto', padding: '5px 12px', position: 'relative'}}>
                                    <img src={SmallLogo} alt="allpos-logo" width='100px' style={{maxWidth: '45px'}} />     
                                </span>    
                            </a>
                            {stateName !== 'manageStock' &&
                                <>
                                    {(cartDraftList !== null && cartDraftList !== undefined && cartDraftList.length > 0) &&
                                        <>
                                            {cartDraftList.map((value,key) => {
                                                let currentStatus = value.Status
                                                let borderColor = 'border-secondary'
                                                let bgColor = ''
                                                var varDate = new Date('2023-11-01').toISOString().slice(0, 10);
                                                var today = new Date().toISOString().slice(0, 10);
                                                if (currentStatus === -1 && value.TotalQuantity > 0) {
                                                    borderColor = 'border-secondary'
                                                    bgColor = 'btn-light'
                                                } else if (currentStatus === 0 && value.TotalQuantity > 0) {
                                                    borderColor = 'allpos-border-secondary'
                                                    bgColor = 'allpos-bg-secondary'
                                                }
                                                return <>
                                                    {key <= 4 &&
                                                        <>
                                                            {/* {(value.Date === null || value.Date === '' || value.Date === undefined || value.Date >= varDate) &&
                                                                <> */}
                                                                    {value.Status !== 1 &&
                                                                        <a href="javascript:void(0)" class="item w-auto" onClick={(value.Status === -2) ? () => {handleCartAction('add', key, value)} : () => {updateDraftCart(value, key)}}>
                                                                            <span class={(activeDraftID !== key) ? `btn btn-block ${borderColor} btn-sm text-white ${bgColor}` : `btn btn-block border-success btn-sm text-white ${bgColor}`} style={{height: 'auto', padding: '5px 12px', position: 'relative'}}>
                                                                                <div class="bg-primary d-flex justify-content-center align-items-center fs-8" style={{borderRadius: '100%', height: '25px', width: '25px', marginRight: '2px'}}>
                                                                                    {/* {key + 1} */}
                                                                                    {String.fromCharCode(64 + (key + 1))}
                                                                                </div>
                                                                                <span class="fs-10">{value.OrderType}</span>
                                                                                {value.TotalQuantity > 0 &&
                                                                                    <span class="badge bg-white text-dark" style={{position: 'absolute', top: '-8px', right: '-6px', backgroundColor: '#e3c20e'}}>{value.TotalQuantity}</span>
                                                                                }
                                                                            </span>
                                                                        </a>
                                                                    }
                                                                {/* </>
                                                            } */}
                                                        </>
                                                    }
                                                </>
                                            })}
                                            <a href="javascript:void(0)" class="item w-auto" onClick={() => {showDraftCartListModal({draftCartListModalVisible: true})}}>
                                                <span class={'btn btn-block btn-sm text-white fs-10 p-0'} style={{height: 'auto', padding: '5px 12px', position: 'relative'}}>
                                                    <ion-icon name="apps-outline" class="m-0 fs-1"></ion-icon>
                                                </span>
                                            </a>
                                        </>
                                    }
                                </>
                            }
                            {stateName === 'manageStock' &&
                                <>
                                    <Link to="/items?tab=inwardOutward" class="item w-auto">
                                        <span class={'btn btn-block border-secondary btn-sm text-white'} style={{height: 'auto', padding: '5px 12px', position: 'relative', minHeight: '40px'}}>
                                            <span class="fs-10">Stock Orders</span>
                                        </span>
                                    </Link>
                                </>
                            }
                        </div>
                    </div>
                    {/* <Splide
                        options={{
                            type: "slide",
                            gap: 6,
                            arrows: false,
                            trimSpace: false,
                            autoWidth: true,
                            pagination: false
                        }}
                        class="custom-splide"
                    >
                        <SplideSlide class="splide-slide-1">
                            <img src={SmallLogo} alt="allpos-logo" width='100px' style={{maxWidth: '45px'}} />
                        </SplideSlide>
                        {(cartDraftList !== null && cartDraftList !== undefined && cartDraftList.length > 0) &&
                            <>
                                {cartDraftList.map((value,key) => {
                                    return <SplideSlide>
                                        <span class={(value.ItemCount > 0) ? 'btn border-white btn-sm text-white fs-10 btn-light' : 'btn border-white btn-sm text-white fs-10'} style={{height: 'auto', padding: '5px 12px', position: 'relative'}} onClick={() => {selectOrderType(key)}}>
                                            <div class="bg-secondary d-flex justify-content-center align-items-center" style={{borderRadius: '100%', height: '25px', width: '25px', marginRight: '2px'}}>
                                                    {value.OrderTypeCount}
                                            </div>
                                            {value.Title !== '' &&
                                                <>
                                                    {value.Title}
                                                </>
                                            }
                                            {(parseInt(value.ItemCount) > 0) &&
                                                <span class="badge bg-danger" style={{position: 'absolute', top: '-8px', right: '-6px'}}>{value.ItemCount}</span>
                                            }
                                        </span>
                                    </SplideSlide>
                                })}
                            </>
                        }
                    </Splide> */}
                </div>
            </div>
        </>
    )
}

const mapStateToProps = ({ data, stock }) => {
    const {
        draftData,
        renderHeaderDraft,
        odIndex,
        activeDraftID,
        fullInvoice,
        updateCartFlag,
        updateCartKey
    } = data
    const {
        stockActionColor
    } = stock
    return {
        draftData,
        renderHeaderDraft,
        odIndex,
        activeDraftID,
        fullInvoice,
        updateCartFlag,
        updateCartKey,
        stockActionColor
    }
  }
  
  const mapDispatchToProps = {
    showMainMenuOffcanvas,
    showOrderTypeModal,
    showCheckoutOffcanvas,
    setCartDraftID,
    setCheckoutFlagDraft,
    updateCart,
    tempPrice,
    cartDraftIndex,
    cartOrderIndex,
    resetPrice,
    checkoutItemType,
    updatePrice,
    showDraftCartListModal,
    setActiveDraftCart,
    runUpdatedCartFunction,
    setDiscountDetails,
    setStockAction
  }

export default connect(mapStateToProps, mapDispatchToProps)(Header2)