import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    addTransactionModalVisible: false,
    transactionData: null
}

export const AddTransactionModalSlice = createSlice({
    name: 'addTransaction',
    initialState,
    reducers: {
      showAddTransactionModal: (state, action) => {
        const {
            addTransactionModalVisible,
            transactionData
        } = action.payload
        state.addTransactionModalVisible = addTransactionModalVisible
        state.transactionData = transactionData
      },
      hideAddTransactionModal: (state) => {
        state.addTransactionModalVisible = false
      }
    }
})

export const { showAddTransactionModal, hideAddTransactionModal } = AddTransactionModalSlice.actions;


export default AddTransactionModalSlice.reducer