import React, { Fragment, useEffect } from 'react'
import { connect } from 'react-redux'

import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { setToken } from './features/auth/authSlice'
import Authentication from './components/Pages/Authentication'
import Layout from './components/Pages/Layout'
import Load from './components/Pages/Load'
import AppInfo from './components/Pages/AppInfo'
import jwt_decode from 'jwt-decode'
import GetStarted from './components/Pages/GetStarted'
import Header from './components/layout/Header/Header'
import Footer from './components/layout/Footer/Footer'
import NewOrder from './components/Pages/NewOrder'
import Items from './components/Pages/Items'
import Summary from './components/Pages/Summary'
import Orders from './components/Pages/Orders'
import OrdersDetail from './components/Pages/OrdersDetail'
import Transactions from './components/Pages/Transactions'
import UserProfile from './components/Pages/UserProfile'
import ItemProfile from './components/Pages/ItemProfile'
import MenuCard from './components/Pages/MenuCard'
import BusinessProfile from './components/Pages/BusinessProfile'
import UserCheckInOut from './components/Pages/UserCheckInOut'
import axios from './AxiosConfig'
import { showShopStatusModal } from './features/Offcanvas/shopStatusModalSlice'
import StoreConfig from './components/Pages/StoreConfig'
import ManageStock from './components/Pages/ManageStock'
import Bill from './components/Pages/Bill'
import CustomOrder from './components/Pages/CustomOrder'

const App = ({
  authToken,
  setToken,
  showShopStatusModal
}) => {
  useEffect(() => {
    window.addEventListener('beforeunload', function(event) {
      // Your code here
      event.preventDefault();
      return 'Are you sure you want to leave this page?';
    });
  }, [])
  if ((localStorage.getItem('Orders') === null && localStorage.getItem('Purchase') === null && localStorage.getItem('Wastage') === null) || (localStorage.getItem('Orders').length <= 2 && localStorage.getItem('Purchase').length <= 2 && localStorage.getItem('Wastage').length <= 2)) {
    JSON.stringify(localStorage.setItem('Orders', '[]'))
    JSON.stringify(localStorage.setItem('Purchase', '[]'))
    JSON.stringify(localStorage.setItem('Wastage', '[]'))    
    JSON.stringify(localStorage.setItem('Carts', '[]'))    
  }
  const validateToken = () => {
    if (authToken) {
      axios.get('/OpeningClosing/getShopStatus/' + new Date().toISOString().substr(0, 10)).then(res => {
        if (res.data.message === 'Success') {
            localStorage.setItem('shopStatus', res.data.data)
            if (res.data.data === 'Close') {
              showShopStatusModal({shopStatusModalVisible: true})
            }
        } else {
            // showAppToast({ toastMessage: 'Something went wrong', background: 'danger', icon: 'close', position: 'center', timeout: 1000 })
        }
      }).catch(err => {
          // showAppToast({ toastMessage: 'Something went wrong', background: 'danger', icon: 'close', position: 'center', timeout: 1000 })
      });
      return true
    } else {
      const token = localStorage.getItem('token')
      const tokenData = (token) ? jwt_decode(token) : false
      if (tokenData && new Date(tokenData.exp * 1000) > new Date()) {
        if (tokenData.userInfo.hasOwnProperty('ID') && tokenData.userInfo.hasOwnProperty('RoleID')) {
          setToken({ authToken: token })
          return true
        } else {
          localStorage.clear()
          return false
        }
      } else {
        localStorage.clear()
        return false
      }
    }
  }
  const userLoggedIn = validateToken()
  
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
        {!userLoggedIn &&
          <Fragment>
            <Route path="*" element={<Auth />} />
            <Route path="getStarted" element={<GetStartedView />} />
            <Route path="menuCard/:restID" element={<MenuCardView />} />
            <Route path="bill" element={<BillView />} />
            <Route path="userLogin" element={<UserLoginView />} />
            {/* <Route path="getStartedLogin" element={<GetStartedLoginView />} /> */}
          </Fragment>
        }
        {userLoggedIn &&
          <Fragment>
            <Route path="/" element={<SelectWebsiteList />} />
            <Route path="getStarted" element={<GetStartedView />} />
            <Route path="appinfo" element={<AboutApp />} />
            <Route path="load" element={<SelectWebsiteList />} />
            <Route path="newOrder" element={<NewOrderView />} />
            <Route path="items" element={<ItemsView />} />
            <Route path="summary" element={<SummaryView />} />
            <Route path="orders" element={<OrdersView />} />
            <Route path="ordersDetail" element={<OrdersDetailView />} />
            <Route path="transactions" element={<TransactionsView />} />
            <Route path="userProfile" element={<UserProfileView />} />
            <Route path="itemProfile" element={<ItemProfileView />} />
            <Route path="businessProfile" element={<BusinessProfileView />} />
            <Route path="menuCard/:restID" element={<MenuCardView />} />
            <Route path="bill" element={<BillView />} />
            <Route path="userLogin" element={<UserLoginView />} />
            <Route path="storeConfig" element={<StoreConfigView />} />
            <Route path="manageStock" element={<ManageStockView />} />
            <Route path="customOrder" element={<CustomOrderView />} />
            <Route path="*" element={<Auth />} />
          </Fragment>
        }
        </Routes>
      </BrowserRouter>
    </div>
  );
}

function Auth() {
  return (
    <div className="App pt-5">
      <Authentication></Authentication>
    </div>
  )
}

function SelectWebsiteList() {
  return (
    <div className="App">
      <Load></Load>
    </div>
  )
}

function AboutApp() {
  return (
    <div className="App">
      {/* <Layout> */}
      <Header></Header>
        <AppInfo></AppInfo>
      <Footer></Footer>
      {/* </Layout> */}
    </div>
  )
}

function GetStartedView() {
  return (
    <div className="App">
    {/* <Layout> */}
      <GetStarted></GetStarted>
    {/* </Layout> */}
    </div>
  )
}

function NewOrderView() {
  return (
    <div className="App">
    <Layout>
      <NewOrder></NewOrder>
    </Layout>
    </div>
  )
}

function ItemsView() {
  return (
    <div className="App">
    <Layout>
      <Items></Items>
    </Layout>
    </div>
  )
}

function SummaryView() {
  return (
    <div className="App">
    <Layout>
      <Summary></Summary>
    </Layout>
    </div>
  )
}

function OrdersView() {
  return (
    <div className="App">
    <Layout>
      <Orders></Orders>
    </Layout>
    </div>
  )
}

function OrdersDetailView() {
  return (
    <div className="App">
    <Layout>
      <OrdersDetail></OrdersDetail>
    </Layout>
    </div>
  )
}

function TransactionsView() {
  return (
    <div className="App">
    <Layout>
      <Transactions></Transactions>
    </Layout>
    </div>
  )
}

function UserProfileView() {
  return (
    <div className="App">
    <Layout>
      <UserProfile></UserProfile>
    </Layout>
    </div>
  )
}

function ItemProfileView() {
  return (
    <div className="App">
    <Layout>
      <ItemProfile></ItemProfile>
    </Layout>
    </div>
  )
}

function BusinessProfileView() {
  return (
    <div className="App">
    <Layout>
      <BusinessProfile></BusinessProfile>
    </Layout>
    </div>
  )
}

function MenuCardView() {
  return (
    <div className="App">
    {/* <Layout> */}
      <MenuCard></MenuCard>
    {/* </Layout> */}
    </div>
  )
}

function UserLoginView() {
  return (
    <div className="App">
    {/* <Layout> */}
      <UserCheckInOut></UserCheckInOut>
    {/* </Layout> */}
    </div>
  )
}

function StoreConfigView() {
  return (
    <div className="App">
      <Layout>
        <StoreConfig></StoreConfig>
      </Layout>
    </div>
  )
}

function ManageStockView() {
  return (
    <div className="App">
      <Layout>
        <ManageStock></ManageStock>
      </Layout>
    </div>
  )
}

function CustomOrderView() {
  return (
    <div className="App">
      <Layout>
        <CustomOrder></CustomOrder>
      </Layout>
    </div>
  )
}

function BillView() {
  return (
    <div className="App">
    {/* <Layout> */}
      <Bill></Bill>
    {/* </Layout> */}
    </div>
  )
}

const mapStateToProps = ({ auth }) => {
  const {
    authToken
  } = auth

  return {
    authToken
  }
}

const mapDispatchToProps = {
  setToken,
  showShopStatusModal
}

export default connect(mapStateToProps, mapDispatchToProps)(App)