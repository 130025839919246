import React, { useEffect, useState } from 'react'
import { connect } from "react-redux"
import { useNavigate } from 'react-router-dom'
import axios from '../../AxiosConfig'
import Veg from '../images/icon/veg.png'
import NonVeg from '../images/icon/non-veg.png'
import { showImageEditModal } from '../../features/Offcanvas/imageEditModalSlice'
import { showAppToast } from '../../features/utilities/appToastSlice'
import PricingComponent from './BitComponents/PricingComponent'
import { showPaymentOffcanvas } from '../../features/Offcanvas/paymentOffcanvasSlice'

const ItemProfile = ({
    itemProfileData,
    categories,
    showImageEditModal,
    showAppToast,
    showPaymentOffcanvas
}) => {
    const [shortCode, setShortCode] = useState('')
    const [color, setColor] = useState('')
    const [alternateText, setAlternateText] = useState('')
    const [foodType, setFoodType] = useState('')
    let navigate = useNavigate()
    useEffect(() => {
        if (itemProfileData === null || itemProfileData === undefined || itemProfileData === '') {
            navigate('/items')
        }
    }, [itemProfileData])
    const addToQuickAdd = (quickAddStatus, ID) => {
        const itemQuickAddStatus = {
            "QuickAdd": quickAddStatus
        }
        axios.post('/ItemAvailability/' + ID, itemQuickAddStatus).then(res => {
            if (res.data.message === 'Success') {
                showAppToast({ toastMessage:'Uploaded', background: 'success', timeout: '1000', icon:'checkmark-circle-outline', position: 'toast-center', isVisible: true })
            } else {
                showAppToast({toastMessage: 'Something went wrong',background: 'danger',icon: 'close',position: 'center',timeout: 1000, isVisible: true})
            }
        })
    }
    const updateItemProfile = (ID) => {
        const updatedItemProfile = {
            'Shortcode': shortCode,
            'Color': color,
            'AlternateText': alternateText,
            'FoodType': foodType
        }
        axios.post('/ItemAvailability/' + ID, updatedItemProfile).then(res => {
            if (res.data.message === 'Success') {
                showAppToast({ toastMessage:'Updated', background: 'success', timeout: '1000', icon:'checkmark-circle-outline', position: 'toast-center', isVisible: true })
            } else {
                showAppToast({toastMessage: 'Something went wrong',background: 'danger',icon: 'close',position: 'center',timeout: 1000, isVisible: true})
            }
        })
    }
    useEffect(() => {
        setShortCode((itemProfileData) ? itemProfileData.Shortcode : '')
        setColor((itemProfileData) ? itemProfileData.Color : '')
        setAlternateText((itemProfileData) ? itemProfileData.AlternateText : '')  
        setFoodType((itemProfileData) ? itemProfileData.FoodType : '') 
    }, [])
    const handleItemImageEdit = (data) => {
        showImageEditModal({imageEditModalVisible: true, imageEditData: data})
    }
    const handleItemSizes = () => {
        showPaymentOffcanvas({paymentOffcanvasVisible: true, fromState: 'itemProfile'})
    }
    return (
        <div id="appCapsule">
            <div class="section mt-2">
                <div class="profile-head">
                    <div class="row w-100">
                        <div class="col-8 col-md-10 d-flex align-items-center">
                            <div class="avatar d-flex flex-column">
                                {(itemProfileData?.ImageURL !== null && itemProfileData?.ImageURL !== undefined && itemProfileData?.ImageURL !== '') &&
                                    <img src={itemProfileData?.ImageURL} alt="avatar" class="imaged w64 rounded-circle" />
                                }
                                {(itemProfileData?.ImageURL === null || itemProfileData?.ImageURL === undefined || itemProfileData?.ImageURL === '') &&
                                    <img src="https://us.123rf.com/450wm/viktorijareut/viktorijareut1503/viktorijareut150300119/37649514-grey-tray-kitchenware-trey-isolated-tray-vector-closed-tray.jpg?ver=6" alt="avatar" class="imaged w64 rounded-circle" />
                                }
                                <small class="text-secondary fs-10 d-flex justify-content-center align-items-center" style={{cursor: 'pointer'}} onClick={() => {handleItemImageEdit(itemProfileData)}}>
                                    <ion-icon name="camera-outline" style={{paddingBottom: '2px', marginRight: '2px'}}></ion-icon>
                                    Change
                                </small>
                            </div>
                            <div class="in">
                                {itemProfileData?.Item_ID &&
                                    <h5 class="subtext mb-0">Item ID: #{itemProfileData?.Item_ID}</h5>
                                }
                                <h3 class="name mb-0 d-flex align-items-center">
                                    <img src={(itemProfileData?.FoodType === 'veg') ? Veg : NonVeg} alt="avatar" style={{width: '20px', paddingBottom: '3px', marginRight: '2px'}} />
                                    <div class="form-group boxed p-0">
                                        <div class="input-wrapper">
                                            <input type="text" class="form-control p-0 ps-1 bg-transparent" id="itemName" placeholder="Item Name" value={itemProfileData?.ItemTitle} autocomplete="off" style={{height: '25px'}} />
                                            <i class="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                        </div>
                                    </div>
                                </h3>
                            </div>
                        </div>
                        <div class="col-4 col-md-2 d-flex justify-content-end align-items-center">
                            <button class="btn btn-danger btn-sm" disabled><ion-icon name="trash-outline"></ion-icon> Delete This Item</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section full">
                <div class="wide-block transparent p-0">
                    <ul class="nav nav-tabs lined iconed" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active" data-bs-toggle="tab" href="#details" role="tab" style={{height: '40px'}}>
                                <ion-icon name="document-text-outline"></ion-icon>
                                Details
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-bs-toggle="tab" href="#stock" role="tab" style={{height: '40px'}}>
                                <ion-icon name="cube-outline"></ion-icon>
                                Stock
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-bs-toggle="tab" href="#sales" role="tab" style={{height: '40px'}}>
                                <ion-icon name="pricetags-outline"></ion-icon>
                                Sales
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="section full mb-2">
                <div class="tab-content">
                    <div class="tab-pane fade show active" id="details" role="tabpanel">
                        <div class="section full mt-1" style={{margin: '0px -8px 0px -8px'}}>
                            <div class="section-title">Item Info</div>
                            <div class="wide-block pt-1 pb-1">
                                <div class="row">
                                    <div class="col-6 col-md-6">
                                        <div class="form-group boxed">
                                            <div class="input-wrapper">
                                                <label class="form-label" for="category">Category</label>
                                                <select class="form-control form-select" id="category">
                                                    {categories &&
                                                        <>
                                                            {categories.map((v,k) => {
                                                                return <option value={v.Name}>{v.Name}</option>
                                                            })}
                                                        </>
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-md-6">
                                        <div class="form-group boxed">
                                            <div class="input-wrapper">
                                                <label class="form-label" for="vegNonVeg">Veg / Non-Veg</label>
                                            </div>
                                        </div>
                                        {/* <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" value="veg" name="radioInline" id="radioInlineDefault1" checked={(foodType === 'veg') ? true : false} onChange={(e) => {setFoodType(e.target.value)}} />&nbsp;
                                            <label class="form-check-label" for="radioInlineDefault1">Veg</label>
                                        </div>&nbsp;&nbsp;&nbsp;&nbsp;
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" value="non-veg" name="radioInline" id="radioInlineDefault2" checked={(foodType === 'non-veg') ? true : false} onChange={(e) => {setFoodType(e.target.value)}} />&nbsp;
                                            <label class="form-check-label" for="radioInlineDefault2">Non-Veg</label>
                                        </div> */}
                                        <div class="btn-group w-100" role="group" aria-label="Basic radio toggle button group">
                                            <input type="radio" class="btn-check" value="veg" name="vegNonVeg" id="veg" checked={(foodType === 'veg') ? true : false} onChange={(e) => {setFoodType(e.target.value)}} />
                                            <label class="btn btn-block btn-outline-primary" for="veg">
                                                <img src={Veg} class="bg-white" style={{width: '18px', marginRight: '5px'}} />
                                                Veg
                                            </label>

                                            <input type="radio" class="btn-check" value="non-veg" name="vegNonVeg" id="nonVeg" checked={(foodType === 'non-veg') ? true : false} onChange={(e) => {setFoodType(e.target.value)}} />
                                            <label class="btn btn-block btn-outline-primary" for="nonVeg">
                                                <img src={NonVeg} class="bg-white" style={{width: '18px', marginRight: '5px'}} />
                                                Non-Veg
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-6 col-md-6">
                                        <div class="form-group boxed">
                                            <div class="input-wrapper">
                                                <label class="form-label" for="alternate_name">Alternate Name</label>
                                                <input type="text" class="form-control" id="alternate_name" placeholder="Enter Alternate Name" value={(alternateText) ? alternateText : 'N/A'} autocomplete="off" onChange={(e) => {setAlternateText(e.target.value)}} />
                                                <i class="clear-input">
                                                    <ion-icon name="close-circle"></ion-icon>
                                                </i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-md-6">
                                        <div class="form-group boxed">
                                            <div class="input-wrapper">
                                                <label class="form-label" for="short_code">Short Code</label>
                                                <input type="text" class="form-control" id="short_code" placeholder="Enter Short Code" value={(shortCode) ? shortCode : 'N/A'} autocomplete="off" onChange={(e) => {setShortCode(e.target.value)}} />
                                                <i class="clear-input">
                                                    <ion-icon name="close-circle"></ion-icon>
                                                </i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-md-6">
                                        <div class="form-group boxed">
                                            <div class="input-wrapper">
                                                <label class="form-label" for="category">Color</label>
                                                <select class="form-control form-select" value={color} id="color" onChange={(e) => {setColor(e.target.value)}} style={{backgroundColor: color}}>
                                                    <option value="" selected disabled>Select a color</option>
                                                    <option style={{backgroundColor: '#F0F8FF'}} value="#F0F8FF">AliceBlue</option>
                                                    <option style={{backgroundColor: '#FAEBD7'}} value="#FAEBD7">AntiqueWhite</option>
                                                    <option style={{backgroundColor: '#00FFFF'}} value="#00FFFF">Aqua</option>
                                                    <option style={{backgroundColor: '#7FFFD4'}} value="#7FFFD4">Aquamarine</option>
                                                    <option style={{backgroundColor: '#F0FFFF'}} value="#F0FFFF">Azure</option>
                                                    <option style={{backgroundColor: '#F5F5DC'}} value="#F5F5DC">Beige</option>
                                                    <option style={{backgroundColor: '#FFE4C4'}} value="#FFE4C4">Bisque</option>
                                                    <option style={{backgroundColor: '#000000'}} value="#000000">Black</option>
                                                    <option style={{backgroundColor: '#FFEBCD'}} value="#FFEBCD">BlanchedAlmond</option>
                                                    <option style={{backgroundColor: '#0000FF'}} value="#0000FF">Blue</option>
                                                    <option style={{backgroundColor: '#8A2BE2'}} value="#8A2BE2">BlueViolet</option>
                                                    <option style={{backgroundColor: '#A52A2A'}} value="#A52A2A">Brown</option>
                                                    <option style={{backgroundColor: '#DEB887'}} value="#DEB887">BurlyWood</option>
                                                    <option style={{backgroundColor: '#5F9EA0'}} value="#5F9EA0">CadetBlue</option>
                                                    <option style={{backgroundColor: '#7FFF00'}} value="#7FFF00">Chartreuse</option>
                                                    <option style={{backgroundColor: '#D2691E'}} value="#D2691E">Chocolate</option>
                                                    <option style={{backgroundColor: '#FF7F50'}} value="#FF7F50">Coral</option>
                                                    <option style={{backgroundColor: '#6495ED'}} value="#6495ED">CornflowerBlue</option>
                                                    <option style={{backgroundColor: '#FFF8DC'}} value="#FFF8DC">Cornsilk</option>
                                                    <option style={{backgroundColor: '#DC143C'}} value="#DC143C">Crimson</option>
                                                    <option style={{backgroundColor: '#00FFFF'}} value="#00FFFF">Cyan</option>
                                                    <option style={{backgroundColor: '#00008B'}} value="#00008B">DarkBlue</option>
                                                    <option style={{backgroundColor: '#008B8B'}} value="#008B8B">DarkCyan</option>
                                                    <option style={{backgroundColor: '#B8860B'}} value="#B8860B">DarkGoldenRod</option>
                                                    <option style={{backgroundColor: '#A9A9A9'}} value="#A9A9A9">DarkGray</option>
                                                    <option style={{backgroundColor: '#A9A9A9'}} value="#A9A9A9">DarkGrey</option>
                                                    <option style={{backgroundColor: '#006400'}} value="#006400">DarkGreen</option>
                                                    <option style={{backgroundColor: '#BDB76B'}} value="#BDB76B">DarkKhaki</option>
                                                    <option style={{backgroundColor: '#8B008B'}} value="#8B008B">DarkMagenta</option>
                                                    <option style={{backgroundColor: '#556B2F'}} value="#556B2F">DarkOliveGreen</option>
                                                    <option style={{backgroundColor: '#FF8C00'}} value="#FF8C00">DarkOrange</option>
                                                    <option style={{backgroundColor: '#9932CC'}} value="#9932CC">DarkOrchid</option>
                                                    <option style={{backgroundColor: '#8B0000'}} value="#8B0000">DarkRed</option>
                                                    <option style={{backgroundColor: '#E9967A'}} value="#E9967A">DarkSalmon</option>
                                                    <option style={{backgroundColor: '#8FBC8F'}} value="#8FBC8F">DarkSeaGreen</option>
                                                    <option style={{backgroundColor: '#483D8B'}} value="#483D8B">DarkSlateBlue</option>
                                                    <option style={{backgroundColor: '#2F4F4F'}} value="#2F4F4F">DarkSlateGray</option>
                                                    <option style={{backgroundColor: '#2F4F4F'}} value="#2F4F4F">DarkSlateGrey</option>
                                                    <option style={{backgroundColor: '#00CED1'}} value="#00CED1">DarkTurquoise</option>
                                                    <option style={{backgroundColor: '#9400D3'}} value="#9400D3">DarkViolet</option>
                                                    <option style={{backgroundColor: '#FF1493'}} value="#FF1493">DeepPink</option>
                                                    <option style={{backgroundColor: '#00BFFF'}} value="#00BFFF">DeepSkyBlue</option>
                                                    <option style={{backgroundColor: '#696969'}} value="#696969">DimGray</option>
                                                    <option style={{backgroundColor: '#696969'}} value="#696969">DimGrey</option>
                                                    <option style={{backgroundColor: '#1E90FF'}} value="#1E90FF">DodgerBlue</option>
                                                    <option style={{backgroundColor: '#B22222'}} value="#B22222">FireBrick</option>
                                                    <option style={{backgroundColor: '#FFFAF0'}} value="#FFFAF0">FloralWhite</option>
                                                    <option style={{backgroundColor: '#228B22'}} value="#228B22">ForestGreen</option>
                                                    <option style={{backgroundColor: '#FF00FF'}} value="#FF00FF">Fuchsia</option>
                                                    <option style={{backgroundColor: '#DCDCDC'}} value="#DCDCDC">Gainsboro</option>
                                                    <option style={{backgroundColor: '#F8F8FF'}} value="#F8F8FF">GhostWhite</option>
                                                    <option style={{backgroundColor: '#FFD700'}} value="#FFD700">Gold</option>
                                                    <option style={{backgroundColor: '#DAA520'}} value="#DAA520">GoldenRod</option>
                                                    <option style={{backgroundColor: '#808080'}} value="#808080">Gray</option>
                                                    <option style={{backgroundColor: '#808080'}} value="#808080">Grey</option>
                                                    <option style={{backgroundColor: '#008000'}} value="#008000">Green</option>
                                                    <option style={{backgroundColor: '#ADFF2F'}} value="#ADFF2F">GreenYellow</option>
                                                    <option style={{backgroundColor: '#F0FFF0'}} value="#F0FFF0">HoneyDew</option>
                                                    <option style={{backgroundColor: '#FF69B4'}} value="#FF69B4">HotPink</option>
                                                    <option style={{backgroundColor: '#CD5C5C'}} value="#CD5C5C">IndianRed</option>
                                                    <option style={{backgroundColor: '#4B0082'}} value="#4B0082">Indigo</option>
                                                    <option style={{backgroundColor: '#FFFFF0'}} value="#FFFFF0">Ivory</option>
                                                    <option style={{backgroundColor: '#F0E68C'}} value="#F0E68C">Khaki</option>
                                                    <option style={{backgroundColor: '#E6E6FA'}} value="#E6E6FA">Lavender</option>
                                                    <option style={{backgroundColor: '#FFF0F5'}} value="#FFF0F5">LavenderBlush</option>
                                                    <option style={{backgroundColor: '#7CFC00'}} value="#7CFC00">LawnGreen</option>
                                                    <option style={{backgroundColor: '#FFFACD'}} value="#FFFACD">LemonChiffon</option>
                                                    <option style={{backgroundColor: '#ADD8E6'}} value="#ADD8E6">LightBlue</option>
                                                    <option style={{backgroundColor: '#F08080'}} value="#F08080">LightCoral</option>
                                                    <option style={{backgroundColor: '#E0FFFF'}} value="#E0FFFF">LightCyan</option>
                                                    <option style={{backgroundColor: '#FAFAD2'}} value="#FAFAD2">LightGoldenRodYellow</option>
                                                    <option style={{backgroundColor: '#D3D3D3'}} value="#D3D3D3">LightGray</option>
                                                    <option style={{backgroundColor: '#D3D3D3'}} value="#D3D3D3">LightGrey</option>
                                                    <option style={{backgroundColor: '#90EE90'}} value="#90EE90">LightGreen</option>
                                                    <option style={{backgroundColor: '#FFB6C1'}} value="#FFB6C1">LightPink</option>
                                                    <option style={{backgroundColor: '#FFA07A'}} value="#FFA07A">LightSalmon</option>
                                                    <option style={{backgroundColor: '#20B2AA'}} value="#20B2AA">LightSeaGreen</option>
                                                    <option style={{backgroundColor: '#87CEFA'}} value="#87CEFA">LightSkyBlue</option>
                                                    <option style={{backgroundColor: '#778899'}} value="#778899">LightSlateGray</option>
                                                    <option style={{backgroundColor: '#778899'}} value="#778899">LightSlateGrey</option>
                                                    <option style={{backgroundColor: '#B0C4DE'}} value="#B0C4DE">LightSteelBlue</option>
                                                    <option style={{backgroundColor: '#FFFFE0'}} value="#FFFFE0">LightYellow</option>
                                                    <option style={{backgroundColor: '#00FF00'}} value="#00FF00">Lime</option>
                                                    <option style={{backgroundColor: '#32CD32'}} value="#32CD32">LimeGreen</option>
                                                    <option style={{backgroundColor: '#FAF0E6'}} value="#FAF0E6">Linen</option>
                                                    <option style={{backgroundColor: '#FF00FF'}} value="#FF00FF">Magenta</option>
                                                    <option style={{backgroundColor: '#800000'}} value="#800000">Maroon</option>
                                                    <option style={{backgroundColor: '#66CDAA'}} value="#66CDAA">MediumAquaMarine</option>
                                                    <option style={{backgroundColor: '#0000CD'}} value="#0000CD">MediumBlue</option>
                                                    <option style={{backgroundColor: '#BA55D3'}} value="#BA55D3">MediumOrchid</option>
                                                    <option style={{backgroundColor: '#9370DB'}} value="#9370DB">MediumPurple</option>
                                                    <option style={{backgroundColor: '#3CB371'}} value="#3CB371">MediumSeaGreen</option>
                                                    <option style={{backgroundColor: '#7B68EE'}} value="#7B68EE">MediumSlateBlue</option>
                                                    <option style={{backgroundColor: '#00FA9A'}} value="#00FA9A">MediumSpringGreen</option>
                                                    <option style={{backgroundColor: '#48D1CC'}} value="#48D1CC">MediumTurquoise</option>
                                                    <option style={{backgroundColor: '#C71585'}} value="#C71585">MediumVioletRed</option>
                                                    <option style={{backgroundColor: '#191970'}} value="#191970">MidnightBlue</option>
                                                    <option style={{backgroundColor: '#F5FFFA'}} value="#F5FFFA">MintCream</option>
                                                    <option style={{backgroundColor: '#FFE4E1'}} value="#FFE4E1">MistyRose</option>
                                                    <option style={{backgroundColor: '#FFE4B5'}} value="#FFE4B5">Moccasin</option>
                                                    <option style={{backgroundColor: '#FFDEAD'}} value="#FFDEAD">NavajoWhite</option>
                                                    <option style={{backgroundColor: '#000080'}} value="#000080">Navy</option>
                                                    <option style={{backgroundColor: '#FDF5E6'}} value="#FDF5E6">OldLace</option>
                                                    <option style={{backgroundColor: '#808000'}} value="#808000">Olive</option>
                                                    <option style={{backgroundColor: '#6B8E23'}} value="#6B8E23">OliveDrab</option>
                                                    <option style={{backgroundColor: '#FFA500'}} value="#FFA500">Orange</option>
                                                    <option style={{backgroundColor: '#FF4500'}} value="#FF4500">OrangeRed</option>
                                                    <option style={{backgroundColor: '#DA70D6'}} value="#DA70D6">Orchid</option>
                                                    <option style={{backgroundColor: '#EEE8AA'}} value="#EEE8AA">PaleGoldenRod</option>
                                                    <option style={{backgroundColor: '#98FB98'}} value="#98FB98">PaleGreen</option>
                                                    <option style={{backgroundColor: '#AFEEEE'}} value="#AFEEEE">PaleTurquoise</option>
                                                    <option style={{backgroundColor: '#DB7093'}} value="#DB7093">PaleVioletRed</option>
                                                    <option style={{backgroundColor: '#FFEFD5'}} value="#FFEFD5">PapayaWhip</option>
                                                    <option style={{backgroundColor: '#FFDAB9'}} value="#FFDAB9">PeachPuff</option>
                                                    <option style={{backgroundColor: '#CD853F'}} value="#CD853F">Peru</option>
                                                    <option style={{backgroundColor: '#FFC0CB'}} value="#FFC0CB">Pink</option>
                                                    <option style={{backgroundColor: '#DDA0DD'}} value="#DDA0DD">Plum</option>
                                                    <option style={{backgroundColor: '#B0E0E6'}} value="#B0E0E6">PowderBlue</option>
                                                    <option style={{backgroundColor: '#800080'}} value="#800080">Purple</option>
                                                    <option style={{backgroundColor: '#663399'}} value="#663399">RebeccaPurple</option>
                                                    <option style={{backgroundColor: '#FF0000'}} value="#FF0000">Red</option>
                                                    <option style={{backgroundColor: '#BC8F8F'}} value="#BC8F8F">RosyBrown</option>
                                                    <option style={{backgroundColor: '#4169E1'}} value="#4169E1">RoyalBlue</option>
                                                    <option style={{backgroundColor: '#8B4513'}} value="#8B4513">SaddleBrown</option>
                                                    <option style={{backgroundColor: '#FA8072'}} value="#FA8072">Salmon</option>
                                                    <option style={{backgroundColor: '#F4A460'}} value="#F4A460">SandyBrown</option>
                                                    <option style={{backgroundColor: '#2E8B57'}} value="#2E8B57">SeaGreen</option>
                                                    <option style={{backgroundColor: '#FFF5EE'}} value="#FFF5EE">SeaShell</option>
                                                    <option style={{backgroundColor: '#A0522D'}} value="#A0522D">Sienna</option>
                                                    <option style={{backgroundColor: '#C0C0C0'}} value="#C0C0C0">Silver</option>
                                                    <option style={{backgroundColor: '#87CEEB'}} value="#87CEEB">SkyBlue</option>
                                                    <option style={{backgroundColor: '#6A5ACD'}} value="#6A5ACD">SlateBlue</option>
                                                    <option style={{backgroundColor: '#708090'}} value="#708090">SlateGray</option>
                                                    <option style={{backgroundColor: '#708090'}} value="#708090">SlateGrey</option>
                                                    <option style={{backgroundColor: '#FFFAFA'}} value="#FFFAFA">Snow</option>
                                                    <option style={{backgroundColor: '#00FF7F'}} value="#00FF7F">SpringGreen</option>
                                                    <option style={{backgroundColor: '#4682B4'}} value="#4682B4">SteelBlue</option>
                                                    <option style={{backgroundColor: '#D2B48C'}} value="#D2B48C">Tan</option>
                                                    <option style={{backgroundColor: '#008080'}} value="#008080">Teal</option>
                                                    <option style={{backgroundColor: '#D8BFD8'}} value="#D8BFD8">Thistle</option>
                                                    <option style={{backgroundColor: '#FF6347'}} value="#FF6347">Tomato</option>
                                                    <option style={{backgroundColor: '#40E0D0'}} value="#40E0D0">Turquoise</option>
                                                    <option style={{backgroundColor: '#EE82EE'}} value="#EE82EE">Violet</option>
                                                    <option style={{backgroundColor: '#F5DEB3'}} value="#F5DEB3">Wheat</option>
                                                    <option style={{backgroundColor: '#FFFFFF'}} value="#FFFFFF">White</option>
                                                    <option style={{backgroundColor: '#F5F5F5'}} value="#F5F5F5">WhiteSmoke</option>
                                                    <option style={{backgroundColor: '#FFFF00'}} value="#FFFF00">Yellow</option>
                                                    <option style={{backgroundColor: '#9ACD32'}} value="#9ACD32">YellowGreen</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-md-6">
                                        <div class="form-group boxed">
                                            <div class="input-wrapper">
                                                <label class="form-label" for="quickAdd">Add to Quick Access</label>
                                                <div class="form-check form-switch">
                                                    <input class="form-check-input" type="checkbox" id="SwitchCheckDefault1" />
                                                    <label class="form-check-label" for="SwitchCheckDefault1"></label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-md-6">
                                        <div class="form-group boxed">
                                            <div class="input-wrapper">
                                                <label class="form-label" for="saleable">Saleable</label>
                                                <div class="form-check form-switch">
                                                    <input class="form-check-input" type="checkbox" id="SwitchCheckDefault2" />
                                                    <label class="form-check-label" for="SwitchCheckDefault2"></label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-md-6">
                                        <div class="form-group boxed">
                                            <div class="input-wrapper">
                                                <label class="form-label" for="stockable">Stockable</label>
                                                <div class="form-check form-switch">
                                                    <input class="form-check-input" type="checkbox" id="SwitchCheckDefault3" />
                                                    <label class="form-check-label" for="SwitchCheckDefault3"></label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="section full mt-1" style={{margin: '0px -8px 0px -8px'}}>
                            <div class="section-title">Measurments</div>
                            <div class="wide-block pt-1 pb-1">
                                <div class="row">
                                    <div class="col-6 col-md-6">
                                        <div class="form-group boxed">
                                            <div class="input-wrapper">
                                                <label class="form-label" for="saleUnit">Sale Unit</label>
                                                <select class="form-control form-select" id="saleUnit">
                                                    <option value="kg">Kilogram</option>
                                                    <option value="g">Gram</option>
                                                    <option value="l">Litter</option>
                                                    <option value="ml">Milliliter</option>
                                                    <option value="m">Meter</option>
                                                    <option value="cm">Centimeter</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-md-6">
                                        <div class="form-group boxed">
                                            <div class="input-wrapper">
                                                <label class="form-label" for="purchaseUnit">Purchase Unit</label>
                                                <select class="form-control form-select" id="purchaseUnit">
                                                    <option value="kg">Kilogram</option>
                                                    <option value="g">Gram</option>
                                                    <option value="l">Litter</option>
                                                    <option value="ml">Milliliter</option>
                                                    <option value="m">Meter</option>
                                                    <option value="cm">Centimeter</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-md-6">
                                        <div class="form-group boxed">
                                            <div class="input-wrapper">
                                                <label class="form-label" for="minimumSaleQty">Minimum Sale Qty</label>
                                                <input type="number" class="form-control" id="minimumSaleQty" placeholder="Min Sale Qty" autocomplete="off" />
                                                <i class="clear-input">
                                                    <ion-icon name="close-circle"></ion-icon>
                                                </i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-md-6">
                                        <div class="form-group boxed">
                                            <div class="input-wrapper">
                                                <label class="form-label" for="unitOfConversion">Unit of Conversion</label>
                                                <input type="number" class="form-control" id="unitOfConversion" placeholder="Min Sale Qty" autocomplete="off" />
                                                <i class="clear-input">
                                                    <ion-icon name="close-circle"></ion-icon>
                                                </i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-md-6">
                                        <div class="form-group boxed">
                                            <div class="input-wrapper">
                                                <label class="form-label" for="allowNegativeStock">Allow Negative Stock</label>
                                                <div class="form-check form-switch">
                                                    <input class="form-check-input" type="checkbox" id="SwitchCheckDefault4" />
                                                    <label class="form-check-label" for="SwitchCheckDefault4"></label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="section full mt-1" style={{margin: '0px -8px 0px -8px'}}>
                            <div class="section-title">Pricing</div>
                            <div class="wide-block pt-1 pb-1">
                               <PricingComponent></PricingComponent>
                            </div>
                        </div>
                        <div class="section full mt-1" style={{margin: '0px -8px 0px -8px'}}>
                            <div class="section-title">Sizes</div>
                            <div class="wide-block pt-1 pb-1">
                                <button class="btn btn-outline-primary me-1 mb-1" onClick={() => {handleItemSizes()}}>50gm</button>
                                <button class="btn btn-outline-primary me-1 mb-1" onClick={() => {handleItemSizes()}}>100gm</button>
                                <button class="btn btn-outline-primary me-1 mb-1" onClick={() => {handleItemSizes()}}>150gm</button>
                                <button class="btn btn-outline-primary me-1 mb-1" onClick={() => {handleItemSizes()}}>200gm</button>
                                <button class="btn btn-success me-1 mb-1" onClick={() => {handleItemSizes()}}><ion-icon name="add-circle-outline"></ion-icon> Add Size</button>
                            </div>
                        </div>
                        <div class="section full mt-1" style={{margin: '0px -8px 0px -8px'}}>
                            <div class="section-title">Delete</div>
                            <div class="wide-block pt-1 pb-1 d-flex">
                                <button class="btn btn-danger btn-block me-1" disabled>
                                    <ion-icon name="trash-outline"></ion-icon>
                                    Delete</button>
                                <button class="btn btn-success btn-block" onClick={() => {updateItemProfile(itemProfileData?.ID)}}>
                                    <ion-icon name="checkmark-circle-outline"></ion-icon>
                                    Update
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="stock" role="tabpanel">
                        <div class="section full mt-1" style={{margin: '0px -8px 0px -8px'}}>
                            <div class="section-title justify-content-start">Stock History 
                                {/* <span class="text-muted ps-1">(Last 7 Days)</span> */}
                                <div class="dropdown d-flex ms-1" style={{cursor: 'pointer'}}>
                                    <div class="badge bg-primary dropdown-toggle fs-8" data-bs-toggle="dropdown">
                                        <span class="ps-1">
                                            Last 7 Days
                                        </span>
                                        <ion-icon name="chevron-down-outline" class="fs-10"></ion-icon>
                                    </div>
                                    <div class="dropdown-menu p-0">
                                        <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)">Today</a>
                                        <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)">Yesterday</a>
                                        <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)">Last 7 Days</a>
                                        <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)">Last 30 Days</a>
                                        <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)">All</a>
                                    </div>
                                </div>
                            </div>
                            <div class="wide-block">
                                <div class="timeline timed">
                                    <div class="item">
                                        <span class="time">25/12/2023<br />11:00 AM</span>
                                        <div class="dot" style={{backgroundColor: '#e3c20e'}}></div>
                                        <div class="content">
                                            <h4 class="title"><ion-icon name="bag-add-outline"></ion-icon> Added <span class="fw-bolder">5 Items</span> </h4>
                                            <div class="text">
                                                <div class="chip chip-media chip-success chip-outline mb-1 me-1">
                                                    <img src="assets/img/sample/avatar/avatar1.jpg" alt="avatar" />
                                                    <span class="chip-label">Chocolate Cake x 10</span>
                                                </div>
                                                <div class="chip chip-media chip-danger chip-outline mb-1 me-1">
                                                    <img src="assets/img/sample/avatar/avatar1.jpg" alt="avatar" />
                                                    <span class="chip-label">Blackforest Cake x 10</span>
                                                </div>
                                                <div class="chip chip-media chip-success chip-outline mb-1 me-1">
                                                    <img src="assets/img/sample/avatar/avatar1.jpg" alt="avatar" />
                                                    <span class="chip-label">Chocolate Pastry x 50</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="item">
                                        <span class="time">24/12/2023<br />1:30 PM</span>
                                        <div class="dot bg-danger"></div>
                                        <div class="content">
                                            <h4 class="title"><ion-icon name="bag-remove-outline"></ion-icon> Removed <span class="badge bg-primary" style={{height: '16px'}}>10 x 100</span></h4>
                                            <div class="text">
                                                <div class="chip chip-success me-1 mb-1">
                                                    <span class="chip-label">Chocolate Cake</span>
                                                </div>
                                                <div class="chip chip-danger me-1 mb-1">
                                                    <span class="chip-label">Blackforest Cake</span>
                                                </div>
                                                <div class="chip chip-success me-1 mb-1">
                                                    <span class="chip-label">Chocolate Pastry</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="item">
                                        <span class="time">23/12/2023<br />04:40 PM</span>
                                        <div class="dot" style={{backgroundColor: '#fa758d'}}></div>
                                        <div class="content">
                                            <h4 class="title"><ion-icon name="swap-horizontal-outline"></ion-icon> Transfered <span class="badge bg-primary" style={{height: '16px'}}>5 x 80</span></h4>
                                            <div class="text">
                                                <div class="chip chip-success me-1 mb-1">
                                                    <span class="chip-label">Chocolate Cake</span>
                                                </div>
                                                <div class="chip chip-danger me-1 mb-1">
                                                    <span class="chip-label">Blackforest Cake</span>
                                                </div>
                                                <div class="chip chip-success me-1 mb-1">
                                                    <span class="chip-label">Chocolate Pastry</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="item">
                                        <span class="time">22/12/2023<br />06:00 PM</span>
                                        <div class="dot bg-danger"></div>
                                        <div class="content">
                                            <h4 class="title"><ion-icon name="bag-handle-outline"></ion-icon> Request <span class="badge bg-primary" style={{height: '16px'}}>15 x 15</span></h4>
                                            <div class="text">
                                                <div class="chip chip-success me-1 mb-1">
                                                    <span class="chip-label">Chocolate Cake</span>
                                                </div>
                                                <div class="chip chip-danger me-1 mb-1">
                                                    <span class="chip-label">Blackforest Cake</span>
                                                </div>
                                                <div class="chip chip-success me-1 mb-1">
                                                    <span class="chip-label">Chocolate Pastry</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="sales" role="tabpanel">
                        <div class="section full mt-1" style={{margin: '0px -8px 0px -8px'}}>
                            <div class="section-title justify-content-start">Graphical</div>
                            <div class="wide-block p-3">
                                GRAPH
                                <div class="table-responsive border">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">First</th>
                                                <th scope="col">Last</th>
                                                <th scope="col">Handle</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th scope="row">1</th>
                                                <td>Mark</td>
                                                <td>Otto</td>
                                                <td>@mdo</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">2</th>
                                                <td>Jacob</td>
                                                <td>Thornton</td>
                                                <td>@fat</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">3</th>
                                                <td>Larry</td>
                                                <td>the Bird</td>
                                                <td>@twitter</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            {/* <div class="section-title justify-content-start">Tabular</div>
                            <div class="wide-block">
                                <div class="section full mt-1 mb-2">
                                    
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({ itemProfile, data }) => {
    const {
        itemProfileData
    } =  itemProfile
    const {
        categories
    } = data
    return {
        itemProfileData,
        categories
    }
}
  
const mapDispatchToProps = {
    showImageEditModal,
    showAppToast,
    showPaymentOffcanvas
}

export default connect(mapStateToProps, mapDispatchToProps)(ItemProfile)
