import React from 'react'
import { connect } from 'react-redux'
import Moment from 'react-moment'
import { showTransactionDetailModal } from '../../../features/Offcanvas/transactionDetailModalSlice'

const TransactionListView = ({
    list,
    type,
    showTransactionDetailModal,
    userValue
}) => {
    const handleTransactionDetailView = (data) => {
        showTransactionDetailModal({transactionDetailModalVisible: true, transactionDetaildata: data})
    }
    return (
        <ul class="listview image-listview flush bg-transparent">
            {list.map(value => {
                return <>
                {(type === 'income' && value.Transaction_Category_ID === '1') &&
                    <li>
                        <a href="javascript:void(0)" class="item ps-1" onClick={() => {handleTransactionDetailView(value)}}>
                            {(value.PaymentMethod === 'UPI' || value.PaymentMethod === 'upi') &&
                                <div class="icon-box bg-warning">
                                    <ion-icon name="qr-code-outline"></ion-icon>
                                </div>
                            }
                            {(value.PaymentMethod === 'Cash' || value.PaymentMethod === 'cash') &&
                                <div class="icon-box bg-success">
                                    <ion-icon name="cash-outline"></ion-icon>
                                </div>
                            }
                            {(value.PaymentMethod === 'Card' || value.PaymentMethod === 'card') &&
                                <div class="icon-box bg-info">
                                    <ion-icon name="card-outline"></ion-icon>
                                </div>
                            }
                            {(value.PaymentMethod === 'Cheque' || value.PaymentMethod === 'cheque') &&
                                <div class="icon-box bg-secondary">
                                    <ion-icon name="receipt-outline"></ion-icon>
                                </div>
                            }
                            {(value.PaymentMethod === 'Payment-Gateway' || value.PaymentMethod === 'payment-gateway') &&
                                <div class="icon-box bg-secondary">
                                    <ion-icon name="wallet-outline"></ion-icon>
                                </div>
                            }
                            {(value.PaymentMethod === 'Other' || value.PaymentMethod === 'other') &&
                                <div class="icon-box bg-secondary">
                                    <ion-icon name="apps-outline"></ion-icon>
                                </div>
                            }
                            <div class="in">
                                <div>
                                    <header class="d-flex align-items-center">
                                        Bill No: {value.billNo}&nbsp;
                                        {value.Transaction_Category_ID === '1' &&
                                            <ion-icon name="arrow-up-outline" class="text-success"></ion-icon>
                                        }
                                        {value.Transaction_Category_ID !== '1' &&
                                            <ion-icon name="arrow-down-outline" class="text-danger"></ion-icon>
                                        }
                                    </header>
                                    <span style={{textTransform: 'capitalize'}}>{value.Transaction_Type}</span>
                                    <footer><Moment fromNow>{value.Date}</Moment></footer>
                                </div>
                                <span class="text-muted">
                                    <div class="text-end">
                                        <div class="fw-bolder text-dark">₹{value.Amount}</div>
                                        <div>{value.PaymentMethod}</div>
                                    </div>
                                </span>
                            </div>
                        </a>
                    </li>
                }
                {(type === 'expense' && value.Transaction_Category_ID !== '1') &&
                    <li>
                        <a href="javascript:void(0)" class="item ps-1">
                        {/* onClick={() => {handleTransactionDetailView(value)}} */}
                            {value.PaymentMethod === 'UPI' &&
                                <div class="icon-box bg-warning">
                                    <ion-icon name="qr-code-outline"></ion-icon>
                                </div>
                            }
                            {value.PaymentMethod === 'Cash' &&
                                <div class="icon-box bg-success">
                                    <ion-icon name="cash-outline"></ion-icon>
                                </div>
                            }
                            {value.PaymentMethod === 'Card' &&
                                <div class="icon-box bg-info">
                                    <ion-icon name="card-outline"></ion-icon>
                                </div>
                            }
                            {value.PaymentMethod === 'cheque' &&
                                <div class="icon-box bg-secondary">
                                    <ion-icon name="receipt-outline"></ion-icon>
                                </div>
                            }
                            {value.PaymentMethod === 'Payment-Gateway' &&
                                <div class="icon-box bg-secondary">
                                    <ion-icon name="wallet-outline"></ion-icon>
                                </div>
                            }
                            {value.PaymentMethod === 'Other' &&
                                <div class="icon-box bg-secondary">
                                    <ion-icon name="apps-outline"></ion-icon>
                                </div>
                            }
                            <div class="in">
                                <div>
                                    <header>Bill No: {value.billNo}</header>
                                    {value.PaymentMethod}
                                    <footer><Moment fromNow>{value.Date}</Moment> <span class="badge bg-secondary" style={{height: '17px'}}>#{value.Item_Order_ID}</span></footer>
                                </div>
                                <span class="text-muted">
                                    <div class="text-end">
                                        <div class="fw-bolder text-dark">₹{value.Amount}</div>
                                        <div>{value.OrderType}</div>
                                    </div>
                                </span>
                            </div>
                        </a>
                    </li>
                }
                </>
            })}
        </ul>
    )
}

const mapStateToProps = () => {
    return {
    }
}
  
const mapDispatchToProps = {
    showTransactionDetailModal
}

export default connect(mapStateToProps, mapDispatchToProps)(TransactionListView)
