import React, { useEffect, useState } from 'react'
import { connect } from "react-redux"
import axios from '../../../AxiosConfig'
import { useNavigate, useLocation, Link } from 'react-router-dom'
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { generalDataUpdate, setShortcodeVisible, setAlternateTextVisible, setColorVisible } from '../../../features/utilities/generalSlice'
import { showAddCategoryOffcanvas } from '../../../features/Offcanvas/addCategoryOffcanvasSlice'
import { activeTabUpdatedName, itemSearch, setOrderDayFilter, emptyTranscript, setCategoryName, setTransactionDayFilter, categoryIdUpdate, checkoutItemType, cartDraftIndex, cartDraftData, cartOrderIndex, setTempCategoryId } from '../../../features/Data/dataSlice'
import { showAddTransactionModal } from '../../../features/Offcanvas/addTransactionModalSlice'
import { showQuickAddOffcanvas } from '../../../features/Offcanvas/quickAddOffcanvasSlice'
import { showOrderTypeModal } from '../../../features/Offcanvas/orderTypeModalSlice'

const SearchWithButtons = ({
    generalDataUpdate,
    gridView,
    view,
    pageName,
    showAddCategoryOffcanvas,
    activeTabUpdatedName,
    itemSearch,
    showAddTransactionModal,
    itemName,
    setOrderDayFilter,
    dayOrderFilter,
    transcriptEmpty,
    emptyTranscript,
    categoryName,
    setCategoryName,
    setTransactionDayFilter,
    dayTransactionFilter,
    categoryIdUpdate,
    categories,
    showQuickAddOffcanvas,
    setShortcodeVisible,
    setAlternateTextVisible,
    setColorVisible,
    shortcodeVisible,
    alternateTextVisible,
    colorVisible,
    topScrollValue,
    type,
    checkoutItemType,
    draftData,
    draftIndex,
    cartDraftIndex,
    cartDraftData,
    cartOrderIndex,
    showOrderTypeModal,
    stockActionColor,
    setTempCategoryId
}) => {
    const [activeIcon, showActiveIcon] = useState('grid')
    const [searchInput, showSearchInput] = useState(false)
    const [ID, setID] = useState(null)
    const search = useLocation().search
    const orderType = new URLSearchParams(search).get('tab')
    const categoryType = new URLSearchParams(search).get('category')
    const transactionCategory = new URLSearchParams(search).get('tcategory')
    const transactionType = new URLSearchParams(search).get('type')
    const [searchText, setSearchText] = useState('')
    const [transactionCategoryList, setTransactionCategoryList] = useState(null)
    const [activeTab, setActiveTab] = useState(0)
    const stateName = window.location.pathname.split('/')
    const [action, setAction] = useState('')
    let navigate = useNavigate()
    useEffect(() => {
        setAction(new URLSearchParams(search).get('action'))
    }, [search])
    useEffect(() => {
        if (view === undefined || view === null || view === '' || view === 'grid') {
            generalDataUpdate({view: 'grid'})
        }
    }, [view])
    useEffect(() => {
        axios.get('/TransactionCategories').then(res => {
            if (res.data.message === 'Success') {
                setTransactionCategoryList(res.data.data)
            }
        })
    }, [])
    const handleViewSwitch = (viewName) => {
        generalDataUpdate({view: viewName})
    }
    const handleListViewSwitch = () => {
        generalDataUpdate({gridView: false})
        showActiveIcon('list')
    }
    const handleGridViewSwitch = () => {
        generalDataUpdate({gridView: true})
        showActiveIcon('grid')
    }
    const handleCategroyModal = () => {
        showAddCategoryOffcanvas({addCategoryOffcanvasVisible: true})
    }

    const handleAddOrder = (tempTabName) => {
        if (tempTabName === 'sales') {
            activeTabUpdatedName({activeTabName: 'sales'})
            navigate('/ordersDetail')
        } else if (tempTabName === 'purchase') {
            activeTabUpdatedName({activeTabName: 'purchase'})
            navigate('/ordersDetail')
        } else if (tempTabName === 'wastage') {
            activeTabUpdatedName({activeTabName: 'wastage'})
            navigate('/ordersDetail')
        }
    }

    const handleItemSearch = (itemName) => {
        itemSearch({itemName: itemName})
    }

    useEffect(() => {
        setSearchText(itemName)
        if (categoryName !== undefined && categoryName !== null && categoryName !== '') {
            setSearchText(categoryName)
        }
    }, [itemName, categoryName])

    const handleSearchShow =() => {
        showSearchInput(true)
    }
    const handleSearchHide =() => {
        showSearchInput(false)
    }

    const handleOrderTypeFilter = (orderType) => {
        navigate('/orders?tab=' + orderType + '&category=' + categoryType)
    }

    const handleCategoryFilter = (categoryType) => {
        navigate('/orders?tab=' + orderType + '&category=' + categoryType)
    }

    const handleTransactionTypeFilter = (transactionType) => {
        navigate('/transactions?type='  + transactionType + '&tcategory=' + transactionCategory)
    }

    const handleTransactionCategoryFilter = (transactionCategory) => {
        navigate('/transactions?type='  + transactionType + '&tcategory=' + transactionCategory)
    }

    const handleAddTransactionModal = () => {
        showAddTransactionModal({addTransactionModalVisible: true, transactionData: null})
    }

    const {
        transcript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition
    } = useSpeechRecognition();

    function startSpeechRecognition() {
        SpeechRecognition.startListening({ continuous: true, language: 'en-IN' });
    }

    useEffect(() => {
        if (transcriptEmpty === true) {
            resetTranscript()
            emptyTranscript({transcriptEmpty: false})
        }
    }, [transcriptEmpty])

    function stopSpeechRecognition() {
        SpeechRecognition.stopListening();
        handleItemSearch(transcript);
        setTimeout(() => {
            resetTranscript();
        }, 300);
    }

    const isSpeechRecognitionDoable = () => {
        if (!browserSupportsSpeechRecognition) {
            return (<span className='fs-16' title='Voice to Text is not supported on this browser!'>
                <ion-icon name="mic-off-outline"></ion-icon>
            </span>)
        } else {
            return (
                <>
                    { 
                        listening ?
                            <button type='button' className='speaker-on speaker-btn position-absolute e-2 t-1' title='Stop Listening' onClick={() => stopSpeechRecognition()}>
                                <div class="pulse-ring" />
                                <div className='listening-animation'>
                                    <div className='sides border-circle' />
                                    <div className='center border-circle' />
                                    <div className='sides border-circle' />
                                </div>
                                <ion-icon name="mic-off-outline" class="off-mic" style={{ cursor: 'pointer' }}></ion-icon>
                            </button>
                        :
                            <button type='button' className='speaker-off speaker-btn position-absolute e-2 t-1' title='Type by Voice' style={{color: (stateName[1] === 'manageStock') ? stockActionColor : '', borderColor: (stateName[1] === 'manageStock') ?  stockActionColor : ''}} onClick={() => startSpeechRecognition()}>
                                <ion-icon name="mic-outline" style={{ cursor: 'pointer' }}></ion-icon>
                            </button>
                    }
                </>
            )
        }
    }
    const clearSearch = () => {
        setSearchText('')
        itemSearch({itemName: ''})
        setCategoryName({categoryName: ''})
        categoryIdUpdate({categoryID: 'all'})
        setTempCategoryId({tempcategoryId: null})
    }

    const handleOrderDateWise = (day) => {
        setOrderDayFilter({dayOrderFilter: day})
    }

    const handleTransactionDateWise = (day) => {
        setTransactionDayFilter({dayTransactionFilter: day})
    }

    const handleTransactionCategory = (categoryID, activeCategoryName) => {
        // console.log(categoryID, activeCategoryName)
    }

    const handleCategorySwitch = (categoryID, categoryName) => {
        categoryIdUpdate({categoryID: (categoryID === undefined || categoryID === null || categoryID === '') ? 1 : categoryID})
        setID(categoryID)
        setCategoryName({categoryName: categoryName})
    }

    const handleAlternateToggle = (toggleType) => {
        if (toggleType === 'shortcode') {
            setAlternateTextVisible({alternateTextVisible: 'shortcode'})
        } else if (toggleType === 'alternateText') {
            setAlternateTextVisible({alternateTextVisible: 'alternateText'})
        } else if (toggleType === 'englishText') {
            setAlternateTextVisible({alternateTextVisible: 'englishText'})
        } else if (toggleType === 'color') {
            setColorVisible()
        }
    }

    const handleActiveTab = (ID, type) => {
        setActiveTab(ID)
        if (type === null || type === undefined || type === '') {
            checkoutItemType({type: topScrollValue[0].name})
        } else {
            checkoutItemType({type: type})
        }

        // if (shortCut === true) {
        //     hideMainActionSheet()
        //     navigate('/newOrder')
        // }

        let tempObj = JSON.parse(JSON.stringify(draftData))
        let addNewItem = false
        if (draftIndex !== null && draftIndex !== undefined && draftIndex !== '') {
            tempObj[draftIndex].OrderType = type 
            addNewItem = false
        } else {
            for (let i = 0; i < draftData.length; i++) {
                if (draftData[i].Status === -2) {
                    tempObj[i].Status = -1
                    tempObj[i].OrderType = type
                    tempObj[i].TotalQuantity = 0
                    cartDraftIndex({draftIndex: i})
                    cartOrderIndex({odIndex: 0})
                    break
                }
            }
        }
        cartDraftData({draftData: tempObj})
    }
    return (
        <div class="row">
            <div class="col-6 col-md-6 d-flex align-items-center">
                {pageName === 'Transactions' &&
                    <>
                        <div class="dropdown d-flex me-1" style={{cursor: 'pointer'}}>
                            <div class="badge bg-primary dropdown-toggle fs-8" data-bs-toggle="dropdown">
                                <span style={{textTransform: 'uppercase'}}>
                                    <ion-icon name="calendar-outline" class="m-0 fs-8" style={{width: '18px'}}></ion-icon>
                                    {dayTransactionFilter}
                                </span>
                                <ion-icon name="chevron-down-outline" class="fs-6"></ion-icon>
                            </div>
                            <div class="dropdown-menu p-0">
                                <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)" onClick={() => {handleTransactionDateWise('Today')}}>Today</a>
                                <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)" onClick={() => {handleTransactionDateWise('Yesterday')}}>Yesterday</a>
                                <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)" onClick={() => {handleTransactionDateWise('Last7')}}>Last 7 Days</a>
                                <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)" onClick={() => {handleTransactionDateWise('Last30')}}>Last 30 Days</a>
                                <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)" onClick={() => {handleTransactionDateWise('All')}}>All</a>
                            </div>
                        </div>
                        <div class="dropdown d-flex me-1" style={{cursor: 'pointer'}}>
                            <div class="badge bg-primary dropdown-toggle fs-8" data-bs-toggle="dropdown">
                                <span style={{textTransform: 'uppercase'}}>
                                    <ion-icon name="grid-outline" class="m-0 fs-8" style={{width: '18px'}}></ion-icon>
                                    Sale
                                </span>
                                <ion-icon name="chevron-down-outline" class="fs-6"></ion-icon>
                            </div>
                            <div class="dropdown-menu p-0">
                                {transactionCategoryList !== null &&
                                    <>
                                        {transactionCategoryList.map(value => {
                                            return <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)" onClick={() => {handleTransactionCategory(value.ID, value.Title)}}>{value.Title}</a>
                                        })}
                                    </>
                                }
                            </div>
                        </div>
                    </>
                }
                {pageName === 'Orders' &&
                    <>
                        <div class="dropdown d-flex me-1" style={{cursor: 'pointer'}}>
                            <div class="badge bg-primary dropdown-toggle fs-8" data-bs-toggle="dropdown">
                                <span style={{textTransform: 'uppercase'}}>
                                    <ion-icon name="calendar-outline" class="m-0 fs-8" style={{width: '18px'}}></ion-icon>
                                    {dayOrderFilter}
                                </span>
                                <ion-icon name="chevron-down-outline" class="fs-6"></ion-icon>
                            </div>
                            <div class="dropdown-menu p-0">
                                <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)" onClick={() => {handleOrderDateWise('Today')}}>Today</a>
                                <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)" onClick={() => {handleOrderDateWise('Yesterday')}}>Yesterday</a>
                                <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)" onClick={() => {handleOrderDateWise('Last7')}}>Last 7 Days</a>
                                <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)" onClick={() => {handleOrderDateWise('Last30')}}>Last 30 Days</a>
                                {/* <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)" onClick={() => {handleOrderDateWise('ThisMonth')}}>This Month</a> */}
                                {/* <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)" onClick={() => {handleOrderDateWise('PrevMonth')}}>Previous Month</a> */}
                                <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)" onClick={() => {handleOrderDateWise('All')}}>All</a>
                            </div>
                        </div>
                    </>
                }
                {(pageName === 'NewOrder' || pageName === 'ManageStock') &&
                    <>
                        <div class="search-form ms-1 me-1">
                            <div class="form-group searchbox">
                                {
                                    listening &&
                                    <span className='position-absolute' style={{top: '5px', left: '39px'}}>{transcript}</span>
                                }
                                <input type="text" value={listening ? '' : searchText} class="form-control" placeholder={listening ? '' : "Search"} style={{borderRadius: '50px'}} onChange={(e) => {handleItemSearch(e.target.value)}} />
                                <i class="input-icon">
                                    <ion-icon name="search-outline"></ion-icon>
                                </i>
                                <div class="dropdown icon-searchbar-custom-position" style={{position: 'absolute'}}>
                                    {(searchText === '' || searchText === null || searchText === undefined) &&
                                        <button type='button' className='btn btn-tarnsparent dropdown-toggle position-absolute e-2 t-1 p-0 d-none d-sm-inline-flex' data-bs-toggle="dropdown" style={{ cursor: 'pointer', height: '20px',width: '20px',display: 'flex',alignItems: 'center',justifyContent: 'center', color: (stateName[1] === 'manageStock') ? stockActionColor : '#24265d' }}>
                                            <ion-icon name="apps-outline" style={{ cursor: 'pointer' }}></ion-icon>
                                        </button>
                                    }
                                    <div class="dropdown-menu p-0" style={{zIndex: 10}}>
                                        {(categories !== null && categories !== undefined) &&
                                            <>
                                                {categories.map((value,key) => {
                                                    return <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)" onClick={() => {handleCategorySwitch(value.ID, value.Name)}}>{value.Name}</a>
                                                })}
                                            </>
                                        }
                                    </div>
                                </div>
                                {(searchText === '' || searchText === null || searchText === undefined) &&
                                    <div class="d-none d-sm-block">
                                        {isSpeechRecognitionDoable()}
                                    </div>
                                }
                                {(searchText !== '' && searchText !== null && searchText !== undefined) &&
                                    <>
                                        <i class="input-icon" onClick={clearSearch} style={{right: 10, left: 'auto', zIndex: 999}}>
                                            <ion-icon name="close-outline" class="fs-4 text-secondary"></ion-icon>
                                        </i>
                                    </>
                                }
                            </div>
                                
                        </div>
                        <div class="dropdown d-none">
                            <div class="badge bg-primary dropdown-toggle fs-8" data-bs-toggle="dropdown">
                                <span style={{textTransform: 'uppercase'}}>
                                    <ion-icon name="apps-outline" class="m-0 fs-8" style={{width: '18px'}}></ion-icon>
                                    {(categoryName !== '' && categoryName !== null && categoryName !== undefined) ? categoryName : 'Categories'}
                                </span>
                                <ion-icon name="chevron-down-outline" class="fs-6"></ion-icon>
                            </div>
                            <div class="dropdown-menu p-0" style={{zIndex: 10}}>
                                {(categories !== null && categories !== undefined) &&
                                    <>
                                        {categories.map((value,key) => {
                                            return <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)" onClick={() => {handleCategorySwitch(value.ID, value.Name)}}>{value.Name}</a>
                                        })}
                                    </>
                                }
                            </div>
                        </div>
                    </>
                }
            </div>
            <div class="col-6 col-md-6 d-flex align-items-center justify-content-end">
                {!searchInput &&
                    <>
                        {pageName === 'NewOrder' &&
                            <>
                                {/* <div class="dropdown">
                                    <button class="btn btn-outline-primary btn-sm dropdown-toggle ps-1 pe-1 p-0 me-1" type="button">
                                        Quick Add
                                    </button>
                                </div> */}
                                <div class="dropdown">
                                    <button class="btn btn-outline-primary btn-sm dropdown-toggle ps-1 pe-1 p-0 me-1" type="button" data-bs-toggle="dropdown" style={{minWidth: '30px', fontSize: '10px'}} onClick={() => {showOrderTypeModal({orderTypeModalVisible: true, reference: 'NewOrder'})}}>
                                        {(type === 'Counter Sale') &&
                                            <>
                                                <ion-icon name="storefront-outline" class="me-0 fs-10"></ion-icon>&nbsp;
                                            </>
                                        }
                                        {(type === 'Dine In') &&
                                            <>
                                                <ion-icon name="restaurant-outline" class="me-0 fs-10"></ion-icon>&nbsp;
                                            </>
                                        }
                                        {(type === 'Pick Up') &&
                                            <>
                                                <ion-icon name="bag-handle-outline" class="me-0 fs-10"></ion-icon>&nbsp;
                                            </>
                                        }
                                        {(type === 'Zomato / Swiggy') &&
                                            <>
                                                <ion-icon name="phone-portrait-outline" class="me-0 fs-10"></ion-icon>&nbsp;
                                            </>
                                        }
                                        {(type) ? type : 'Counter Sale'}
                                    </button>
                                    {/* <div class="dropdown-menu p-0">
                                        {topScrollValue.map((v,k) => {
                                            return <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)" onClick={() => {handleActiveTab(k, v.name)}}>{v.name} <ion-icon name={(type === v.name) ? 'checkmark-circle-outline'  :'ellipse-outline'} class="m-0 text-secondary" style={{width: '18px'}}></ion-icon></a> 
                                        })}
                                    </div> */}
                                </div>
                                <div class="dropdown">
                                    <button class="btn bg-transparent btn-sm dropdown-toggle p-0" type="button" data-bs-toggle="dropdown" style={{minWidth: '30px', fontSize: '13px'}}>
                                        View<ion-icon name="chevron-down-outline" class="me-0 fs-10"></ion-icon>
                                    </button>
                                    <div class="dropdown-menu p-0">
                                        <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)" onClick={() => {handleViewSwitch('list')}}>List <ion-icon name={`${(view === 'list') ? 'checkmark-circle-outline' : 'ellipse-outline'}`} class="m-0 text-secondary" style={{width: '18px'}}></ion-icon></a>
                                        <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)" onClick={() => {handleViewSwitch('grid')}}>Grid <ion-icon name={`${(view === 'grid') ? 'checkmark-circle-outline' : 'ellipse-outline'}`} class="m-0 text-secondary" style={{width: '18px'}}></ion-icon></a>
                                        <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)" onClick={() => {handleViewSwitch('button')}}>Button <ion-icon name={`${(view === 'button') ? 'checkmark-circle-outline' : 'ellipse-outline'}`} class="m-0 text-secondary" style={{width: '18px'}}></ion-icon></a>
                                        <div class="dropdown-divider mt-0"></div>
                                        <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)" onClick={() => {handleAlternateToggle('englishText')}}>English Text <ion-icon name={`${(alternateTextVisible === 'englishText') ? 'checkmark-circle-outline' : 'ellipse-outline'}`} class="m-0 text-secondary ms-2" style={{width: '18px'}}></ion-icon></a>
                                        <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)" onClick={() => {handleAlternateToggle('shortcode')}}>Shortcode <ion-icon name={`${(alternateTextVisible === 'shortcode') ? 'checkmark-circle-outline' : 'ellipse-outline'}`} class="m-0 text-secondary ms-2" style={{width: '18px'}}></ion-icon></a>
                                        <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)" onClick={() => {handleAlternateToggle('alternateText')}}>Alternate Text <ion-icon name={`${(alternateTextVisible === 'alternateText') ? 'checkmark-circle-outline' : 'ellipse-outline'}`} class="m-0 text-secondary ms-2" style={{width: '18px'}}></ion-icon></a>
                                        <div class="dropdown-divider mt-0"></div>
                                        <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)" onClick={() => {handleAlternateToggle('color')}}>Color <ion-icon name={`${(colorVisible === 'true' || colorVisible === true) ? 'checkmark-circle-outline' : 'ellipse-outline'}`} class="m-0 text-secondary ms-2" style={{width: '18px'}}></ion-icon></a>
                                    </div>
                                </div>
                            </>
                        }
                        {pageName === 'ManageStock' &&
                            <>
                                <div class="dropdown">
                                    <button class="btn btn-outline-primary btn-sm dropdown-toggle ps-1 pe-1 p-0 me-1" type="button" data-bs-toggle="dropdown" style={{minWidth: '30px', fontSize: '10px', borderColor: (stateName[1] === 'manageStock') ? stockActionColor : '#24265d', color: (stateName[1] === 'manageStock') ? stockActionColor : '#24265d'}} onClick={() => {showOrderTypeModal({orderTypeModalVisible: true, reference: 'ManageStock'})}}>
                                        <>
                                            {action === 'addStock' &&
                                                <>
                                                    <ion-icon name="bag-add-outline" class="fs-6" style={{marginRight: '2px'}}></ion-icon>
                                                    Add Stock
                                                </>
                                            }
                                        </>
                                        <>
                                            {action === 'removeStock' &&
                                                <>
                                                    <ion-icon name="bag-remove-outline" class="fs-6" style={{marginRight: '2px'}}></ion-icon>
                                                    Remove Stock
                                                </>
                                            }
                                        </>
                                        <>
                                            {action === 'transferOut' &&
                                                <>
                                                    <ion-icon name="swap-horizontal-outline" class="fs-6" style={{marginRight: '2px'}}></ion-icon>
                                                    Transfer Out
                                                </>
                                            }
                                        </>
                                        <>
                                            {action === 'request' &&
                                                <>
                                                    <ion-icon name="bag-handle-outline" class="fs-6" style={{marginRight: '2px'}}></ion-icon>
                                                    Request
                                                </>
                                            }
                                        </>
                                    </button>
                                    {/* <div class="dropdown-menu p-0">
                                        {topScrollValue.map((v,k) => {
                                            return <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)" onClick={() => {handleActiveTab(k, v.name)}}>{v.name} <ion-icon name={(type === v.name) ? 'checkmark-circle-outline'  :'ellipse-outline'} class="m-0 text-secondary" style={{width: '18px'}}></ion-icon></a> 
                                        })}
                                    </div> */}
                                </div>
                                <div class="dropdown">
                                    <button class="btn bg-transparent btn-sm dropdown-toggle p-0" type="button" data-bs-toggle="dropdown" style={{minWidth: '30px', fontSize: '13px'}}>
                                        View<ion-icon name="chevron-down-outline" class="me-0 fs-10"></ion-icon>
                                    </button>
                                    <div class="dropdown-menu p-0">
                                        <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)" onClick={() => {handleViewSwitch('list')}}>List <ion-icon name={`${(view === 'list') ? 'checkmark-circle-outline' : 'ellipse-outline'}`} class="m-0 text-secondary" style={{width: '18px'}}></ion-icon></a>
                                        <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)" onClick={() => {handleViewSwitch('grid')}}>Grid <ion-icon name={`${(view === 'grid') ? 'checkmark-circle-outline' : 'ellipse-outline'}`} class="m-0 text-secondary" style={{width: '18px'}}></ion-icon></a>
                                        <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)" onClick={() => {handleViewSwitch('button')}}>Button <ion-icon name={`${(view === 'button') ? 'checkmark-circle-outline' : 'ellipse-outline'}`} class="m-0 text-secondary" style={{width: '18px'}}></ion-icon></a>
                                        <div class="dropdown-divider mt-0"></div>
                                        <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)" onClick={() => {handleAlternateToggle('englishText')}}>English Text <ion-icon name={`${(alternateTextVisible === 'englishText') ? 'checkmark-circle-outline' : 'ellipse-outline'}`} class="m-0 text-secondary ms-2" style={{width: '18px'}}></ion-icon></a>
                                        <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)" onClick={() => {handleAlternateToggle('shortcode')}}>Shortcode <ion-icon name={`${(alternateTextVisible === 'shortcode') ? 'checkmark-circle-outline' : 'ellipse-outline'}`} class="m-0 text-secondary ms-2" style={{width: '18px'}}></ion-icon></a>
                                        <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)" onClick={() => {handleAlternateToggle('alternateText')}}>Alternate Text <ion-icon name={`${(alternateTextVisible === 'alternateText') ? 'checkmark-circle-outline' : 'ellipse-outline'}`} class="m-0 text-secondary ms-2" style={{width: '18px'}}></ion-icon></a>
                                        <div class="dropdown-divider mt-0"></div>
                                        <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)" onClick={() => {handleAlternateToggle('color')}}>Color <ion-icon name={`${(colorVisible === 'true' || colorVisible === true) ? 'checkmark-circle-outline' : 'ellipse-outline'}`} class="m-0 text-secondary ms-2" style={{width: '18px'}}></ion-icon></a>
                                    </div>
                                </div>
                            </>
                        }
                        {pageName === 'Orders' &&
                            <div class="dropdown">
                                {/* <button class="btn btn-secondary btn-sm dropdown-toggle" type="button" disabled>
                                    <ion-icon name="download-outline" class="fs-5"></ion-icon>Export
                                </button> */}
                                <Link to="/customOrder">
                                    <button class="btn btn-secondary btn-sm dropdown-toggle" type="button">
                                        <ion-icon name="add-outline" class="fs-5"></ion-icon>Custom Order
                                    </button>
                                </Link>
                            </div>
                        }
                        {pageName === 'Items' &&
                            <div class="dropdown">
                                <button class="btn bg-transparent btn-sm dropdown-toggle p-0" type="button" data-bs-toggle="dropdown" style={{minWidth: '30px'}}>
                                    <ion-icon name="ellipsis-vertical-outline" class="fs-5 me-0"></ion-icon>more
                                </button>
                                <div class="dropdown-menu p-0">
                                    <a class="dropdown-item p-1" href="javascript:void(0)">Sync Now&nbsp;<span class="badge bg-secondary fs-10">Coming Soon</span></a>
                                </div>
                            </div>
                        }
                        {pageName === 'Transactions' &&
                            <>
                                <div class="dropdown">
                                    <button class="btn btn-secondary btn-sm dropdown-toggle" type="button" disabled>
                                        <ion-icon name="download-outline" class="fs-5"></ion-icon>Export
                                    </button>
                                </div>
                            </>
                        }
                    </>
                }
                {searchInput &&
                    <form class="search-form ms-1 me-1">
                        <div class="form-group searchbox">
                            <input type="text" class="form-control" placeholder="Search" style={{borderRadius: '50px'}} onChange={(e) => {handleItemSearch(e.target.value)}} />
                            <i class="input-icon">
                                <ion-icon name="search-outline"></ion-icon>
                            </i>
                            <i class="input-icon" style={{right: 0, left: 'auto'}} onClick={handleSearchHide}>
                                <ion-icon name="chevron-forward-outline" class="fs-5"></ion-icon>
                            </i>
                        </div>
                    </form>
                }
            </div>
        </div>
    )
}

const mapStateToProps = ({ general, data, stock }) => {
    const {
        view,
        gridView,
        shortcodeVisible,
        alternateTextVisible,
        colorVisible
    } = general
    const {
        itemName,
        dayOrderFilter,
        transcriptEmpty,
        categoryName,
        dayTransactionFilter,
        categories,
        topScrollValue,
        type,
        draftData,
        draftIndex
    } = data
    const {
        stockActionColor
    } = stock
    return {
        view,
        gridView,
        itemName,
        dayOrderFilter,
        transcriptEmpty,
        categoryName,
        dayTransactionFilter,
        categories,
        shortcodeVisible,
        alternateTextVisible,
        colorVisible,
        topScrollValue,
        type,
        draftData,
        draftIndex,
        stockActionColor
    }
  }
  
const mapDispatchToProps = {
    generalDataUpdate,
    showAddCategoryOffcanvas,
    activeTabUpdatedName,
    itemSearch,
    showAddTransactionModal,
    setOrderDayFilter,
    emptyTranscript,
    setCategoryName,
    setTransactionDayFilter,
    categoryIdUpdate,
    showQuickAddOffcanvas,
    setShortcodeVisible,
    setAlternateTextVisible,
    setColorVisible,
    checkoutItemType,
    cartDraftIndex,
    cartDraftData,
    cartOrderIndex,
    showOrderTypeModal,
    setTempCategoryId
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchWithButtons)
