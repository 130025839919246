import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    ongoingOffcanvasVisible: false
}

export const OngoingOffcanvasSlice = createSlice({
    name: 'ongoingOffcanvas',
    initialState,
    reducers: {
      showOngoingOffcanvas: (state, action) => {
        const {
            ongoingOffcanvasVisible
        } = action.payload
        state.ongoingOffcanvasVisible = ongoingOffcanvasVisible
      },
      hideOngoingOffcanvas: (state) => {
        state.ongoingOffcanvasVisible = false
      }
    }
})

export const { showOngoingOffcanvas, hideOngoingOffcanvas } = OngoingOffcanvasSlice.actions;


export default OngoingOffcanvasSlice.reducer