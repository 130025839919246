import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    transactionDetailModalVisible: false,
    transactionDetaildata: null
}

export const TransactionDetailModalSlice = createSlice({
    name: 'transactionDetail',
    initialState,
    reducers: {
      showTransactionDetailModal: (state, action) => {
        const {
            transactionDetailModalVisible,
            transactionDetaildata
        } = action.payload
        state.transactionDetailModalVisible = transactionDetailModalVisible
        state.transactionDetaildata = transactionDetaildata
      },
      hideTransactionDetailModal: (state) => {
        state.transactionDetailModalVisible = false
      }
    }
})

export const { showTransactionDetailModal, hideTransactionDetailModal } = TransactionDetailModalSlice.actions;


export default TransactionDetailModalSlice.reducer