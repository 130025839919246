import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import axios from '../../AxiosConfig'
import config from '../../config'
import { useNavigate, useLocation } from 'react-router-dom'
import SearchWithButtons from './Components/SearchWithButtons'
import TransactionGridView from './BitComponents/TransactionGridView'
import TransactionListView from './BitComponents/TransactionListView'
import TransactionDetailModal from '../layout/Sidebar/TransactionDetailModal'
import { generalDataUpdate } from '../../features/utilities/generalSlice'
import { showAddTransactionModal } from '../../features/Offcanvas/addTransactionModalSlice'

const Transactions = ({
    view,
    renderTransactions,
    dayTransactionFilter,
    generalDataUpdate,
    showAddTransactionModal
}) => {
    const [transactionList, setTransactionList] = useState([])
    const [totalIncomeCredit, setTotalIncomeCredit] = useState(0)
    const [totalIncomeDebit, setTotalIncomeDebit] = useState(0)
    const [totalExpenseCredit, setTotalExpenseCredit] = useState(0)
    const [totalExpenseDebit, setTotalExpenseDebit] = useState(0)
    const [noOfTransactions, setNoOfTransactions] = useState(0)
    const [userValue, setUserValue] = useState(null)
    const today = new Date().toISOString().slice(0, 10)
    const search = useLocation().search
    const fullUrl = useLocation().pathname
    const tempPageName = fullUrl.split('/')[1]
    const transactionType = new URLSearchParams(search).get('type')
    
    let navigate = useNavigate()
    useEffect(() => {
        let totalIncomeCredit = []
        let totalIncomeDebit = []
        let totalExpenseCredit = []
        let totalExpenseDebit = []
        let dateFilterFrom = new Date().toISOString().slice(0, 10)
        let dateFilterTo = new Date().toISOString().slice(0, 10)
        if (dayTransactionFilter === 'Today') {
            dateFilterFrom = new Date().toISOString().slice(0, 10)
            dateFilterTo = new Date().toISOString().slice(0, 10)
        } else if (dayTransactionFilter === 'Yesterday') {
            dateFilterFrom = (new Date(new Date().setDate(new Date().getDate()-1)).toISOString().slice(0, 10))
            dateFilterTo = (new Date(new Date().setDate(new Date().getDate()-1)).toISOString().slice(0, 10))
        } else if (dayTransactionFilter === 'Last7') {
            dateFilterFrom = (new Date(new Date().setDate(new Date().getDate()-7)).toISOString().slice(0, 10))
            dateFilterTo = new Date().toISOString().slice(0, 10)
        } else if (dayTransactionFilter === 'Last30') {
            dateFilterFrom = (new Date(new Date().setDate(new Date().getDate()-30)).toISOString().slice(0, 10))
            dateFilterTo = new Date().toISOString().slice(0, 10)
        } else if (dayTransactionFilter === 'All') {
            dateFilterFrom = 'All'
            dateFilterTo = 'All'
        }
        axios.get(config.apiUrl + '/Transactions/list?fdate=' + dateFilterFrom + '&tdate=' + dateFilterTo).then(res=>{
            
            if (res.data.message === 'Success') {
                res.data.data.map(value => {
                    if (value.Transaction_Category_ID === '1' && transactionType === 'income' && value.Transaction_Type === 'credit') {
                        totalIncomeCredit.push(parseInt(value.Amount))
                        const sumTotalIncomeCredit = totalIncomeCredit.reduce(function(total, item) {
                            return total + item;
                        })
                        setTotalIncomeCredit(sumTotalIncomeCredit)
                    }
                    if (value.Transaction_Category_ID === '1' && transactionType === 'income' && value.Transaction_Type === 'debit') {
                        totalIncomeDebit.push(parseInt(value.Amount))
                        const sumTotalIncomeDebit = totalIncomeDebit.reduce(function(total, item) {
                            return total + item;
                        })
                        setTotalIncomeDebit(sumTotalIncomeDebit)
                    }
                    if (value.Transaction_Category_ID !== '1' && transactionType === 'expense' && value.Transaction_Type === 'credit') {
                        totalExpenseCredit.push(parseInt(value.Amount))
                        const sumTotalExpenseCredit = totalExpenseCredit.reduce(function(total, item) {
                            return total + item;
                        })
                        setTotalExpenseCredit(sumTotalExpenseCredit)
                    }
                    if (value.Transaction_Category_ID !== '1' && transactionType === 'expense' && value.Transaction_Type === 'debit') {
                        totalExpenseDebit.push(parseInt(value.Amount))
                        const sumTotalExpenseDebit = totalExpenseDebit.reduce(function(total, item) {
                            return total + item;
                        })
                        setTotalExpenseDebit(sumTotalExpenseDebit)
                    }
                })
                setTransactionList(res.data.data)
            }
        })
    }, [transactionType, renderTransactions, dayTransactionFilter])
    
    useEffect(() => {
        if (transactionType === 'income') {
            let count = 0
            transactionList.map(value => {
                if (value.Transaction_Category_ID === '1') {
                    count++
                }
                setNoOfTransactions(count)
            })
        } else if (transactionType === 'expense') {
            let count = 0
            transactionList.map(value => {
                if (value.Transaction_Category_ID !== '1') {
                    count++
                }
                setNoOfTransactions(count)
            })
        }
    }, [transactionType, renderTransactions])
    useEffect(() => {
        if (transactionList !== null && transactionList.length > 0) {
            axios.get('/Customers').then(res => {
                if (res.data.message === 'Success') {
                    setUserValue(res.data.data)
                }
            })
        }
    }, [transactionList, renderTransactions])

    useEffect(() => {
        if (tempPageName === 'transactions') {
            generalDataUpdate({view: 'list'})
        }
    }, [])
    const handleTabChange = (transactionType) => {
        navigate('/transactions?type='  + transactionType + '&tcategory=all')
    }
    const handleNewTransaction = () => {
        showAddTransactionModal({addTransactionModalVisible: true, transactionData: {
            "Transaction_Category_ID": 1,
            "Transaction_Type":	"expense",	
            "BalanceAmount": '100',	
            "Date":	new Date().toISOString().substr(0, 10),
            "Description": '',
            "Currency": "INR",	
            "PaymentMethod": 'cash',
            "Item_Order_ID": null,
            "OrderType": "",
            "ID": null
        }})
    }
    return (
        <div id="appCapsule">
            {/* <div class="extraHeader p-0" style={{height: '36px', zIndex: 10}}>
                <ul class="nav nav-tabs lined" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link active" style={{height: '36px'}} data-bs-toggle="tab" href="#income" role="tab" onClick={() => {handleTabChange('income')}}>
                            Income
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" style={{height: '36px'}} data-bs-toggle="tab" href="#expense" role="tab" onClick={() => {handleTabChange('expense')}}>
                            Expense
                        </a>
                    </li>
                </ul>
            </div> */}
            {/* <div class="tab-content"> */}
                {/* <div class="tab-pane fade show active" id="income" role="tabpanel"> */}
                    <div class="section full mb-1">
                        <div class="pt-1">
                            <SearchWithButtons pageName={'Transactions'}></SearchWithButtons>
                        </div>
                    </div>
                    <div class="section full">
                        <div class="row">
                            <div class="col-6 col-md-4 col-lg-3 mb-2">
                                <div class="card">
                                    <div class="card-body p-2 pb-1 pt-1">
                                        <div class="row ps-1">
                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 d-flex align-items-center">
                                                <p class="card-text m-0 text-secondary fs-8">Total Income</p>
                                            </div>
                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                                <h3 class="m-0 text-primary fs-3">₹{totalIncomeCredit}</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {view === 'grid' &&
                        <div class="section full">
                            <TransactionGridView list={transactionList} type={transactionType} userValue={userValue}></TransactionGridView>
                        </div>
                    }
                    {view === 'list' &&
                        <div class="section full">
                            <TransactionListView list={transactionList} type={transactionType} userValue={userValue}></TransactionListView>
                        </div>
                    }
                    {transactionList.length === 0 &&
                        <h2 class="text-danger d-flex align-items-center justify-content-center" style={{height: '250px'}}>No Transaction Found</h2>
                    }
                {/* </div> */}
                {/* <div class="tab-pane fade" id="expense" role="tabpanel">
                    <div class="section full mb-1">
                        <div class="pt-1">
                            <SearchWithButtons pageName={'Transactions'}></SearchWithButtons>
                        </div>
                    </div>
                    <div class="section full">
                        <div class="row">
                            <div class="col-6 col-md-4 col-lg-3 mb-2">
                                <div class="card">
                                    <div class="card-body p-2 pb-1 pt-1">
                                        <div class="row ps-1">
                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 d-flex align-items-center">
                                                <p class="card-text m-0 text-secondary fs-8">Total Expense</p>
                                            </div>
                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                                <h3 class="m-0 text-primary fs-3">₹{totalExpenseCredit}</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {view === 'grid' &&
                        <div class="section full">
                            <TransactionGridView list={transactionList} type={transactionType} userValue={userValue}></TransactionGridView>
                        </div>
                    }
                    {view === 'list' &&
                        <div class="section full">
                            <TransactionListView list={transactionList} type={transactionType} userValue={userValue}></TransactionListView>
                        </div>
                    }
                    {transactionList.length === 0 &&
                        <h2 class="text-danger d-flex align-items-center justify-content-center" style={{height: '250px'}}>No Transaction Found</h2>
                    }
                </div> */}
            {/* </div> */}
            <TransactionDetailModal></TransactionDetailModal>
            <div class="fab-button animate bottom-right dropdown responsive-fab">
                <a href="javascript:void(0)" class="fab" onClick={handleNewTransaction}>
                    <ion-icon name="add-outline"></ion-icon>
                </a>
            </div>
        </div>
    )
}

const mapStateToProps = ({ general, data }) => {
    const {
        view
    } = general
    const {
        renderTransactions,
        dayTransactionFilter
    } = data
    return {
        view,
        renderTransactions,
        dayTransactionFilter    
    }
  }
  
  const mapDispatchToProps = {
    generalDataUpdate,
    showAddTransactionModal
  }

export default connect(mapStateToProps, mapDispatchToProps)(Transactions)
