import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    shopStatusModalVisible: false,
    liveShopStatus: ''
}

export const ShopStatusModalSlice = createSlice({
    name: 'shopStatus',
    initialState,
    reducers: {
      showShopStatusModal: (state, action) => {
        const {
            shopStatusModalVisible,
            liveShopStatus
        } = action.payload
        state.shopStatusModalVisible = shopStatusModalVisible
        state.liveShopStatus = liveShopStatus
      },
      hideShopStatusModal: (state) => {
        state.shopStatusModalVisible = false
      }
    }
})

export const { showShopStatusModal, hideShopStatusModal } = ShopStatusModalSlice.actions;


export default ShopStatusModalSlice.reducer