import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  checkoutItemList: [],
  price:{
    subtotal : 0,
    cgst : 0,
    sgst : 0
  },
  tempSubtotal: 0,
  tempBalance: 0
}

export const CheckoutItemsSlice = createSlice({
  name: 'checkoutItems',
  initialState,
  reducers: {
    updateCart: (state, action) => {
      state.checkoutItemList = action.payload
    },
    updateQuantity: (state, action) => {
      if (state.checkoutItemList.length > 0 && state.checkoutItemList !== undefined) {
        state.checkoutItemList[action.payload.checkoutItemIndex].quantity = action.payload.quantity
      } else {
        
      }
    },
    updatePrice: (state, action) => {
      state.price = action.payload
    },
    resetPrice: (state, action) => {
      state.price = {
        subtotal : 0,
        cgst : 0,
        sgst : 0
      }
    },
    tempPrice: (state, action) => {
      const {
        tempSubtotal,
        tempBalance
      } = action.payload
      state.tempSubtotal = tempSubtotal
      state.tempBalance = tempBalance
    },
  }
})

export const { updateCart, updateQuantity, updatePrice, resetPrice, tempPrice } = CheckoutItemsSlice.actions

export default CheckoutItemsSlice.reducer