import React from 'react'
import { useNavigate } from 'react-router-dom'
import Offcanvas from 'react-bootstrap/Offcanvas'
import { connect } from "react-redux"
import OngoingOrders from '../../Pages/BitComponents/OngoingOrders'
import { hideOngoingOffcanvas } from '../../../features/Offcanvas/ongoingOffcanvasSlice'

const OngoingOffcanvas = ({
    ongoingOffcanvasVisible,
    hideOngoingOffcanvas
}) => {
    let navigate = useNavigate()
    return (
        <div>
            <Offcanvas show={ongoingOffcanvasVisible} placement={'end'} onHide={() => {hideOngoingOffcanvas()}} style={{transition: '0.4s'}}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Ongoing</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body class="pt-0 offcanvas-body p-0">
                    <div class="section full">
                        <div class="accordion" id="accordionExample1">
                            <OngoingOrders></OngoingOrders>
                            {/* <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <ul class="listview image-listview media flush">
                                        <li class="multi-level">
                                            <a href="#" class="item accordion-button collapsed pe-5" data-bs-toggle="collapse" data-bs-target={'#accordion1'}>
                                                <div class="icon-box bg-primary">
                                                    <ion-icon name="storefront-outline" class="fs-5 me-0"></ion-icon>
                                                </div>
                                                <div class="in">
                                                    <div class="w-100" style={{lineHeight: 1.5}}>
                                                        <header>#orderID . InvoiceNumber</header>
                                                        <span class="text-primary fw-bolder">₹BalanceAmount</span>
                                                        <footer>Date</footer>
                                                    </div>
                                                    <span class="text-muted">
                                                        <span class="badge bg-success fs-10" style={{height: '18px'}}>1100 Items</span>
                                                    </span>
                                                </div>
                                            </a>
                                        </li>
                                    </ul>
                                </h2>
                                <div id={'accordion1'} class="accordion-collapse collapse" data-bs-parent="#accordionExample1">
                                    <div class="accordion-body pe-0">
                                        <ul class="listview image-listview">
                                            <li class="pe-2 py-2">
                                                <div class="gap-2 d-flex justify-content-between">
                                                    <button class="btn btn-sm btn-success btn-block fs-6">Add</button>
                                                    <button class="btn btn-sm btn-secondary btn-block fs-6">Complete</button>
                                                </div>
                                            </li>
                                            <li>
                                                <a href="#" class="item">
                                                    <div class="icon-box webweb-bg-secondary" style={{width: '45px', height: '45px', minWidth: '45px', maxWidth: '45px'}}>
                                                        <ion-icon name="print-outline" class="fs-1 me-0"></ion-icon>
                                                    </div>
                                                    <div class="in flex-column align-items-start">
                                                        <header>#orderID</header>
                                                        <div style={{lineHeight: 1.5}}>
                                                            <>
                                                                ItemTitle <span class="text-warning fw-bolder">quantity</span>
                                                                {JSON.parse(v2.Items).length > 1 &&
                                                                    <>
                                                                        , &nbsp;
                                                                    </>
                                                                }
                                                            </>
                                                        </div>
                                                        <footer>
                                                            DateTime
                                                            <Moment fromNow>{v2.DateTime}</Moment>
                                                        </footer>
                                                    </div>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div> */}
                            {/* <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <ul class="listview image-listview media flush">
                                        <li class="multi-level">
                                            <a href="#" class="item accordion-button collapsed pe-5" data-bs-toggle="collapse" data-bs-target={'#accordion2'}>
                                                <div class="icon-box bg-warning">
                                                    <ion-icon name="storefront-outline" class="fs-5 me-0"></ion-icon>
                                                </div>
                                                <div class="in">
                                                    <div class="w-100" style={{lineHeight: 1.5}}>
                                                        <header>Hussain Kothari</header>
                                                        <span class="text-primary fw-bolder d-flex justify-content-between">
                                                            ₹1200
                                                            <span class="badge bg-success fs-10" style={{height: '18px'}}>1100 Items</span>
                                                        </span>
                                                        <footer>Date Time</footer>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                    </ul>
                                </h2>
                                <div id={'accordion2'} class="accordion-collapse collapse" data-bs-parent="#accordionExample1">
                                    <div class="accordion-body pe-0">
                                        <ul class="listview image-listview">
                                            <li>
                                                <a href="#" class="item">
                                                    <div class="in flex-column align-items-start">
                                                        <div>
                                                            <ul class="ps-0" style={{listStyle: 'none'}}>
                                                                <li>Chicken Cheese Burger <span class="text-warning fw-bolder">2x</span></li>
                                                                <li>Chicken Peri Peri Shawarma <span class="text-warning fw-bolder">1x</span></li>
                                                                <li>Veg Burger <span class="text-warning fw-bolder">1x</span></li>
                                                                <li>Paneer Frankie <span class="text-warning fw-bolder">1x</span></li>
                                                            </ul>
                                                        </div>
                                                        <footer class="d-flex justify-content-bewteen w-100 mt-1">
                                                            <button class="btn btn-sm btn-success btn-block rounded-0">Accept</button>
                                                            <button class="btn btn-sm btn-danger btn-block rounded-0">Reject</button>
                                                        </footer>
                                                    </div>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </Offcanvas.Body>
                <div class="section full" style={{position: 'absolute', bottom: 0, right: 0, left: 0}}>
                    <button class={`btn btn-block btn-primary rounded-0 d-flex justify-content-between pe-0 ps-1`} onClick={() => {navigate('/orders?tab=sales&category=all', hideOngoingOffcanvas())}}>
                        <div class="fw-bolder">Show Delivered Orders</div> <div><ion-icon name="chevron-forward-outline" class="fs-6 d-flex"></ion-icon></div>
                    </button>
                </div>
            </Offcanvas>
        </div>
    );
}

const mapStateToProps = ({ ongoingOffcanvas }) => {
    const {
        ongoingOffcanvasVisible
    } = ongoingOffcanvas
    return {
        ongoingOffcanvasVisible
    }
}
  
  const mapDispatchToProps = {
    hideOngoingOffcanvas
  }

export default connect(mapStateToProps, mapDispatchToProps)(OngoingOffcanvas)