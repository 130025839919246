import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    stockActionColor: '',
    stockActionType: '',
    stockItemList: [],
    stockPrice:{
      subtotal : 0,
      cgst : 0,
      sgst : 0
    },
}

export const StockSlice = createSlice({
    name: 'stock',
    initialState,
    reducers: {
      setStockAction: (state, action) => {
        const {
            stockActionColor,
            stockActionType
        } = action.payload
        state.stockActionColor = stockActionColor
        state.stockActionType = stockActionType
      },
      updateStock: (state, action) => {
        state.stockItemList = action.payload
      },
      updateStockQuantity: (state, action) => {
        // if (state.stockItemList.length > 0 && state.stockItemList !== undefined) {
          state.stockItemList[action.payload.stockItemIndex].quantity = action.payload.quantity
        // }
      },
      updateStockPrice: (state, action) => {
        state.stockPrice = action.payload
      },
    }
})

export const { setStockAction, updateStock, updateStockQuantity, updateStockPrice } = StockSlice.actions;


export default StockSlice.reducer