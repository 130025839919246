import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import { connect } from "react-redux"
import Logo from '../../images/logo.svg'
import { hideTransactionDetailModal } from '../../../features/Offcanvas/transactionDetailModalSlice'
import { showConfirmationModal } from '../../../features/Offcanvas/confirmationModalSlice'
import axios from '../../../AxiosConfig'
import { triggerBusinessdDataCall } from '../../../features/utilities/generalSlice'

const TransactionDetailModal = ({
    transactionDetailModalVisible,
    hideTransactionDetailModal,
    transactionDetaildata,
    showConfirmationModal,
    businessData,
    triggerBusinessdDataCall,
    triggerFunction
}) => {
    const [userValue, setUserValue] = useState(null)
    const [businessName, setBusinessName] = useState('')
    const [branchName, setBranchName] = useState('')
    const [businessLogo, setBusinessLogo] = useState('')
    useEffect(() => {
        axios.get('/Customers').then(res => {
            if (res.data.message === 'Success') {
                {res.data.data.map(value => {
                    if (transactionDetaildata !== null && transactionDetaildata !== undefined) {
                        if (transactionDetaildata.Customer_ID === value.ID) {
                            setUserValue(value)
                        }
                    }
                })}
            }
        })
    }, [transactionDetailModalVisible])
    useEffect(() => {
        if (businessData === null || businessData === undefined) {
            triggerBusinessdDataCall({triggerFunction: true})
        } else {
            businessData.map((value, key) => {
                if (transactionDetaildata !== null && transactionDetaildata.Business_ID === value.ID) {
                    setBusinessName(value.Name) 
                    setBusinessLogo(value.Logo)
                    value.Branches.map((v,k) => {
                        if (transactionDetaildata.Location_ID === v.ID) {
                            setBranchName(v.Name)
                        }
                    })  
                }
            })
        }
    }, [businessData, triggerFunction])

    const handleDeleteTransaction = (orderID, message) => {
        hideTransactionDetailModal()
        showConfirmationModal({confirmationModalVisible: true, ID: orderID, message: message, deleteType: 'Transaction'})
    }
    return (
        <div>
            <Modal show={transactionDetailModalVisible} centered={true}>
                <div class="rounded-circle d-flex align-items-center justify-content-center text-light" style={{height: '25px', width: '25px', background: 'rgba(0,0,0,0.3)', position: 'absolute', top: '-13px', right: '-13px', cursor: 'pointer'}} onClick={() => {hideTransactionDetailModal()}}>
                    <ion-icon name="close-outline"></ion-icon>
                </div>
                <Modal.Body class="modal-body m-1 p-1 border">
                <div class="section full mt-0">
                    <div class="invoice m-0 p-1 shadow-none">
                        <div class="invoice-page-header align-items-center justify-content-center" style={{textTransform: 'uppercase'}}>
                            {transactionDetaildata && transactionDetaildata.Transaction_Category_ID === '1' &&
                                <>
                                    {/* <div class="fs-8 text-success">Income</div> */}
                                    <div class="fs-8 text-primary fw-bolder">Bill No: {transactionDetaildata && transactionDetaildata.billNo}</div>
                                </>
                            }
                            {transactionDetaildata && transactionDetaildata.Transaction_Category_ID !== '1' &&
                                <>
                                    {/* <div class="fs-8 text-danger">Expense</div> */}
                                    <div class="fs-8 text-primary fw-bolder">Bill No: {transactionDetaildata && transactionDetaildata.billNo}</div>
                                </>
                            }
                        </div>
                        {/* <div class="invoice-page-header align-items-center">
                            {(businessLogo === null || businessLogo === undefined || businessLogo === '') &&
                                <div class="invoice-logo">
                                    <img src={Logo} width="70px" alt="logo" class="h-auto" />
                                </div>
                            }
                            {businessLogo &&
                                <div class="invoice-logo">
                                    <img src={businessLogo} width="70px" alt="logo" class="h-auto" />
                                </div>
                            }
                        </div> */}
                        <div class="invoice-person mt-4">
                            <div class="invoice-to">
                                {/* <h4 class="fw-bolder" style={{textTransform: 'uppercase'}}>{transactionDetaildata && transactionDetaildata.Transaction_Type}</h4> */}
                                <h4 class="fw-bolder">{businessName}</h4>
                                <ion-icon name="location-outline"></ion-icon>{branchName}
                                {userValue && (userValue.ContactNumber !== null || userValue.Email !== null) &&
                                    <p>
                                        <a href={'tel:' + (userValue && userValue.ContactNumber)} style={{color: 'inherit', fontSize: 'inherit'}}>{userValue && userValue.ContactNumber}</a>,&nbsp;
                                        <a href={'mailto:' + (userValue && userValue.Email)} style={{color: 'inherit', fontSize: 'inherit'}}>{userValue && userValue.Email}</a>
                                    </p>
                                }
                            </div>
                            <div class="invoice-from">
                                <h4 class="fw-bolder">{transactionDetaildata && transactionDetaildata.OrderType}, {transactionDetaildata && transactionDetaildata.PaymentMethod}</h4>
                                {/* <p>mobilekit@domain.com</p> */}
                                <p>{userValue && userValue.City}</p>
                            </div>
                        </div>

                        <div class="invoice-total">
                            <ul class="listview transparent simple-listview flush">
                                {/* <li>Subtotal <span class="hightext">₹100</span></li>
                                <li>CGST (5%)<span class="hightext">₹25</span></li>
                                <li>SGST (5%)<span class="hightext">₹25</span></li> */}
                                <li class="fs-6">Total<span class={(transactionDetaildata && transactionDetaildata.Transaction_Type === 'credit') ? 'totaltext text-success' : 'totaltext text-danger'}>₹{transactionDetaildata && transactionDetaildata.Amount}</span></li>
                            </ul>
                        </div>

                        <div class="invoice-bottom">
                            This is for preview purposes only.
                        </div>
                    </div>
                    {/* <p class="d-flex align-items-center justify-content-center mb-0" style={{fontSize: '13px'}}>
                        <button class="btn btn-link btn-sm p-0"><ion-icon name="share-social-outline" class="me-2 fs-4"></ion-icon></button>
                        <button class="btn btn-link btn-sm p-0"><ion-icon name="print-outline" class="me-2 fs-4"></ion-icon></button>
                        <button class="btn btn-link btn-sm p-0" onClick={() => {handleDeleteTransaction(transactionDetaildata.ID, 'Are you sure you want to cancel this Transaction ?')}}><ion-icon name="trash-bin-outline" class="me-2 fs-4"></ion-icon></button>
                    </p> */}
                </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}

const mapStateToProps = ({ transactionDetail, data, general }) => {
    const {
        transactionDetailModalVisible,
        transactionDetaildata
    } = transactionDetail
    const {
        businessData
    } = data
    const {
        triggerFunction
    } = general
    return {
        transactionDetailModalVisible,
        transactionDetaildata,
        businessData,
        triggerFunction
    }
  }
  
  const mapDispatchToProps = {
    hideTransactionDetailModal,
    showConfirmationModal,
    triggerBusinessdDataCall
  }

export default connect(mapStateToProps, mapDispatchToProps)(TransactionDetailModal)