import React from 'react'
import Offcanvas from 'react-bootstrap/Offcanvas'
import { connect } from "react-redux"
import { hideDineInOffcanvas } from '../../../features/Offcanvas/dineInTableOffcanvasSlice'
const DineInTableOffcanvas = ({
    dineInTableOffcanvasVisible,
    hideDineInOffcanvas
}) => {
    return (
        <div>
            <Offcanvas show={dineInTableOffcanvasVisible} placement={'bottom'} onHide={() => {hideDineInOffcanvas()}}>
                <Offcanvas.Header closeButton style={{minHeight: '36px'}}>
                    <Offcanvas.Title style={{display: 'flex', alignItems: 'center'}}>Table No.</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body class="offcanvas-body p-1">
                    <div class="section full">
                        <div class="row">
                            <div class="col-3 col-md-3 mb-1">
                                 <div class="card shadow-none border-primary">
                                    <div class="card-body p-1 d-flex align-items-center justify-content-center">
                                        Table 1
                                    </div>
                                 </div>
                            </div>
                            <div class="col-3 col-md-3 mb-1">
                                <div class="card shadow-none webweb-bg-secondary">
                                    <div class="card-body p-1 d-flex align-items-center justify-content-center">
                                        Table 2
                                    </div>
                                 </div>
                            </div>
                            <div class="col-3 col-md-3 mb-1">
                                <div class="card shadow-none webweb-bg-secondary">
                                    <div class="card-body p-1 d-flex align-items-center justify-content-center">
                                        Table 3
                                    </div>
                                 </div>
                            </div>
                            <div class="col-3 col-md-3 mb-1">
                                <div class="card shadow-none border-primary">
                                    <div class="card-body p-1 d-flex align-items-center justify-content-center">
                                        Table 4
                                    </div>
                                 </div>
                            </div>
                            <div class="col-3 col-md-3 mb-1">
                                <div class="card shadow-none webweb-bg-secondary">
                                    <div class="card-body p-1 d-flex align-items-center justify-content-center">
                                        Table 5
                                    </div>
                                 </div>
                            </div>
                            <div class="col-3 col-md-3 mb-1">
                                <div class="card shadow-none webweb-bg-secondary">
                                    <div class="card-body p-1 d-flex align-items-center justify-content-center">
                                        Table 6
                                    </div>
                                 </div>
                            </div>
                            <div class="col-3 col-md-3 mb-1">
                                <div class="card shadow-none border-warning">
                                    <div class="card-body p-1 d-flex align-items-center justify-content-center">
                                        Table 7
                                    </div>
                                 </div>
                            </div>
                            <div class="col-3 col-md-3 mb-1">
                                <div class="card shadow-none webweb-bg-secondary">
                                    <div class="card-body p-1 d-flex align-items-center justify-content-center">
                                        Table 8
                                    </div>
                                 </div>
                            </div>
                        </div>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    );
}

const mapStateToProps = ({ dineIn }) => {
    const {
        dineInTableOffcanvasVisible
    } = dineIn
    return {
        dineInTableOffcanvasVisible
    }
  }
  
  const mapDispatchToProps = {
    hideDineInOffcanvas
  }

export default connect(mapStateToProps, mapDispatchToProps)(DineInTableOffcanvas)