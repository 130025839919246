import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import Offcanvas from 'react-bootstrap/Offcanvas'
import axios from '../../../AxiosConfig'
import { connect } from "react-redux"
import { hideAddTransactionModal } from '../../../features/Offcanvas/addTransactionModalSlice'
import { handleRenderOrders } from '../../../features/Data/dataSlice'
import moment from 'moment-timezone'

const TransactionDetailModal = ({
    addTransactionModalVisible,
    hideAddTransactionModal,
    transactionData,
    handleRenderOrders
}) => {
    const [tempTransactionData, setTempTransactionData] = useState(null)
    const [warningMessage, setWarningMessage] = useState('')
    const [transactionType, setTransactionType] = useState('Income')
    const [transactionCategories, setTransactionCategories] = useState([])
    const [transactionCategoryID, setTransactionCategoryID] = useState(1)
    const [paymentMethod, setPaymentMethod] = useState('Cash')
    const [descriptionValue, setDescriptionValue] = useState('')
    const targetTimezone = 'Asia/Kolkata'
    const newDate = moment().tz(targetTimezone).format('YYYY-MM-DD HH:mm:ss')
    const [transactionDateTime, setTransactionDateTime] = useState(newDate)
    const [transactionDate, setTransactionDate] = useState(new Date().toISOString().substr(0, 10))
    
    let tempTransaction = null
    useEffect(() => {
        tempTransaction = {
            "Transaction_Category_ID": transactionCategoryID,
            "Transaction_Type":	(transactionType === 'Income') ? "credit" : "expense",	
            "Amount": (transactionData !== null) ? parseInt(transactionData.BalanceAmount) : 0,	
            "BalanceAmount": 0,	
            "Date":	transactionDate,
            "TransactionDate": transactionDateTime,
            "Description": "",
            "Currency": "INR",	
            "PaymentMethod": paymentMethod,	
            "Invoice_ID": (transactionData && transactionData.ID) ? parseInt(transactionData.ID) : null,
            "OrderType": (transactionData && transactionData.OrderType) ? transactionData.OrderType : ""
        }
        setTempTransactionData(tempTransaction)
        axios.get('/TransactionCategories').then(res => {
            if (res.data.message === 'Success') {
                setTransactionCategories(res.data.data)
            }
        })
    }, [addTransactionModalVisible])

    const changeAmount = (newAmount) => {
        tempTransaction = {
            "Transaction_Category_ID": transactionCategoryID,
            "Transaction_Type":	(transactionType === 'Income') ? "credit" : "expense",	
            "Amount": parseInt(newAmount),	
            "BalanceAmount": parseInt(transactionData.BalanceAmount) - parseInt(newAmount),	
            "Date":	transactionDate,
            "TransactionDate": transactionDateTime,
            "Description": "",
            "Currency": "INR",
            "PaymentMethod": paymentMethod,	
            "Invoice_ID": (transactionData && transactionData.ID) ? parseInt(transactionData.ID) : null,
            "OrderType": (transactionData && transactionData.OrderType) ? transactionData.OrderType : ""
        }
        if ((transactionData) && (transactionData.ID !== null && transactionData.ID !== undefined)) {
            if (parseInt(transactionData.BalanceAmount) >= ((newAmount === '') ? 0 : parseInt(newAmount))) {
                setTempTransactionData(tempTransaction)
                setWarningMessage('')
            } else {
                setWarningMessage('Not Allowed')
            }
        } else {
            
        }
    }

    const handleTransactionType = (type) => {
        setTransactionType(type)
    }

    const handleTransactionDate = (date) => {
        setTransactionDate(date)
    }

    const handlePaymentMethod = (method) => {
        setPaymentMethod(method)
    }

    const handleDescriptionValue = (description) => {
        setDescriptionValue(description)
    }
    const handleTransactionSubmit = (e) => {
        e.preventDefault()
        const Data = {
            "Transaction_Category_ID": transactionCategoryID,
            "Transaction_Type":	(transactionType === 'Income') ? "credit" : "expense",	
            "Amount": tempTransactionData.Amount,	
            "BalanceAmount": parseInt(transactionData.BalanceAmount) - tempTransactionData.Amount,	
            "Date":	transactionDate,
            "TransactionDate": transactionDateTime,
            "Description": descriptionValue,
            "Currency": "INR",	
            "PaymentMethod": paymentMethod,
            "Invoice_ID": (transactionData && transactionData.ID) ? parseInt(transactionData.ID) : null,
            "OrderType": (transactionData && transactionData.OrderType) ? transactionData.OrderType : "",
            "billNo": (transactionData && transactionData.billNo) ? transactionData.billNo : ""
        }
        axios.put('/Transactions', Data).then(res => {
            if (res.data.message === 'Success') {
                hideAddTransactionModal()
                if (transactionData.ID) {
                    handleRenderOrders({renderOrders: true})
                }
            } else {
                hideAddTransactionModal()
            }
        })
    }
    return (
        <div>
            <Offcanvas show={addTransactionModalVisible} placement={'bottom'} onHide={() => {hideAddTransactionModal()}} style={{height: 'fit-content', position: 'relative'}}>
                <Offcanvas.Header closeButton style={{minHeight: '36px'}}>
                    <Offcanvas.Title style={{display: 'flex', alignItems: 'center'}}>New Transaction</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body class="offcanvas-body p-1">
                    <div class="section full">
                        <form onSubmit={handleTransactionSubmit}>
                            <div class="row">
                                <div class="col-12 col-md-12">
                                    <div class="form-group basic">
                                        <div class="input-wrapper">
                                            <label class="form-label mb-0">Type <span class="text-danger">*</span></label>
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center">
                                        <div class="form-check">
                                            <input class="form-check-input" type="radio" value="Expense" name="type" id="type1" onChange={(e) => {handleTransactionType(e.target.value)}} checked={transactionType === 'Expense'} />
                                            <label class="form-check-label ps-3" for="type1">
                                                Expense
                                            </label>
                                        </div>&nbsp;
                                        <div class="form-check">
                                            <input class="form-check-input" type="radio" value="Income" name="type" id="type2" onChange={(e) => {handleTransactionType(e.target.value)}} checked={transactionType === 'Income'} />
                                            <label class="form-check-label ps-3" for="type2">
                                                Income
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6 col-md-6">
                                    <div class="form-group basic">
                                        <div class="input-wrapper">
                                            <label class="form-label" for="category">Category <span class="text-danger">*</span></label>
                                            <select class="form-control form-select" id="category" onChange={(e) => {setTransactionCategoryID(e.target.value)}} required>
                                                {transactionCategories.map(value => {
                                                    return <>
                                                        {(transactionType === 'Income' && value.Type === 'credit') &&
                                                            <option value={value.ID}>{value.Title}</option>
                                                        }
                                                        {(transactionType === 'Expense' && value.Type === 'debit') &&
                                                            <option value={value.ID}>{value.Title}</option>
                                                        }
                                                    </>
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6 col-md-6">
                                    <div class="form-group basic">
                                        <div class="input-wrapper">
                                            <label class="form-label" for="datepicker">Sale Date <span class="text-danger">*</span></label>
                                            <input type="date" class="form-control" id="datepicker" value={transactionDate} onChange={(e) => {handleTransactionDate(e.target.value)}} required />
                                            <i class="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6 col-md-6">
                                    <div class="form-group basic">
                                        <div class="input-wrapper">
                                            <label class="form-label" for="amount">
                                                Amount 
                                                <span class="text-danger">*</span>
                                                <span class="text-danger">&nbsp; {warningMessage}</span>
                                            </label>
                                            <input type="number" class="form-control" id="amount" value={tempTransactionData && tempTransactionData.Amount} onChange={(e) => {changeAmount(e.target.value)}} required />
                                            <i class="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6 col-md-6">
                                    <div class="form-group basic">
                                        <div class="input-wrapper">
                                            <label class="form-label" for="paymentMethod">Payment Method <span class="text-danger">*</span></label>
                                            <select class="form-control form-select" id="paymentMethod" onChange={(e) => {handlePaymentMethod(e.target.value)}} required>
                                                <option value="Cash">Cash</option>
                                                <option value="Card">Card</option>
                                                <option value="UPI">UPI</option>
                                                <option value="Payment Gateway">Payment Gateway</option>
                                                <option value="Cheque">Cheque</option>
                                                <option value="Other">Other</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-12">
                                    <div class="form-group basic">
                                        <div class="input-wrapper">
                                            <label class="form-label" for="otherDetail">Other Detail</label>
                                            <textarea class="form-control" id="otherDetail" value={descriptionValue} onChange={(e) => {handleDescriptionValue(e.target.value)}}></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-12">
                                    <div style={{position: 'absolute', top: '6px', right: '45px'}}>
                                        <button type="submit" class="btn btn-success btn-sm d-flex align-items-center"><ion-icon name="add-outline" class="me-0"></ion-icon>&nbsp;Add</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    );
}

const mapStateToProps = ({ addTransaction }) => {
    const {
        addTransactionModalVisible,
        transactionData
    } = addTransaction
    return {
        addTransactionModalVisible,
        transactionData
    }
  }
  
  const mapDispatchToProps = {
    hideAddTransactionModal,
    handleRenderOrders
  }

export default connect(mapStateToProps, mapDispatchToProps)(TransactionDetailModal)