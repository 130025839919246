import React, { useEffect } from 'react'
import { connect } from "react-redux"
import Toast from 'react-bootstrap/Toast'
import { hideToast } from '../../../features/Offcanvas/toastSlice'

const ToastMessage = ({
    toastVisible,
    hideToast
}) => {
    useEffect(() => {
        setTimeout(() => {
            hideToast()
        }, 1200)
    }, [toastVisible])
    return (
        <div>
            <Toast show={toastVisible} autohide delay={2000} bg={'white'} className={"toast-box toast-center border-success"} style={{display: 'inherit', zIndex: 9999}}>
                <Toast.Body className="in">
                <div>
                    <ion-icon name={'checkmark-circle-outline'} className="fs-6" style={{height: '48px', width: '48px', marginBottom: '0px', color: '#34c759'}}></ion-icon>
                    <h2 class="text-success">Success</h2>
                </div>
                <div className="text text-secondary">
                    {/* Your Order ID: #INVC64A */}
                    <div class="d-flex">
                        <button class="me-1 btn btn-primary d-flex align-items-center"><ion-icon name="share-social-outline" class="mb-0" disabled style={{height: '18px', width: '18px'}}></ion-icon>&nbsp;Share</button>
                        {/* <button class="me-1 btn btn-primary d-flex align-items-center"><ion-icon name="print-outline" class="mb-0" style={{height: '18px', width: '18px'}}></ion-icon>&nbsp;Print</button>
                        <button class="me-1 btn btn-primary d-flex align-items-center"><ion-icon name="download-outline" class="mb-0" style={{height: '18px', width: '18px'}}></ion-icon>&nbsp;Download</button>
                        <button class="me-1 btn btn-primary d-flex align-items-center"><ion-icon name="mail-outline" class="mb-0" style={{height: '18px', width: '18px'}}></ion-icon>&nbsp;Mail</button> */}
                        {/* <button class="me-1 btn btn-secondary text-light d-flex align-items-center" onClick={() => {hideToast()}}><ion-icon name="close-circle-outline" class="mb-0" style={{height: '18px', width: '18px'}}></ion-icon>&nbsp;Close</button> */}
                    </div>
                </div>
                </Toast.Body>
            </Toast>
        </div>
    )
}

const mapStateToProps = ({ toast }) => {
    const {
        toastVisible
    } = toast
    return {
        toastVisible
    }
  }
  
const mapDispatchToProps = {
    hideToast
}

export default connect(mapStateToProps, mapDispatchToProps)(ToastMessage)
