import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    itemSizeOffcanvasVisible: false,
    itemValue: null
}

export const ItemSizeOffcanvasSlice = createSlice({
    name: 'itemSize',
    initialState,
    reducers: {
      showItemSizeOffcanvas: (state, action) => {
        const {
            itemSizeOffcanvasVisible,
            itemValue
        } = action.payload
        state.itemSizeOffcanvasVisible = itemSizeOffcanvasVisible
        state.itemValue = itemValue
      },
      hideItemSizeOffcanvas: (state) => {
        state.itemSizeOffcanvasVisible = false
      }
    }
})

export const { showItemSizeOffcanvas, hideItemSizeOffcanvas } = ItemSizeOffcanvasSlice.actions;


export default ItemSizeOffcanvasSlice.reducer