import React, { useEffect } from 'react'
import Toast from 'react-bootstrap/Toast'
import { connect } from "react-redux"
import { addToCart } from '../../../features/Data/dataSlice'

const MessageToast = ({
    os,
    added,
    addToCart
}) => {
    useEffect(() => {
        if (added) {
            setTimeout(() => {
                addToCart({added: false})
            }, 800);
        }
    }, [added])
    return (
        <div>
            <Toast show={added} position={'bottom-center'} bg={'success'} style={{position: 'fixed', right: '0px', left: '0px', width: '100%', zIndex: 10, bottom: (os) ? '66px' : '56px', transition: '0.2s'}}>
                <Toast.Body class="p-1">
                    <div class="row">
                        <div class="col-8 col-md-8 d-flex align-items-center">
                            Item Added in Cart
                        </div>
                        <div class="col-4 col-md-4 d-flex align-items-center justify-content-end">
                            <span class="btn btn-sm btn-success text-muted" style={{textDecoration: 'underline'}} onClick={() => {addToCart({added: false})}}>Close</span>
                        </div>
                    </div>
                </Toast.Body>
            </Toast>
        </div>
    )
}

const mapStateToProps = ({ data }) => {
    const {
        added
    } = data
    return {
        added
    }
  }
  
const mapDispatchToProps = {
    addToCart
}

export default connect(mapStateToProps, mapDispatchToProps)(MessageToast)