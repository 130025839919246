import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    draftCartListModalVisible: false
}

export const DraftCartListModalSlice = createSlice({
    name: 'draftCartList',
    initialState,
    reducers: {
        showDraftCartListModal: (state, action) => {
        const {
            draftCartListModalVisible
        } = action.payload
        state.draftCartListModalVisible = draftCartListModalVisible
      },
      hideDraftCartListModal: (state) => {
        state.draftCartListModalVisible = false
      }
    }
})

export const { showDraftCartListModal, hideDraftCartListModal } = DraftCartListModalSlice.actions;


export default DraftCartListModalSlice.reducer