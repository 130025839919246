import React from 'react'
import Modal from 'react-bootstrap/Modal'
import { connect } from "react-redux"
import { hideQuantityOffcanvas } from '../../../features/Offcanvas/quantityOffcanvasSlice'
import { updateCart, updateQuantity, updatePrice } from '../../../features/state/checkoutItemsSlice'

const QuantityOffcanvas = ({
    quantityOffcanvasVisible,
    hideQuantityOffcanvas,
    checkoutItemIndex,
    checkoutItemList,
    updateQuantity,
    updateCart,
    price,
    updatePrice
}) => {
    let checkoutItem = checkoutItemList[checkoutItemIndex]

    const removeItemFromChecklist = ()=>{
        let itemList = [...checkoutItemList]
        itemList.splice(checkoutItemIndex,1)
        updateCart(itemList)
        updatePrice({
            subtotal : price?.subtotal - checkoutItem?.item?.MRP*checkoutItem?.quantity,
            cgst : 0,
            sgst : 0
          })
        hideQuantityOffcanvas()
    }

    const updateQuantityOfItem = (quantity)=>{
        updateQuantity({checkoutItemIndex:checkoutItemIndex,quantity:quantity})
        updatePrice({
            subtotal : price?.subtotal + checkoutItem?.item?.MRP*(quantity-checkoutItem?.quantity),
            cgst : 0,
            sgst : 0
        })
    }

    return (
        <div>
            <Modal show={quantityOffcanvasVisible} centered={true} onHide={() => {hideQuantityOffcanvas()}}>
                <Modal.Header closeButton style={{border: 'none'}}>
                    <div class="row w-100">
                        <div class="col-6 col-md-6 d-flex align-items-center">
                            <span>{(checkoutItem !== null || checkoutItem !== undefined) ? checkoutItem?.item?.ItemTitle : 'Quantity'}</span>
                        </div>
                        <div class="col-6 col-md-6 d-flex justify-content-end">
                            <button class="btn btn-icon border-0 btn-sm me-1" onClick={removeItemFromChecklist}>
                                <ion-icon name="trash-sharp" class="fs-5 md hydrated text-danger" role="img" aria-label="trash-sharp"></ion-icon>
                            </button>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body class="pt-0 modal-body">
                    <div class="section">
                        <div class="row mb-1">
                            {/* <div class="col-12 col-md-12 d-flex justify-content-center">
                                <small><span class="text-secondary">Available Stock:</span> 36</small>
                            </div> */}
                            <div class="col-12 col-md-12">
                                <form class="search-form">
                                    <div class="form-group searchbox d-flex justify-content-center">
                                        <input type="number" class="form-control w-50 mb-1" placeholder="Quantity" min="1" value={checkoutItem?.quantity} onChange={(e)=>updateQuantityOfItem(e.target.value)}/>
                                        <i class="input-icon" style={{left: '25%'}}>
                                            <ion-icon name="cube-outline" class="fs-5"></ion-icon>
                                        </i>
                                        {/* <i class="input-icon" style={{right: '25%', left: 'auto'}}>
                                            <select class="form-control p-1 pe-0 pb-0 rounded-0" id="city5">
                                                <option value="0">gm</option>
                                                <option value="1">kg</option>
                                                <option value="2">ml</option>
                                                <option value="3">l</option>
                                            </select>
                                        </i> */}
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div class="text-center">
                            <span class="badge bg-secondary me-1 fs-6" style={{cursor: 'pointer'}} onClick={()=>updateQuantityOfItem(Number(checkoutItem?.quantity)+1)}>+ 1</span>
                            <span class="badge bg-secondary me-1 fs-6" style={{cursor: 'pointer'}} onClick={()=>updateQuantityOfItem(Number(checkoutItem?.quantity)+10)}>+ 10</span>
                            <span class="badge bg-secondary me-1 fs-6" style={{cursor: 'pointer'}}onClick={()=>updateQuantityOfItem(Number(checkoutItem?.quantity)+50)}>+ 50</span>
                            <span class="badge bg-secondary me-1 fs-6" style={{cursor: 'pointer'}}onClick={()=>updateQuantityOfItem(Number(checkoutItem?.quantity)+100)}>+ 100</span>
                            <span class="badge bg-secondary me-1 fs-6" style={{cursor: 'pointer'}}onClick={()=>updateQuantityOfItem(Number(checkoutItem?.quantity)+1000)}>+ 1000</span>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}

const mapStateToProps = ({ quantity, checkoutItems}) => {
    const {
        quantityOffcanvasVisible,
        checkoutItemIndex
    } = quantity
    const {
        checkoutItemList,
        price
    } = checkoutItems
    return {
        quantityOffcanvasVisible,
        checkoutItemIndex,
        checkoutItemList,
        price
    }
  }
  
  const mapDispatchToProps = {
    hideQuantityOffcanvas,
    updateCart,
    updateQuantity,
    updatePrice
  }

export default connect(mapStateToProps, mapDispatchToProps)(QuantityOffcanvas)