import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import { connect } from "react-redux"
import { useNavigate, useLocation, Link } from 'react-router-dom'
import { hideOrderTypeModal } from '../../../features/Offcanvas/orderTypeModalSlice'
import { cartDraftData, checkoutItemType, cartDraftIndex, cartOrderIndex } from '../../../features/Data/dataSlice'

const OrderTypeModal = ({
    orderTypeModalVisible,
    hideOrderTypeModal,
    cartDraftID,
    draftData,
    cartDraftData,
    topScrollValue,
    checkoutItemType,
    draftIndex,
    cartDraftIndex,
    cartOrderIndex,
    type,
    reference
}) => {
    const [activeTab, setActiveTab] = useState(0)
    const [action, setAction] = useState('')
    let navigate = useNavigate()
    const search = useLocation().search
    useEffect(() => {
        setAction(new URLSearchParams(search).get('action'))
    }, [search])
    const handleActiveTab = (ID, type) => {
        setActiveTab(ID)
        if (type === null || type === undefined || type === '') {
            checkoutItemType({type: topScrollValue[0].name})
        } else {
            checkoutItemType({type: type})
        }

        let tempObj = JSON.parse(JSON.stringify(draftData))
        let addNewItem = false
        if (draftIndex !== null && draftIndex !== undefined && draftIndex !== '') {
            tempObj[draftIndex].OrderType = type 
            addNewItem = false
        } else {
            for (let i = 0; i < draftData.length; i++) {
                if (draftData[i].Status === -2) {
                    tempObj[i].Status = -1
                    tempObj[i].OrderType = type
                    tempObj[i].TotalQuantity = 0
                    cartDraftIndex({draftIndex: i})
                    cartOrderIndex({odIndex: 0})
                    break
                }
            }
        }
        cartDraftData({draftData: tempObj})
        hideOrderTypeModal()
    }
    const handleStockAction = (actionType) => {
        navigate('/manageStock?action=' + actionType)
        hideOrderTypeModal()
    }
    return (
        <div>
            <Modal show={orderTypeModalVisible} centered={true} onHide={() => {hideOrderTypeModal()}}>
                <Modal.Header closeButton style={{border: 'none'}}>
                    <div class="row w-100 position-relative">
                        <div class="col-md-12 col-12">
                            {reference === 'NewOrder' &&
                                <h5 class="mb-0">Select Order Type</h5>
                            }
                            {reference === 'ManageStock' &&
                                <h5 class="mb-0">Select Action</h5>
                            }
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body class="p-0 modal-body">
                    <div class="section mt-1">
                        <div class="row">
                            {reference === 'NewOrder' &&
                                <>
                                    {topScrollValue.map((v,k) => {
                                        return <div class="col-6 col-md-6 mb-2 text-center">
                                            <div class={'card h-100'} style={{border: (type === v.name) ? '2px solid rgb(36, 38, 93)' : 'solid 2px gainsboro'}} onClick={() => {handleActiveTab(k, v.name)}}>
                                                <div class="card-body p-1">
                                                    <ion-icon name={v.icon} class="fs-1"></ion-icon>
                                                    <h4 class="mt-0 mb-0 fw-bolder">{v.name}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    })}
                                </>
                            }
                            {reference === 'ManageStock' &&
                                <>
                                    <div class="col-6 col-md-6 mb-2 text-center">
                                        <div href="javascript:void(0)" class={'card h-100'} style={{border: (action === 'addStock') ? '2px solid rgb(36, 38, 93)' : 'solid 2px gainsboro'}} onClick={() => {handleStockAction('addStock')}}>
                                            <div class="card-body p-1">
                                                <ion-icon name="bag-add-outline" class="fs-1"></ion-icon>
                                                <h4 class="mt-0 mb-0 fw-bolder">Add Stock</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-md-6 mb-2 text-center">
                                        <div href="javascript:void(0)" class={'card h-100'} style={{border: (action === 'removeStock') ? '2px solid rgb(36, 38, 93)' : 'solid 2px gainsboro'}} onClick={() => {handleStockAction('removeStock')}}>
                                            <div class="card-body p-1">
                                                <ion-icon name="bag-remove-outline" class="fs-1"></ion-icon>
                                                <h4 class="mt-0 mb-0 fw-bolder">Remove Stock</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-md-6 mb-2 text-center">
                                        <div href="javascript:void(0)" class={'card h-100'} style={{border: (action === 'transferOut') ? '2px solid rgb(36, 38, 93)' : 'solid 2px gainsboro'}} onClick={() => {handleStockAction('transferOut')}}>
                                            <div class="card-body p-1">
                                                <ion-icon name="swap-horizontal-outline" class="fs-1"></ion-icon>
                                                <h4 class="mt-0 mb-0 fw-bolder">Transfer Out</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-md-6 mb-2 text-center">
                                        <div href="javascript:void(0)" class={'card h-100'} style={{border: (action === 'request') ? '2px solid rgb(36, 38, 93)' : 'solid 2px gainsboro'}} onClick={() => {handleStockAction('request')}}>
                                            <div class="card-body p-1">
                                                <ion-icon name="bag-handle-outline" class="fs-1"></ion-icon>
                                                <h4 class="mt-0 mb-0 fw-bolder">Request</h4>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}

const mapStateToProps = ({ orderType, data }) => {
    const {
        orderTypeModalVisible,
        cartDraftID,
        reference
    } = orderType
    const {
        draftData,
        topScrollValue,
        draftIndex,
        type
    } = data
    return {
        orderTypeModalVisible,
        cartDraftID,
        draftData,
        topScrollValue,
        draftIndex,
        type,
        reference
    }
  }
  
  const mapDispatchToProps = {
    hideOrderTypeModal,
    cartDraftData,
    checkoutItemType,
    cartDraftIndex,
    cartOrderIndex
  }

export default connect(mapStateToProps, mapDispatchToProps)(OrderTypeModal)